<!--NOTICIA GENERAR CONSTANCIA DE 7MO-->
<div class="container-fluid p-0 my-2">
  <div class="row m-0 d-flex row justify-content-center flex-wrap my-3">
    <div class="col-sm-12 col-md-12 col-lg-12 text-center my-2">
      <h1>Videos para la Carga de Notas Finales 2022</h1>
    </div>
    
    <div class="col-sm-12 col-md-6 col-lg-4 text-center my-2" *ngFor="let video of listaNotasFinalesVideos">
      <h4>{{ video.titulo }}</h4>
     
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12" [src]="video?.url | safeUrl" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="alert alert-danger" *ngIf="video.id == 27">
        <p><strong>Importante!</strong> DEBE CARGAR LAS NOTAS CON FECHA DE CARGA 29 de Noviembre según Resolución
          1228-E /2022 ANEXO I</p>
      </div>
      
    </div>
  </div>
</div>
<!--NOTICIA GENERAR CONSTANCIA DE 7MO-->
<div class="container my-2" *ngIf="false">
  <div class="d-flex row justify-content-center flex-wrap my-3">
    <div class="col-10 text-center">
      <h4 class="display-4">Cómo generar constancia de Alumno Regular</h4>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12" [src]="videoGenerarConstancia?.url | safeUrl" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="alert alert-danger">
        <h4><strong>Importante!</strong> la fecha en la que se debe generar la constancia es apartir del 10 DE OCTUBRE EN ADELANTE</h4>
      </div>
    </div>
  </div>
</div>
<!--NOTICIA SOBRE RA-->
<div class="container " *ngIf="false">
  <div class="alert alert-primary">
    La Dirección de Información, Monitoreo y Evaluación Educativa, perteneciente a la Secretaría de Innovación y Calidad
    Educativa del Ministerio de Educación de la provincia de Jujuy informa a los Directores de todas las instituciones
    educativas de todos los niveles y modalidades de gestión estatal y privada de la provincia de Jujuy, que se lanzará
    en forma oficial el operativo a nivel nacional el Relevamiento Anual 2022 (RAWEB2022).
    La carga de los cuadernillos específicos para cada oferta educativa se hará como todos los años vía web y estará
    disponible a para las escuelas de periodo común (marzo/diciembre) a partir del 06 de junio hasta el 08 de julio, y
    para las escuelas de periodo especial (septiembre/junio) a partir del 26 de septiembre hasta el 04 de noviembre del
    presente año. Cabe aclarar que es obligatorio y tiene carácter de declaración jurada.
  </div>
  <div class="d-flex justify-content-center flex-wrap my-3">
    <div *ngFor="let t of tarjetasNiveles; let i=index" class="card 
            col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-1  p-0 my-4 cards text-center animated fadeIn">
      <div class='card-header shadow border-bottom-0 flex-column justify-content-center text-white'
        [ngClass]="getClassNivelesTitulo(t.titulo)">
        <img src="assets/images/{{t.imagen}}" class='card-img-top iconos' alt='...'>

        <div>
          <img *ngIf="t.titulo!=='RA'" src="assets/images/sinidelogo.png" class='' width="100" alt='...'>

        </div>
        <h3 *ngIf="t.titulo!=='RA'" class='card-title'>Nivel {{t.titulo}} </h3>
        <h3 *ngIf="t.titulo==='RA'" class='card-title'>Relevamiento Anual 2022 </h3>
      </div>
      <div style="height: 150px; overflow: hidden; margin-bottom: -91px;"><svg viewBox="0 0 500 150"
          preserveAspectRatio="none" style="height: 100%; width: 100%;">
          <path [ngClass]="getClassNivelesTituloOnda(t.titulo)"
            d="M-3.15,5.44 C298.19,144.56 262.63,-85.35 502.48,60.69 L516.02,-37.00 L-29.11,-23.19 Z"
            style="stroke: none; "></path>
        </svg></div>


      <div class='card-body'>

        <p class='card-text'>{{t.descripcion}} </p>
      </div>
      <div class='card-footer card-footer-button text-muted mx-auto'>
        <a (click)="cargarPagina(t.titulo)" class='btn' [ngClass]="getClassNiveles(t.titulo)"> Ingresar </a>

      </div>
    </div>
  </div>
</div>