import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from '../data/safe-url.pipe';



@NgModule({
  declarations: [SafeUrlPipe],
  imports: [
    CommonModule
  ],
  exports: [SafeUrlPipe]
})
export class DatespipeModule { }
