import { Component, Input, OnInit } from '@angular/core';
import { sistemas } from 'src/app/data/data';

@Component({
  selector: 'app-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss']
})
export class SeccionComponent implements OnInit {
  @Input() titulo:string ='';
  @Input() descripcion:string = null;
  @Input() habilitarBotones: boolean = false;
  @Input() fondo:string = 'naranja';
   listaSistemas: any[]=sistemas;

  constructor() { }

  ngOnInit(): void {
  }
  getClass():string {
    return this.fondo == 'naranja' ? 'bg-naranja' : 'bg-azul';
  }

}
