import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfertaLocal } from '../../models/ra/OfertaLocal';
import { Localizacion } from '../../models/ra/Localizacion';

@Injectable({
  providedIn: 'root'
})
export class OfertaLocalService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {

 }
 getOfertaLocales(): Observable<OfertaLocal[]> {
    return this.http.get<OfertaLocal[]>(this.urlEndPoint + '/ofertalocal');
 }
 getOfertaLocalXCodEstado(codestado: string): Observable<OfertaLocal[]> {
  return this.http.get<OfertaLocal[]>(this.urlEndPoint + '/ofertalocal/' + codestado);
}
getOfertaLocalXIdLocalizacion(localizacion: Localizacion): Observable<OfertaLocal[]> {
  return this.http.get<OfertaLocal[]>(this.urlEndPoint + '/ofertalocal/localizacion/' + localizacion.idLocalizacion);
}
}
