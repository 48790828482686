import { Ambito } from './Ambito';
import { Cooperadora } from './Cooperadora';
import { Estado } from './Estado';
import { Establecimiento } from './Establecimiento';
import { Responsable } from './Responsable';
export class Localizacion {
    idLocalizacion: number;
    anexo: string;
    cAlternancia: number;
    cAmbito: Ambito;
    cCooperadora: Cooperadora;
    cestado: Estado;
    cPerFuncionamiento: number;
    cPermanencia: number;
    codigoJurisdiccional: string;
    email: string;
    fechaActualizacion: string;
    fechaAlta: string;
    fechaBaja: string;
    fechaCreacion: string;
    nombre: string;
    observaciones: string;
    sede: boolean;
    sedeAdministrativa: boolean;
    sitioWeb: string;
    telefono: string;
    telefonoCodArea: string;
    establecimiento: Establecimiento;
    responsable: Responsable;
    constructor(){
        this.establecimiento = new Establecimiento();
    }
}
