import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarjeta-video',
  templateUrl: './tarjeta-video.component.html',
  styleUrls: ['./tarjeta-video.component.scss']
})
export class TarjetaVideoComponent implements OnInit {
  @Input() video:any = null; // video tutorial
  constructor() { }

  ngOnInit(): void {
  }

  getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }
  getUrlVideo(urlVideo: string):string{
    return "https://youtu.be/"+urlVideo.slice(30,urlVideo.length);

  }

}
