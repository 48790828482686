import { Component, OnInit } from '@angular/core';
import { Consultor } from 'src/app/data/Consultor';
import { consultores } from 'src/app/data/consultores';

@Component({
  selector: 'app-tarjetas-consultores',
  templateUrl: './tarjetas-consultores.component.html',
  styleUrls: ['./tarjetas-consultores.component.scss']
})
export class TarjetasConsultoresComponent implements OnInit {

  listaConsultores=consultores;
  constructor() {
    console.log(this.listaConsultores)
   }

  ngOnInit(): void {
  }

}
