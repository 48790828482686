<div class="mc-banner-type-c-personas img-fluid"
  style="background-image: url('assets/images/informe1.png');">
  <div class="bg-color-9 d-flex justify-content-center py-5">
    <div class="row container text-white text-left">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div *ngIf="seccionInput?.tituloSeccion.includes('Relevamiento') " class="font-weight-bold animated fadeIn" [ngClass]="{'titulo': seccionInput?.tituloSeccion === 'Institucional'}">
          <h1>
            {{seccionInput?.tituloSeccion}}
          </h1>
        </div>
        <div *ngIf="!seccionInput?.tituloSeccion.includes('Relevamiento') " class="display-4 font-weight-bold animated fadeIn" [ngClass]="{'titulo': seccionInput?.tituloSeccion === 'Institucional'}">
          {{seccionInput?.tituloSeccion}}
        </div>
        <div class="animated fadeIn">
          <h3>{{seccionInput?.descripcion}}</h3>
          <div class="d-flex justify-content-left flex-wrap">
            <h4 *ngFor="let item of seccionInput?.items">
              <i class="fab fa-youtube mx-1"></i> {{item}}
            </h4>
          </div>
          <h2 *ngIf="false" class="display-4 text-white  mb-0 mr-2">
            Secretaría de Innovación y Calidad
            Educativa</h2>
          </div>
        <a routerLink="/" class="btn rounded-pill botonesInicio m-t-10">
          <i class="fas fa-school"></i>
          Regresar a Inicio
        </a>
        <a *ngIf="!seccionInput?.tituloSeccion.includes('Relevamiento') " (click)="elegir('recorrido',templateVideo)" class="mx-1 btn rounded-pill botonesInicioRosa tamanio-boton m-t-10 my-1">
          <i class="fas fa-street-view"></i>
          Quiero iniciar un recorrido por SINIDE
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #templateVideo>

  <div *ngIf="!validarObjeto()" class="modal-header azul-violeta text-white text-center">
    <h4 class="modal-title  w-100 text-center">{{videoElegido?.titulo}}</h4>
   
    <button type="button" class="btn btn-danger btn-circle pull-right border border-white" aria-label="Close" (click)="modalRef.hide()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div *ngIf="!validarObjeto()" class="modal-body text-dark">
    <div class="text-center d-sm-block d-md-block d-lg-block" id="videoSeleccionado">
      <div class="alert alert-primary" role="alert">
        <h6 class="modal-title pull-left">{{videoElegido?.subtitulo}}</h6>
      </div>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12" [src]="videoElegido?.url | safeUrl"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>

</ng-template>