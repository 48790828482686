import { Video } from './video';
export const videos: any[] = [
  {
    id: 1,
    titulo: 'Ingresar al sistema SGE',
    subtitulo: 'Ingresar al sistema SGE',
    imagen: 'icono.png',
    url: 'https://youtu.be/BTQZq9TlCfY',
    observacion: '',
    categoria: 'INICIO',
  },
  {
    id: 2,
    titulo: 'Crear usuarios asignar Roles y permisos ',
    subtitulo: 'Crear usuarios asignar Roles y permisos ',
    imagen: 'icono.png',
    url: 'https://youtu.be/8t_GaCWrKNg',
    observacion: '',
    categoria: 'NOTAS,SECUNDARIO',
  },

  {
    id: 3,
    titulo: 'Como crear Secciones en mi Escuela ',
    subtitulo: 'Como crear Secciones en mi Escuela',
    imagen: 'icono.png',
    url: 'https://youtu.be/kYa2LbWVFu0',
    observacion: '',
    categoria: 'INICIAL,PRIMARIO,SECUNDARIO,SALIDAS,MENSAJERIA',
  },
  {
    id: 4,
    titulo: 'Agregar Alumnos a mi Escuela',
    subtitulo: 'Agregar Alumnos a mi Escuela',
    imagen: 'icono.png',
    url: 'https://youtu.be/eKa41f06pUk',
    observacion: '',
    categoria: 'INICIAL,ALUMNOS',
  },
  {
    id: 5,
    titulo: 'Inscribir alumnos a una Sección',
    subtitulo: 'Inscribir alumnos a una Sección',
    imagen: 'icono.png',
    url: 'https://youtu.be/ozCHy-nHCoY',
    observacion: '',
    categoria: 'ALUMNOS,PRIMARIO',
  },
  {
    id: 6,
    titulo: 'Tomar asistencia por Seccion',
    subtitulo: 'Tomar asistencia por Seccion',
    imagen: 'angular.png',
    url: 'https://youtu.be/RnbCl-Rf5Q0',
    observacion: '',
    categoria: 'ALUMNOS,INICIAL,INSCRIPCIONES',
  },
  {
    id: 7,
    titulo: 'Gestión de Pases',
    subtitulo: 'Gestión de Pases',
    imagen: 'angular.png',
    url: 'https://youtu.be/-y3yw5OBazU',
    observacion: '',
    categoria: 'ALUMNOS,INICIAL,INSCRIPCIONES',
  },
  {
    id: 8,
    titulo: 'Notas de Nivel Primario 2024',
    subtitulo: 'Notas de Nivel Primario',
    imagen: 'angular.png',
    url: 'https://youtu.be/Ed8rgwz4oYM',
    observacion: '',
    categoria: 'ALUMNOS,PRIMARIO,INSCRIPCIONES',
    tituloAviso:'Fechas actuales a usar',
    avisos:[
      {
        id:'1p',
        titulo:'1er Bimestre',
        detalle: 'Fecha de calificación del 1er Bimestre 03 de mayo. Cierre de calificación fecha 10 de mayo del 1er Bimestre'
      },
      {
        id:'2p',
        titulo:'2do Bimestre',
        detalle: 'Fecha de calificación del 2do Bimestre 12 de julio. Cierre de calificación fecha 15 de julio del 2do Bimestre'
      },
      {
        id:'3p',
        titulo:'3er bimestre',
        detalle: 'Fecha de calificaciones del 3 Bimestre 04 de octubre. Cierre del 3er bimestre el 11 octubre'
      },
      {
        id:'4p',
        titulo:'4to Bimestre',
        detalle: 'Fecha de calificación del 4to Bimestre 13 de diciembre. Cierre del 4to bimestre el 13 de diciembre'
      },
      {
        id:'5p',
        titulo:'Fortalecimiento de diciembre',
        detalle: 'Fecha de calificaciones de instancia de fortalecimiento de diciembre el 20 de diciembre'
      }
    ]
  },
  {
    id: 9,
    titulo: 'Notas de Nivel Secundario 2024',
    subtitulo: 'Notas de Nivel Secundario',
    imagen: 'angular.png',
    url: 'https://youtu.be/yArPAzjMTaM',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES',
    tituloAviso:'Fechas actuales a usar',
    avisos:[
      {
        id:'1s',
        titulo:'1er trimestre',
        detalle: 'Fecha de calificación del 1er trimestre 31 de mayo. Cierre de calificación fecha 7 de Junio del 1er trimestre'
      },
      {
        id:'2s',
        titulo:'2do trimestre',
        detalle: 'Fecha de calificación del 2do trimestre el 13 de septiembre. Cierre de calificación fecha 20 de Septiembre del 2do trimestre'
      },
      {
        id:'3s',
        titulo:'3er trimestre',
        detalle: 'Fecha de calificaciones del 3er trimestre el 13 Diciembre. Cierre del 3er trimestre el 13 de diciembre'
      }
    ]
  }
  ,
  {
    id: 10,
    titulo: 'Generar reporte de matricula ampliado',
    subtitulo: 'Generar reporte de matricula ampliado',
    imagen: 'angular.png',
    url: 'https://youtu.be/uejaEjNY8MY',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  }
  ,
  {
    id: 11,
    titulo: 'Carga de Alumno Abanderado Nacional',
    subtitulo: 'Carga de Alumno Abanderado Nacional',
    imagen: 'angular.png',
    url: 'https://youtu.be/D_NqEzZEN4w',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  }
  ,
  {
    id: 12,
    titulo: 'Emitir constancia de Alumno Regular + código de asignación a vacante de 1er Año 2024',
    subtitulo: 'Emitir constancia de Alumno Regular + código de asignación a vacante de 1er Año',
    imagen: 'angular.png',
    url: 'https://youtu.be/k3exIEDaNMU',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES',
    tituloAviso:'Fechas actuales a usar',
    avisos:[
      {
        id:'1s',
        titulo:'Fechas a usar',
        detalle: 'Las fechas que debe usar son: 18 al 30 de septiembre 2024'
      },
    ]
  }
  ,
  {
    id: 13,
    titulo: 'Registro y carga de datos antropométricos de peso y talla',
    subtitulo: 'Registro y carga de datos antropométricos de peso y talla',
    imagen: 'angular.png',
    url: 'https://youtu.be/m1-yyRc_wmQ',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },

  {
    id: 14,
    titulo: 'Carga de notas valorativas - Nivel Inicial',
    subtitulo: 'Carga de notas valorativas - Nivel Inicial',
    imagen: 'angular.png',
    url: 'https://youtu.be/jqNHfvQuutQ',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },
  {
    id: 15,
    titulo: 'Nivel Inicial- Promoción de Alumnos - SGE',
    subtitulo: 'Nivel Inicial- Promoción de Alumnos - SGE',
    imagen: 'angular.png',
    url: 'https://youtu.be/-mx1JmeRziY',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },
  {
    id: 16,
    titulo: 'Nivel Primario- Promoción de Alumnos - SGE',
    subtitulo: 'Nivel Primario- Promoción de Alumnos - SGE',
    imagen: 'angular.png',
    url: 'https://youtu.be/e8--6-B9088',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },
  {
    id: 17,
    titulo: 'Nivel Secundario- Promoción de Alumnos - SGE',
    subtitulo: 'Nivel Secundario- Promoción de Alumnos - SGE',
    imagen: 'angular.png',
    url: 'https://youtu.be/6JNmreW7Okw',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },
  {
    id: 18,
    titulo: 'Tablero Resumen de Asistencia',
    subtitulo: 'Tablero Resumen de Asistencia',
    imagen: 'angular.png',
    url: 'https://youtu.be/gieVNQ9mBRw',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },
  {
    id: 19,
    titulo: 'Tablero Motivos Ausentismo',
    subtitulo: 'Tablero Motivos Ausentismo',
    imagen: 'angular.png',
    url: 'https://youtu.be/Nh7qPk1vVEQ',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },

  {
    id: 20,
    titulo: 'Tablero Calificaciones',
    subtitulo: 'Tablero Calificaciones',
    imagen: 'angular.png',
    url: 'https://youtu.be/lIgtQFnCTyM',
    observacion: '',
    categoria: 'ALUMNOS,Secundario,INSCRIPCIONES'
  },



];
