import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { TarjetasVideosComponent } from '../tarjetas-videos/tarjetas-videos.component';
import { Video } from '../../models/video';
import { PlatformLocation } from '@angular/common';
import { Tarjeta } from '../../data/tarjetas';

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss']
})
export class ActividadesComponent implements OnInit {
  @ViewChild(TarjetasVideosComponent) tarjetaVideosComponent: TarjetasVideosComponent;
  listaTarjetas = [];
  tarjetas: any[] = [];
  section: string;
  categoriaElegida: string;
  listaVideos = [];
  videoSeleccionado: Video = new Video();
  seccion: Tarjeta;
  urlorigin: string;
  pathname: string;
  url: any;
  constructor(platformLocation: PlatformLocation, private dataservice: DataService, private activatedRoute: ActivatedRoute) {
    this.section = this.activatedRoute.snapshot.params['page'];
    this.listaTarjetas = this.dataservice.getTarjetasRyA(this.section);
    this.seccion = this.dataservice.getTarjeta(this.section);
    // console.log('SECCION CARGADA:', this.seccion);
    // console.log('LISTA SELECCIONADA:', this.listaTarjetas);
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    // console.log('REC ORIGIN', this.urlorigin );
    // console.log('REC PATH ACTUAL: ', this.pathname);
    // console.log('REC URL ACTUAL:', this.url);
  }

  ngOnInit(): void {
   
  }
  cargarTarjetas(): void{
    this.tarjetas = this.listaTarjetas.filter(t => t.categoria === this.section.toString());
    // console.log(this.tarjetas);
  }

  getVideos( categoria: string): void {
    this.categoriaElegida = categoria;
    // console.log(categoria);
    if (categoria.toLowerCase().includes('grupo')){
      this.categoriaElegida = 'GRUPO';
    }
    this.listaVideos = this.dataservice.getTarjetasVideos(this.categoriaElegida);
    if (this.tarjetaVideosComponent){
      this.tarjetaVideosComponent.videoElegido = undefined;
    }
    this.videoSeleccionado = new Video();
  }

}
