<a 
class='btn btn-danger text-white my-2 mx-2 botonesIniciales col-sm-12 col-md-3 col-lg-2 d-flex align-items-center'>
<i class="fab fa-wpforms iconosInit"></i> Consultas
</a>
<a data-toggle="modal" data-target="#modalFormulario"
                class='btn btn-danger text-white my-2 mx-2 botonesIniciales col-sm-12 col-md-3 col-lg-2 d-flex align-items-center'>
                <i class="fab fa-wpforms iconosInit"></i> Consultas
            </a>

<button type="button" class="btn btn-primary" (click)="openModal(template)">Open modal</button>
<br><br>

<ng-template #template>
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Formulario de Consulta</h5>
                <button type="button" class="close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!--formulario-->
                <form class="was-validated" #form="ngForm" (ngSubmit)="onSubmit(form)"
                 method="post" name="formularioDocente" >
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="inputEmail4">Apellido y nombre</label>
                            <input type="text" [(ngModel)]="contacto.nombre" #nombre="ngModel" name="nombre" class="form-control" required>
                              <div class="invalid-feedback" *ngIf="!!nombre.errors?.required"><!--!! lo convierte a boolean-->
                                El nombre es requerido
                              </div>
                        </div>
                        
                        
                        <div class="form-group col-md-12">
                            <label for="inputPassword4">Email</label>
                            <input type="email"  [(ngModel)]="contacto.email" #email="ngModel" name="email" class="form-control" required>
                            <div class="invalid-feedback" *ngIf="!!email.errors?.required">
                                El Email es requerido
                              </div>
                        </div>
                      
                        <div class="form-group col-md-12">
                            <label for="inputPassword4">DNI</label>
                            <input type="text" [(ngModel)]="contacto.dni" #dni="ngModel" name="dni" maxlength="8" minlength="8" class="form-control" required>
                            <div class="invalid-feedback" *ngIf="!!dni.errors?.required">
                                El dni es requerido
                              </div>
                              <div class="invalid-feedback" *ngIf="!dni.errors?.required">
                                El dni debe ser de 8 digitos
                              </div>
                        </div>
                      
                    </div>
                    <div class="form-group">
                        <label for="institucion">Facultad o Escuela: </label>
                        <select class="custom-select" #facultad="ngModel" [(ngModel)]="contacto.institucion"  name="institucion" id="institucion" required>
                            <option [value]="f" [selected]="i===0" [disabled]="i===0" *ngFor="let f of listaFacultades; let i=index">{{f}}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="facultad.disabled">
                            Debe seleccionar la institucion
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputPassword4">Usted tiene rol de:</label>
                        <select class="custom-select" [(ngModel)]="contacto.rol" name="rol" required>
                            <option selected value="Profesor">Profesor</option>
                            <option value="Estudiante">Estudiante</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="inputPassword4">Como podemos contactarnos con usted:</label>
                        <div class="form-group form-check" *ngFor="let c of listaComunicacion;let i=index">
                            <input type="checkbox" [value]="c" (click)="verificar(c)" class="form-check-input" id="exampleCheck{{i}}">
                            <label class="form-check-label" for="exampleCheck1">{{c}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="form-group col-md-12" *ngIf="seleccionoCelular()">
                        <label for="inputEmail4">Celular:</label>
                        <input type="text" [(ngModel)]="contacto.celular" #celular="ngModel" name="celular" class="form-control" required>
                        <div class="invalid-feedback" *ngIf="!!celular.errors?.required">
                            El celular es requerido comunicarnos
                          </div>
                    </div>
                    <div class="form-group col-md-12" *ngIf="seleccionoCelular()">
                        <label for="exampleFormControlTextarea1">Horario disponible:</label>
                        <input type="text" [(ngModel)]="contacto.horario" #horario="ngModel" name="horario" placeholder="Ej. Todo el dia, de 9 a 12, etc" class="form-control">
                        <div class="invalid-feedback" *ngIf="!!horario.errors?.required">
                            Su horario es requerido para contactarlo
                          </div>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="inconveniente">Inconveniente con: </label>
                        <select class="custom-select" [(ngModel)]="contacto.inconveniente" name="inconveniente" required>
                            <option [value]="f" [selected]="i===0" *ngFor="let f of listaIncovenientes; let i=index">{{f}}</option>
                        </select>
                    </div>
                    </div>
                    <div class="form-group" *ngIf="contacto.inconveniente === listaIncovenientes[listaIncovenientes.length-1]" >
                      <label for="exampleFormControlTextarea1">Describe el problema</label>
                      <textarea class="form-control" #consulta="ngModel" [(ngModel)]="contacto.consulta" name="consulta" rows="4" placeholder="No se olivide de comentarnos sobre el nombre de la Materia - Año - Carrera - Sede" required></textarea>
                      <div class="invalid-feedback" *ngIf="!!consulta.errors?.required">
                        Su consulta es requerida
                      </div>
                    </div>
                    <!--
                    <div class="form-group text-center d-flex justify-content-center">
                        <div class="g-recaptcha" data-sitekey="6Ld7qcIZAAAAAIKa4iZKuHKEFv7iEyxpgosx5ntz"></div>
                    </div>
-->
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-danger mx-2" data-dismiss="modal" (click)="cancelar()">CANCELAR</button>
                        <button type="submit" class="btn botonesInicio mx-2"  [disabled]="!form.valid" >ENVIAR</button>
                        
                    </div>
                </form>
                <!--fin formulario-->
            </div>

        </div>
    </div>
</ng-template>