import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalizacionDomicilio } from '../../models/ra/LocalizacionDomicilio';

@Injectable({
  providedIn: 'root'
})
export class LocalizacionDomicilioService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'})
  constructor(private http: HttpClient) {

 }
 getLocalizacionesDomicilios(): Observable<LocalizacionDomicilio[]> {
    return this.http.get<LocalizacionDomicilio[]>(this.urlEndPoint + '/localizaciondomicilio');
 }
 getLocalizacionesDomiciliosXLocalizacionCodEstado(codEstado: string): Observable<LocalizacionDomicilio[]> {
  return this.http.get<LocalizacionDomicilio[]>(this.urlEndPoint + `/localizaciondomicilio/localizacion/estado/${codEstado}`);
}
getLocalizacionesDomiciliosXLocalizacionCodEstadoTipoDomicilio(codEstado: string,codDomi: number): Observable<LocalizacionDomicilio[]> {
  return this.http.get<LocalizacionDomicilio[]>(this.urlEndPoint + `/localizaciondomicilio/estadodomicilio/${codEstado}/${codDomi}`);
}
 getLocalizacionDomicilioXidLocalizacion(id: number): Observable<LocalizacionDomicilio[]> {
    return this.http.get<LocalizacionDomicilio[]>(this.urlEndPoint + `/localizaciondomicilio/localizacion/${id}`);
  }
}
