import { Localidad } from "./Localidad";

export class Domicilio {
    idDomicilio: number;
    barrio: string;
    cCodgis: number;
    clocalidad: Localidad;
    calle: string;
    calleDerecha: string;
    calleFondo: string;
    calleIzquierda: string;
    codPostal: string;
    cui: string;
    fechaActualizacion: string;
    fraccion: string;
    nro: string;
    radio: string;
    referencia: string;
    xLongitud: number;
    yLatitud: number;
    constructor(){
        this.clocalidad = new Localidad();
    }
}