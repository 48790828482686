import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Alumnos } from '../../models/ra/Alumnos';

@Injectable({
  providedIn: 'root'
})
export class AlumnosService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'})
  constructor(private http: HttpClient) {

 }
 getAlumnosTodos(): Observable<Alumnos[]> {
  return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos`);
}
getAlumnosXidSeccion(id: number): Observable<Alumnos[]> {
  return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos/seccion/${id}`);
}
 getAlumnosXidLocalizacion(id: number): Observable<Alumnos[]> {
    return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos/seccion/localizacion/${id}`);
 }
 getAlumnosXidAlumnoTipo(id: number): Observable<Alumnos[]> {
  return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos/alumnotipo/${id}`);
}
getAlumnosXidLocalizacionXidAlumnoTipo(idloc: number,idalu:number): Observable<Alumnos[]> {
  // http://localhost:8083/api/alumnos/localizacion/alumnotipo/1280444004/1
  return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos/localizacion/alumnotipo/${idloc}/${idalu}`);
}
getAlumnosXLocalizacionEstadoXidAlumnoTipo(estadoLocalizacion: string,idalu:number): Observable<Alumnos[]> {
  // http://localhost:8083/api/alumnos/localizacion/alumnotipo/1280444004/1
  return this.http.get<Alumnos[]>(this.urlEndPoint + `/alumnos/localizacion/estado/alumnotipo/${estadoLocalizacion}/${idalu}`);
}


}

