import {Consultor} from './Consultor';
export const consultores : Consultor[] = [
        {
            orden: "1",
            apellido: "MIULLER",
            nombre: "CRISTIAN",
            celular: "3884412793",
            horarioAtencion: "7: 00 A 13:00",
            correo: "cristianmullerjujuy@gmail.com"
        },
        {
            orden: "2",
            apellido: "TORRICO",
            nombre: "CARINA SOLEDAD",
            celular: "3885246674",
            horarioAtencion: "13: 00 A 19:00",
            correo: "melibasoy@hotmail.com"
        },
        {
            orden: "3",
            apellido: "CABELLO",
            nombre: "DELFOR JAVIER ",
            celular: "3884150734",
            horarioAtencion: "7: 00 A 13:00",
            correo: "delforcabello@hotmail.com"
        },
        {
            "orden": "4",
            "apellido": "GARCIA",//NACER
            "nombre": "MARCELO DANIEL",
            "celular": "3884077924",
            "horarioAtencion": "8:00 A 14:00 ",
            "correo": "danygarcia13@gmail.com"
        },
        {
            "orden": "5",
            "apellido": "PARRAGA",
            "nombre": "PATRICIA FABIANA",
            "celular": "3885041214",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "Patriciaparraga19@hotmail.com"
        },
        {
            "orden": "6",
            "apellido": "FLORES",
            "nombre": "JORGE ORLANDO",
            "celular": "3884569644",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "plliculaska@gmail.com"
        },
        {
            "orden": "7",
            "apellido": "RUARTE",
            "nombre": "MARTIN GABRIEL",
            "celular": "3884296881",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "martin_dos@hotmail.com"
        },
        {
            "orden": "10",
            "apellido": "VILLAGRAN",
            "nombre": "IVAN IGNACIO",
            "celular": "3884960605",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "ivanvillagran@hotmail.com"
        },
        {
            "orden": "8",
            "apellido": "LOPEZ",
            "nombre": "OSCAR ALEJANDRO",
            "celular": "3885198447",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "alelopezlua@gmail.com"
        },
        {
            "orden": "9",
            "apellido": "CRUZ",
            "nombre": "MIRTA LILIANA",
            "celular": "3885833598",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "mcruz.lili123@gmail.com"
        },
        {
            "orden": "10",
            "apellido": "RODRIGUEZ",
            "nombre": "SILVANA ANDREA",
            "celular": "3884347244",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "silandro2015@gmail.com"
        },
        {
            "orden": "11",
            "apellido": "MAREÑO",
            "nombre": "ARIEL EDUARDO",
            "celular": "3885799023",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "hnoarieljujuy@hotmail.com"
        },
        {
            "orden": "12",
            "apellido": "LEONAR",
            "nombre": "DIEGO SEBASTIAN",
            "celular": "3885232844",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "leonardiegosebastian5@gmail.com"
        },
        {
            "orden": "13",
            "apellido": "MACHACA",
            "nombre": "ALEJANDRO ARIEL",
            "celular": "3885285912",
            "horarioAtencion": "13:00 A 19:00",
            "correo": "gaelalejandro8384@gmail.com"
        },
        {
            "orden": "14",
            "apellido": "SUDAÑEZ",
            "nombre": "MARIANA",
            "celular": "385052039",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "sudanezmariana@gmail.com"
        },
        {
            "orden": "15",
            "apellido": "SOTO",
            "nombre": "BLANCA",
            "celular": "3885901423",
            "horarioAtencion": "7: 00 A 13:00",
            "correo": "bes010377@hotmail.com.ar"
        }
    ];


export const consultoresEscuela=[
        {
            "cue": "380048500",
            "escuela": "ESCUELA N 60 VICE ALTE MARIANO CORDERO",
            "matricula": "26",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CALETE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380004300",
            "escuela": "ESCUELA PROVINCIAL DE ARTES N 1 MEDARDO PANTOJA",
            "matricula": "853",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Cursos y Talleres de Artística - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380048900",
            "escuela": "BACHILLERATO PROVINCIAL N 4 ARMADA ARGENTINA",
            "matricula": "288",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Secundaria Completa - Adultos\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380050100",
            "escuela": "ESCUELA NOCTURNA N 88 FRANCISCA MUÑOZ DE SUAREZ",
            "matricula": "10",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380050500",
            "escuela": "ESCUELA NOCTURNA PARA JÓVENES Y ADULTOS N 403",
            "matricula": "20",
            "consultor": "TORRICO CARINA",
            "localidad": "YUTO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380004201",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 7 - INTELA",
            "matricula": "589",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380008201",
            "escuela": "BACHILLERATO PROVINCIAL ACELERADO N 1 - UNIDAD 2 - MENORES",
            "matricula": "28",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007300",
            "escuela": "ESCUELA PRIMARIA N 295 DR ROQUE SAENZ PEÑA",
            "matricula": "325",
            "consultor": "MARTIN RUARTE",
            "localidad": "AGUAS CALIENTES",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380049300",
            "escuela": "ESCUELA N 163 DR GUSTAVO MARTINEZ ZUVIRIA",
            "matricula": "33",
            "consultor": "TORRICO CARINA",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380006000",
            "escuela": "ASOCIACION TODOS JUNTOS",
            "matricula": "153",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nIntegración - Especial\r\nJardin de infantes - Especial\r\nJardin maternal - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380087501",
            "escuela": "J.I.N. 10",
            "matricula": "79",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380065400",
            "escuela": "INSTITUTO DE REHABILITACION APPACE CENTRO DE DIA",
            "matricula": "62",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380051700",
            "escuela": "ESCUELA N 182",
            "matricula": "40",
            "consultor": "LEONAR DIEGO",
            "localidad": "EL TORO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380052100",
            "escuela": "ESCUELA N 404 DOMITILA CHOLELE",
            "matricula": "638",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053300",
            "escuela": "ESCUELA NORMAL SUPERIOR DR EDUARDO CASANOVA",
            "matricula": "345",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "TILCARA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380053700",
            "escuela": "ESCUELA NOCTURNA N 399",
            "matricula": "46",
            "consultor": "CRUZ MIRTA",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380061500",
            "escuela": "ESCUELA PROFESIONAL N 5 CNEL MANUEL ALVAREZ PRADO",
            "matricula": "66",
            "consultor": "FLORES JORGE",
            "localidad": "TILCARA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380065000",
            "escuela": "ESCUELA N 449 MUNICIPALIDAD CIUDAD PERICO",
            "matricula": "342",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087901",
            "escuela": "J.I.N. 14",
            "matricula": "147",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380066300",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA - REGION IV",
            "matricula": "127",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380088301",
            "escuela": "J.I.N. 18",
            "matricula": "130",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380049700",
            "escuela": "ESCUELA N 229 PROVINCIA DE ENTRE RIOS",
            "matricula": "289",
            "consultor": "FLORES JORGE",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380073300",
            "escuela": "JARDÍN INDEPENDIENTE LA VIRGEN NIÑA",
            "matricula": "65",
            "consultor": "MARTIN RUARTE",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380074800",
            "escuela": "ESCUELA N 455",
            "matricula": "731",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003500",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR DELTA",
            "matricula": "154",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380005500",
            "escuela": "ESCUELA N 111 SAN FRANCISCO DE ALAVA",
            "matricula": "266",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003900",
            "escuela": "ESCUELA N 225 FELIX INFANTE",
            "matricula": "5",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LA ALMONA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008500",
            "escuela": "ESCUELA N 418 EVA PERON",
            "matricula": "564",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380006900",
            "escuela": "ESCUELA N 104 OJO DE AGUA",
            "matricula": "17",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PIE DE LA CUESTA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380007700",
            "escuela": "ESCUELA N 236 PROVINCIA DE SANTA FE",
            "matricula": "995",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008100",
            "escuela": "ESCUELA N 360 DR TEODORO SANCHEZ DE BUSTAMANTE",
            "matricula": "611",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380076804",
            "escuela": "ESCUELA PROVINCIAL DE ARTE N 2 HERMOGENES CAYO",
            "matricula": "311",
            "consultor": "FLORES JORGE",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Cursos y Talleres de Artística - Común\r\nSNU - Común\r\nTrayecto Artístico Profesional - Común\r\nCiclos de Enseñanza Artística - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380012501",
            "escuela": "COLEGIO PRIVADO NUEVA GENERACIÓN",
            "matricula": "20",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380008900",
            "escuela": "COLEGIO REMEDIOS DE ESCALADA DE SAN MARTÍN",
            "matricula": "339",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380009300",
            "escuela": "BACHILLERATO PROVINCIAL N 21",
            "matricula": "470",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380050900",
            "escuela": "ESCUELA N 279 ABDON CASTRO TOLAY",
            "matricula": "58",
            "consultor": "LEONAR DIEGO",
            "localidad": "ABDON CASTRO TOLAY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380004700",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 7",
            "matricula": "453",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380006500",
            "escuela": "EMDEI SRL ESCUELA MODELO DE EDUCACIÓN INTEGRAL",
            "matricula": "296",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380010300",
            "escuela": "BACHILLERATO PROVINCIAL N 17",
            "matricula": "175",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN ANTONIO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380015500",
            "escuela": "ESCUELA DE COMERCIO N 2 27 DE ABRIL DÍA GRANDE DE JUJUY",
            "matricula": "843",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380074400",
            "escuela": "COLEGIO SECUNDARIO N 7 MAESTRO MARIO HUMBERTO PEREZ",
            "matricula": "441",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009900",
            "escuela": "ESCUELA N 307 SAN JOSE DE CALASANZ",
            "matricula": "136",
            "consultor": "TORRICO CARINA",
            "localidad": "EL SUNCHAL",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380073200",
            "escuela": "BACHILLERATO A DISTANCIA",
            "matricula": "4243",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380075200",
            "escuela": "COLEGIO SECUNDARIO N 8",
            "matricula": "38",
            "consultor": "CABELLO DELFOR",
            "localidad": "LAGUNILLAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380013900",
            "escuela": "ESCUELA N 296 MODESTA LIZARRAGA DE LERMA",
            "matricula": "58",
            "consultor": "TORRICO CARINA",
            "localidad": "EL PERCHEL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380014700",
            "escuela": "ESCUELA N 209 AVELINO BAZAN",
            "matricula": "13",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "VALIAZO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015100",
            "escuela": "ESCUELA N 141 MONSEÑOR JOSE MARÍA MARQUEZ BERNAL",
            "matricula": "22",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "APARZO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380074100",
            "escuela": "ESCUELA NORMAL SUPERIOR JUAN IGNACIO GORRITI",
            "matricula": "328",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380041500",
            "escuela": "BACHILLERATO PROVINCIAL N 1 GOB ING ALBERTO JOSE ITURBE",
            "matricula": "911",
            "consultor": "FLORES JORGE",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380043500",
            "escuela": "ESCUELA N 407 PROF PABLO ARROYO",
            "matricula": "616",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380046700",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1 ING LUIS MICHAUD",
            "matricula": "1147",
            "consultor": "MARTIN RUARTE",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092509",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - VALLE COLORADO",
            "matricula": "17",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "VALLE COLORADO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380042300",
            "escuela": "ESCUELA N 238 SANTA BARBARA",
            "matricula": "167",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380040800",
            "escuela": "COLEGIO NACIONAL N 3 ÉXODO JUJEÑO",
            "matricula": "690",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380043100",
            "escuela": "ESCUELA N 77 BERNARDINO RIVADAVIA",
            "matricula": "605",
            "consultor": "CABELLO DELFOR",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380043400",
            "escuela": "COLEGIO SECUNDARIO N 62 \"SAN JUAN BAUTISTA DE LA SALLE\"",
            "matricula": "455",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Itinerario formativo - Común\r\nPolimodal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380047500",
            "escuela": "ESCUELA NOCTURNA N 194",
            "matricula": "4",
            "consultor": "TORRICO CARINA",
            "localidad": "EL AGUILAR",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380043800",
            "escuela": "ESCUELA N 223 ARZOBISPO JOSE MARIANO ROQUE MENDIZABAL",
            "matricula": "732",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094210",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - EL TORO",
            "matricula": "30",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "EL TORO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380042700",
            "escuela": "ESCUELA PRIMARIA N 427 JUAN JOSE PASO",
            "matricula": "419",
            "consultor": "CABELLO DELFOR",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin maternal - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380048800",
            "escuela": "ESCUELA N 92 PADRE TARCISIO RUBÍN",
            "matricula": "45",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAUZAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380044200",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1 CNEL MANUEL ALVAREZ PRADO",
            "matricula": "898",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380006800",
            "escuela": "ESCUELA N 288 DR JORGE CORNEJO ARIAS",
            "matricula": "75",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN JUAN DE DIOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380044700",
            "escuela": "BACHILLERATO PROVINCIAL N 10 FRANCISCO LUNA",
            "matricula": "499",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SANTA CLARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380045600",
            "escuela": "ESCUELA N 356 JOSE HERNANDEZ",
            "matricula": "968",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SANTA CLARA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380007200",
            "escuela": "ESCUELA N 255 PUCARITA",
            "matricula": "655",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007600",
            "escuela": "ESCUELA N 314 CORONEL RICARDO SANTIAGO IBAZETA",
            "matricula": "380",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LAS PAMPITAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380028800",
            "escuela": "ESCUELA N 384",
            "matricula": "11",
            "consultor": "FLORES JORGE",
            "localidad": "PIZCUNO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029200",
            "escuela": "ESCUELA N 18 GRAL RONDEAU",
            "matricula": "52",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SANTA CATALINA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031600",
            "escuela": "ESCUELA N 345",
            "matricula": "20",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "MUÑAYOC",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032000",
            "escuela": "ESCUELA N 45 DR JUAN CARLOS ARIAS",
            "matricula": "32",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "COCHINOCA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032400",
            "escuela": "ESCUELA N 83 CRISTOBAL COLON",
            "matricula": "45",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "MINA PIRQUITAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032800",
            "escuela": "ESCUELA N 52 EPIFANIO SARAVIA",
            "matricula": "11",
            "consultor": "TORRICO CARINA",
            "localidad": "CABRERIAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033200",
            "escuela": "ESCUELA N 299 GRUPO DE ARTILLERIA 5",
            "matricula": "11",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LAGUNILLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033600",
            "escuela": "ESCUELA N 316 CPTO TEODORO LAGUNA",
            "matricula": "12",
            "consultor": "FLORES JORGE",
            "localidad": "CASA GRANDE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034000",
            "escuela": "ESCUELA N 219",
            "matricula": "6",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "YAQUISPAMPA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034400",
            "escuela": "ESCUELA N 272 PROVINCIA DE LA RIOJA",
            "matricula": "28",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "HUICHAIRA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380045100",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 6",
            "matricula": "837",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045500",
            "escuela": "ESCUELA N 214 EJERCITO DEL NORTE",
            "matricula": "835",
            "consultor": "FLORES JORGE",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045900",
            "escuela": "ESCUELA PRIMARIA N 63 GOB EUGENIO TELLO",
            "matricula": "25",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380047200",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 5 JOSE EUGENIO TELLO",
            "matricula": "0",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380047100",
            "escuela": "ESCUELA MUNICIPAL N 1 MAESTRA MARINA VILTE",
            "matricula": "935",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380039100",
            "escuela": "BACHILLERATO PROVINCIAL N 5 OCTAVIO FEDERICO BERNIS",
            "matricula": "488",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380039500",
            "escuela": "ESCUELA N 420 REPUBLICA DE SAN MARINO",
            "matricula": "29",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380039900",
            "escuela": "ESCUELA N 436 DIPUTADA MARÍA DEL PILAR BERMUDEZ",
            "matricula": "433",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040300",
            "escuela": "ESCUELA N 224 NUESTRA SRA DEL ROSARIO",
            "matricula": "692",
            "consultor": "CABELLO DELFOR",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040700",
            "escuela": "COLEGIO DEL SALVADOR",
            "matricula": "2076",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nSecundaria Completa req. 7 años - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380041100",
            "escuela": "ESCUELA N 3 ENRIQUE WOLLMANN",
            "matricula": "803",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380043200",
            "escuela": "ESCUELA NOCTURNA N 199 CONGRESALES DE TUCUMAN",
            "matricula": "2",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380043600",
            "escuela": "ESCUELA N 415 CONFEDERACIÓN GENERAL DEL TRABAJO (CGT)",
            "matricula": "642",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380044000",
            "escuela": "ESCUELA N 417 PRESBÍTERO JUAN ROBERTO MORENO",
            "matricula": "186",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380044400",
            "escuela": "ESCUELA N 168 ADELINA RUIZ DE CHAGRA",
            "matricula": "47",
            "consultor": "FLORES JORGE",
            "localidad": "LAS PICHANAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380074900",
            "escuela": "ESCUELA N 454",
            "matricula": "57",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SALAR DE JAMA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380077800",
            "escuela": "COLEGIO SECUNDARIO N 14",
            "matricula": "477",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380078200",
            "escuela": "CENTRO DE TERMINALIDAD PRIMARIA N 198 SERVICIO PENITENCIARIO FEDERAL U.8 MASCULINO",
            "matricula": "93",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380023101",
            "escuela": "ESCUELA PRIMARIA N 66 MARTÍN MIGUEL DE GUEMES",
            "matricula": "10",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL TALAR",
            "ofertaLocal": "Jardin maternal - Especial\r\nIntegración - Especial\r\nCursos/Talleres de la Escuela Especial - Especial",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380082700",
            "escuela": "COLEGIO SECUNDARIO N 29",
            "matricula": "155",
            "consultor": "FLORES JORGE",
            "localidad": "RODEITO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380002500",
            "escuela": "ESCUELA NORMAL JUAN IGNACIO GORRITI",
            "matricula": "929",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088700",
            "escuela": "J.I.N. 22",
            "matricula": "93",
            "consultor": "CRUZ MIRTA",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089100",
            "escuela": "J.I.N. 26",
            "matricula": "107",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380086900",
            "escuela": "J.I.N. 3",
            "matricula": "214",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091500",
            "escuela": "ESCUELA N 467",
            "matricula": "338",
            "consultor": "TORRICO CARINA",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091900",
            "escuela": "ESCUELA HOSPITALARIA GRAL BELGRANO",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380091300",
            "escuela": "JARDIN INDEPENDIENTE N 6",
            "matricula": "189",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092700",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR TRASCENDER",
            "matricula": "138",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380091700",
            "escuela": "ESCUELA HOSPITALARIA Y DOMICILIARIA JORGE URO",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380092100",
            "escuela": "ESCUELA HOSPITALARIA Y DOMICILIARIA OSCAR ORIAS",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380077400",
            "escuela": "ESCUELA PRIMARIA N 453 \"ARA San Juan\"",
            "matricula": "339",
            "consultor": "LEONAR DIEGO",
            "localidad": "AGUAS CALIENTES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380097500",
            "escuela": "JARDIN INDEPENDIENTE N 9",
            "matricula": "322",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380018100",
            "escuela": "ESCUELA N 206",
            "matricula": "11",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "EL PORTILLO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380018500",
            "escuela": "ESCUELA N 47 JOSE FERNANDEZ CAMPERO",
            "matricula": "9",
            "consultor": "TORRICO CARINA",
            "localidad": "PUEBLO VIEJO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380036700",
            "escuela": "ESCUELA N 366",
            "matricula": "47",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LOMA BLANCA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380048701",
            "escuela": "COLEGIO CATÓLICO JESUS MAESTRO",
            "matricula": "521",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380087001",
            "escuela": "J.I.N. 4",
            "matricula": "159",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087401",
            "escuela": "J.I.N. 7",
            "matricula": "51",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380090401",
            "escuela": "J.I.N. 28",
            "matricula": "139",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380000300",
            "escuela": "ESCUELA N 136 GRAL LAMADRID",
            "matricula": "275",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002100",
            "escuela": "BACHILLERATO PROVINCIAL N 15 LEGISLATURA DE LA PROVINCIA DE JUJUY",
            "matricula": "259",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380001200",
            "escuela": "COLEGIO PRIVADO JEAN PIAGET",
            "matricula": "203",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380015900",
            "escuela": "ESCUELA N 48 DR MANUEL PADILLA",
            "matricula": "322",
            "consultor": "LEONAR DIEGO",
            "localidad": "REYES",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380001700",
            "escuela": "COLEGIO N 1 TEODORO SANCHEZ DE BUSTAMANTE",
            "matricula": "885",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380019500",
            "escuela": "ESCUELA ESPECIAL N 4 DR TEOVALDO BURGOS",
            "matricula": "257",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Especial\r\nJardin maternal - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380019000",
            "escuela": "ESCUELA N 68 MAESTRA CONCEPCIÓN CICARELLI",
            "matricula": "306",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380020300",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 13",
            "matricula": "88",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "VALLE GRANDE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380037100",
            "escuela": "ESCUELA N 340",
            "matricula": "3",
            "consultor": "FLORES JORGE",
            "localidad": "CIENAGA GRANDE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380021900",
            "escuela": "INSTITUTO PRIVADO SECUNDARIO LATINOAMERICANO",
            "matricula": "213",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380024100",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 6 GRAL MANUEL BELGRANO",
            "matricula": "472",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nItinerario formativo - Común\r\nPolimodal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380023200",
            "escuela": "BACHILLERATO PROVINCIAL N 9 SAN FRANSISCO DE ASIS",
            "matricula": "265",
            "consultor": "FLORES JORGE",
            "localidad": "EL TALAR",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380035600",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1 GRAL MANUEL BELGRANO",
            "matricula": "671",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "MAIMARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380036300",
            "escuela": "ESCUELA DE COMERCIO N 1 REPUBLICA ARGENTINA",
            "matricula": "1061",
            "consultor": "TORRICO CARINA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Secundaria Completa - Adultos\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380037500",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1",
            "matricula": "183",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL AGUILAR",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380077000",
            "escuela": "COLEGIO SECUNDARIO N 12",
            "matricula": "42",
            "consultor": "FLORES JORGE",
            "localidad": "NUEVA PIRQUITAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380092500",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - SEDE CENTRAL",
            "matricula": "0",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092300",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 8",
            "matricula": "150",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380000700",
            "escuela": "ESCUELA ESPECIAL N 7 INSTITUTO HELLEN KELLER",
            "matricula": "18",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Especial\r\nPrimaria de 7 años - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nIntegración - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380044900",
            "escuela": "ESCUELA N 95 ALTE GUILLERMO BROWN",
            "matricula": "896",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380020700",
            "escuela": "ESCUELA N 35 DR HECTOR QUINTANA",
            "matricula": "100",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN FRANCISCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380021100",
            "escuela": "ESCUELA N 212 SAN FRANCISCO DE ASIS",
            "matricula": "208",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380022700",
            "escuela": "ESCUELA N 156 DR GUILLERMO CLELAND PATERSON",
            "matricula": "178",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LA ESPERANZA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380023600",
            "escuela": "ESCUELA NORMAL GRAL SAN MARTÍN NIVEL PRIMARIO",
            "matricula": "404",
            "consultor": "CRUZ MIRTA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380040901",
            "escuela": "COLEGIO FASTA ING JOSE MARÍA PAZ",
            "matricula": "404",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380036400",
            "escuela": "ESCUELA N 244 BARTOLOME MITRE",
            "matricula": "42",
            "consultor": "FLORES JORGE",
            "localidad": "PUESTO DEL MARQUEZ",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380087502",
            "escuela": "J.I.N. 10",
            "matricula": "52",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380092502",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - NORMENTA",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "NORMENTA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380045700",
            "escuela": "ESCUELA ESPECIAL N 5",
            "matricula": "68",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Integración - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nJardin de infantes - Especial\r\nJardin maternal - Especial\r\nPrimaria de 7 años - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nEducacion integral para adolescentes y jovenes - Especial",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380036800",
            "escuela": "ESCUELA N 267",
            "matricula": "15",
            "consultor": "GARCIA DANIEL",
            "localidad": "CASA COLORADA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380000800",
            "escuela": "ESCUELA DE GESTIÓN PRIVADA ARCO IRIS",
            "matricula": "104",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380001800",
            "escuela": "ESCUELA N 173 AMÉRICA DEL SUR",
            "matricula": "222",
            "consultor": "LEONAR DIEGO",
            "localidad": "PUESTO VIEJO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002200",
            "escuela": "ESCUELA N 100 FRANCISCO DE ARGAÑARAZ",
            "matricula": "542",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003000",
            "escuela": "ESCUELA N 171 MONSEÑOR GERMAN MIGUEL MALLAGRAY",
            "matricula": "520",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380035201",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 10",
            "matricula": "598",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380039200",
            "escuela": "ESCUELA N 27 JUAN GALO LAVALLE",
            "matricula": "151",
            "consultor": "CRUZ MIRTA",
            "localidad": "UQUIA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380003100",
            "escuela": "ESCUELA N 410 MAGDALENA DE GOYECHEA Y CORTE DE GUEMES",
            "matricula": "195",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003800",
            "escuela": "ESCUELA N 430 PADRE JERÓNIMO SCHONFELD",
            "matricula": "97",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008000",
            "escuela": "ESCUELA N 110 MONSEÑOR MIGUEL ANGEL VERGARA",
            "matricula": "683",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009800",
            "escuela": "ESCUELA N 6 DR JOAQUIN CARRILLO",
            "matricula": "1004",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011800",
            "escuela": "ESCUELA N 235 PROVINCIA DE CHUBUT",
            "matricula": "122",
            "consultor": "GARCIA DANIEL",
            "localidad": "YALA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380037600",
            "escuela": "ESCUELA N 408 SIMON BOLIVAR",
            "matricula": "190",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380038400",
            "escuela": "ESCUELA N 438 EL LABRADOR",
            "matricula": "588",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010200",
            "escuela": "ESCUELA N 283 DR JOSE HUMBERTO MARTIARENA",
            "matricula": "14",
            "consultor": "CABELLO DELFOR",
            "localidad": "EL CEIBAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380038000",
            "escuela": "COLEGIO DEL DIVINO REDENTOR",
            "matricula": "1718",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380003400",
            "escuela": "ESCUELA N 1 GRAL MANUEL BELGRANO",
            "matricula": "1020",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010600",
            "escuela": "ESCUELA N 353 DR DALMACIO VELEZ SARFIELD",
            "matricula": "8",
            "consultor": "FLORES JORGE",
            "localidad": "SEVERINO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380042400",
            "escuela": "ESCUELA N 138 PEDRO ORTIZ DE ZARATE",
            "matricula": "405",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380011000",
            "escuela": "ESCUELA NOCTURNA N 191 BARON DE HOLMBERG",
            "matricula": "37",
            "consultor": "CRUZ MIRTA",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380039600",
            "escuela": "CENTRO DE CAPACITACIÓN Y FORMACIÓN LABORAL APPACE",
            "matricula": "56",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380037200",
            "escuela": "ESCUELA N 250",
            "matricula": "7",
            "consultor": "TORRICO CARINA",
            "localidad": "ABRA COLORADA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380011400",
            "escuela": "ESCUELA N 264 BATALLA DE SUIPACHA",
            "matricula": "243",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380080100",
            "escuela": "ESCUELA N 460 HÉROES DE MALVINAS",
            "matricula": "331",
            "consultor": "TORRICO CARINA",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380080900",
            "escuela": "COLEGIO DE JOVENES Y ADULTOS PERIODISTA JUAN CARLOS ZAMBRANO",
            "matricula": "76",
            "consultor": "TORRICO CARINA",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380012200",
            "escuela": "ESCUELA N 335 NACIONES UNIDAS",
            "matricula": "186",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380041600",
            "escuela": "ESCUELA N 39 PABLO SORIA",
            "matricula": "222",
            "consultor": "CRUZ MIRTA",
            "localidad": "CHALICAN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380042000",
            "escuela": "ESCUELA N 231 PROVINCIA DE CORRIENTES",
            "matricula": "365",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380012600",
            "escuela": "COLEGIO LOS LAPACHOS",
            "matricula": "150",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380013000",
            "escuela": "ESCUELA N 266 PROVINCIA DE CATAMARCA",
            "matricula": "209",
            "consultor": "LEONAR DIEGO",
            "localidad": "RIO BLANCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380042800",
            "escuela": "ESCUELA N 253 PROVINCIA DE CHACO",
            "matricula": "530",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "YUTO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380081300",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR DEPOR TEA JUJUY",
            "matricula": "17",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380052800",
            "escuela": "ESCUELA N 67",
            "matricula": "10",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SANTA ANA DE LA PUNA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380002700",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 3 JOSE MANUEL ESTRADA",
            "matricula": "827",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380059200",
            "escuela": "ESCUELA PROVINCIAL DE ARTES",
            "matricula": "559",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Trayecto Artístico Profesional - Común\r\nCursos y Talleres de Artística - Común\r\nCiclos de Enseñanza Artística - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380060000",
            "escuela": "ESCUELA PROFESIONAL N 13 DAMAS MENDOCINAS",
            "matricula": "469",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALPALA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040400",
            "escuela": "COLEGIO PRIVADO NUESTRA SEÑORA DE LAS MERCED",
            "matricula": "332",
            "consultor": "CRUZ MIRTA",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380060600",
            "escuela": "ESCUELA PROFESIONAL N 8 SARMIENTO",
            "matricula": "796",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380041200",
            "escuela": "ESCUELA PROVINCIAL DE ARTES N 3 LOLA MORA",
            "matricula": "1107",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Cursos y Talleres de Artística - Común\r\nCiclos de Enseñanza Artística - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380002600",
            "escuela": "ESCUELA DE COMERCIO N 1 PROF JOSE ANTONIO CASAS",
            "matricula": "1069",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380077700",
            "escuela": "COLEGIO SECUNDARIO N 15 PADRE MIGUEL ÁNGEL AQUINO",
            "matricula": "356",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380078100",
            "escuela": "CENTRO DE TERMINALIDAD PRIMARIA N 199 SERVICIO PENITENCIARIO FEDERAL U.22 FEMENINO",
            "matricula": "23",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091200",
            "escuela": "J.I.N. 35",
            "matricula": "94",
            "consultor": "FLORES JORGE",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380075300",
            "escuela": "COLEGIO DE NIVEL SECUNDARIO N 9",
            "matricula": "62",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CIENEGA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380089600",
            "escuela": "JARDIN MATERNAL MIS PRIMEROS TRAZOS I",
            "matricula": "39",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380077300",
            "escuela": "COLEGIO SECUNDARIO N 13",
            "matricula": "43",
            "consultor": "GARCIA DANIEL",
            "localidad": "ABDON CASTRO TOLAY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090800",
            "escuela": "J.I.N. 31",
            "matricula": "188",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380078900",
            "escuela": "ESCUELA NORMAL SUPERIOR GRAL SAN MARTÍN",
            "matricula": "720",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380080500",
            "escuela": "COLEGIO SECUNDARIO N 22",
            "matricula": "54",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "COCHINOCA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380078500",
            "escuela": "ESCUELA NORMAL REPÚBLICA DE BOLIVIA",
            "matricula": "456",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380090000",
            "escuela": "COLEGIO SECUNDARIO N 50",
            "matricula": "46",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SANTUARIO DE 3 POZOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092900",
            "escuela": "COLEGIO SECUNDARIO N 55",
            "matricula": "14",
            "consultor": "CABELLO DELFOR",
            "localidad": "ORATORIO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380099400",
            "escuela": "J.I.N. 37",
            "matricula": "137",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099800",
            "escuela": "JARDIN MATERNAL \"EL MUNDO MAGICO DE SUSY\"",
            "matricula": "113",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN VICENTE",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380100200",
            "escuela": "JARDIN DE INFANTES MIS PRIMEROS PASOS",
            "matricula": "54",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380004200",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 7 POPULORUM PROGRESSIO",
            "matricula": "2166",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común\r\nCursos de Capacitación de SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380102200",
            "escuela": "JARDÍN INDEPENDIENTE N 18",
            "matricula": "120",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380047201",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 5 JOSE EUGENIO TELLO",
            "matricula": "0",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045801",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 9",
            "matricula": "90",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "SNU - Común\r\nCursos de Capacitación de SNU - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380038502",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 4",
            "matricula": "647",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380047202",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 5 JOSE EUGENIO TELLO",
            "matricula": "505",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380034800",
            "escuela": "ESCUELA N 251 MAESTRO HIPOLITO CACIANO CRUZ",
            "matricula": "65",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "EL MORENO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380076803",
            "escuela": "ESCUELA PROVINCIAL DE TEATRO TITO GUERRA",
            "matricula": "387",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Trayecto Artístico Profesional - Común\r\nSNU - Común\r\nCursos y Talleres de Artística - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380041700",
            "escuela": "JARDIN DE INFANTES INTEGRAL N 1 NIÑO JESUS",
            "matricula": "366",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380098600",
            "escuela": "JARDIN INDEPENDIENTE N 12",
            "matricula": "115",
            "consultor": "CABELLO DELFOR",
            "localidad": "LOZANO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380046200",
            "escuela": "ESCUELA ESPECIAL N 9 NUESTRA SEÑORA DEL CARMEN",
            "matricula": "57",
            "consultor": "CRUZ MIRTA",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nJardin maternal - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040900",
            "escuela": "INSTITUTO FASTA N 12 ING JOSE MARÍA PAZ",
            "matricula": "200",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380040100",
            "escuela": "ESCUELA DE COMERCIO N 1 JOSE MANUEL ESTRADA",
            "matricula": "1791",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PERICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040500",
            "escuela": "BACHILLERATO PROVINCIAL N 3 DR CARLOS MARCELO QUEVEDO CORNEJO",
            "matricula": "887",
            "consultor": "GARCIA DANIEL",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380046500",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 6",
            "matricula": "124",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "RODEITO",
            "ofertaLocal": "Itinerario formativo - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380003700",
            "escuela": "CENTRO EDUCATIVO NIVEL SECUNDARIO N 104",
            "matricula": "419",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380043700",
            "escuela": "COLEGIO N 1 DOMINGO FAUSTINO SARMIENTO",
            "matricula": "756",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008202",
            "escuela": "BACHILLERATO PROVINCIAL ACELERADO N 1 - UNIDAD 3 - FEMENINO",
            "matricula": "11",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092510",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - PAMPICHUELA",
            "matricula": "17",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PAMPICHUELA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380102600",
            "escuela": "COLEGIO SECUNDARIO N 64",
            "matricula": "157",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380042500",
            "escuela": "ESCUELA N 310 AERONÁUTICA ARGENTINA",
            "matricula": "359",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380043300",
            "escuela": "ESCUELA N 240 CORONEL JUAN CACERES",
            "matricula": "65",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN ROQUE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380042100",
            "escuela": "ESCUELA N 57 MARTÍN LEDESMA VALDERRAMA",
            "matricula": "493",
            "consultor": "TORRICO CARINA",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380046100",
            "escuela": "ESCUELA N 139 SGTO JUAN BAUTISTA CABRAL",
            "matricula": "120",
            "consultor": "TORRICO CARINA",
            "localidad": "EL ACHERAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380093601",
            "escuela": "COLEGIO NUEVO HORIZONTE N 1",
            "matricula": "429",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380044100",
            "escuela": "ESCUELA N 144 VICTOR MERCANTE",
            "matricula": "970",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380044500",
            "escuela": "ESCUELA DE MINAS DR HORACIO CARRILLO",
            "matricula": "794",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045000",
            "escuela": "ESCUELA N 386 LATINOAMERICA",
            "matricula": "93",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL MILAGRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045400",
            "escuela": "ESCUELA N 51 SANTA ROSA DE LIMA",
            "matricula": "455",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045300",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 1 PROF CARLOS EUSEBIO IBARRA",
            "matricula": "741",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045800",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 9",
            "matricula": "1500",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380046600",
            "escuela": "ESCUELA N 151 JOSE ANTONIO DE TEZANOS PINTO",
            "matricula": "34",
            "consultor": "CRUZ MIRTA",
            "localidad": "LOBATON",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380047300",
            "escuela": "ESCUELA N 332",
            "matricula": "38",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "CHUCALEZNA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380047000",
            "escuela": "ESCUELA N 109 FLORENTINO AMEGHINO",
            "matricula": "6",
            "consultor": "MARTIN RUARTE",
            "localidad": "LAS ESCALERAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380047700",
            "escuela": "ESCUELA N 215",
            "matricula": "21",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "VIZCARRA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380047400",
            "escuela": "ESCUELA N 241 PROVINCIA DE NEUQUEN",
            "matricula": "32",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "JUELLA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380048100",
            "escuela": "ESCUELA N 277 GRAL JUAN GALO LAVALLE",
            "matricula": "38",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "HORNADITAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380051500",
            "escuela": "ESCUELA N 180",
            "matricula": "13",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "AGUA DE CASTILLA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380045102",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 6",
            "matricula": "716",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "MONTERRICO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094102",
            "escuela": "INSTITUTO SUPERIOR DE FORMACIÓN TÉCNICA MELBY CLAROS",
            "matricula": "0",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380004900",
            "escuela": "ESCUELA N 178 NICOLAS LAMAS",
            "matricula": "113",
            "consultor": "LEONAR DIEGO",
            "localidad": "PAMPA VIEJA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005300",
            "escuela": "ESCUELA NOCTURNA N 99 DESIDERIO SARVERRY",
            "matricula": "34",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094800",
            "escuela": "INSTITUTO SUPERIOR MANUEL BELGRANO DE TECNICO MECANICO DENTAL",
            "matricula": "33",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380007500",
            "escuela": "ESCUELA N 252 PROVINCIA DE SALTA",
            "matricula": "222",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LOS LAPACHOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007900",
            "escuela": "ESCUELA N 64 INDEPENDENCIA",
            "matricula": "975",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380076802",
            "escuela": "ESCUELA SUPERIOR DE MÚSICA",
            "matricula": "3617",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Trayecto Artístico Profesional - Común\r\nCiclos de Enseñanza Artística - Común\r\nSNU - Común\r\nCursos y Talleres de Artística - Común\r\nCursos de Capacitación de SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380062700",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA - REGION V",
            "matricula": "144",
            "consultor": "CRUZ MIRTA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380064100",
            "escuela": "JARDIN MATERNAL GRILLITO PEPE",
            "matricula": "44",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380064500",
            "escuela": "ESCUELA N 446",
            "matricula": "10",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CHOLACOR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380078800",
            "escuela": "ESCUELA NORMAL SUPERIOR GRAL JOSE DE SAN MARTÍN",
            "matricula": "1069",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380080000",
            "escuela": "ESCUELA N 459",
            "matricula": "339",
            "consultor": "CABELLO DELFOR",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380080400",
            "escuela": "ESCUELA N 458",
            "matricula": "276",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380093600",
            "escuela": "COLEGIO NUEVO HORIZONTE N 1",
            "matricula": "1023",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380056902",
            "escuela": "SENTIR III CENTRO EDUCATIVO TERAPEUTICO",
            "matricula": "70",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LOZANO",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial\r\nCursos/Talleres de la Escuela Especial - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380099500",
            "escuela": "JARDIN INDEPENDIENTE N 13",
            "matricula": "315",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099002",
            "escuela": "J.I.N. 38",
            "matricula": "61",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "PARAPETI",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380004203",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 7 POPULORUM PROGRESSIO",
            "matricula": "317",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380004500",
            "escuela": "BACHILLERATO PROVINCIAL N 20",
            "matricula": "291",
            "consultor": "LEONAR DIEGO",
            "localidad": "AGUAS CALIENTES",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380080800",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 5",
            "matricula": "494",
            "consultor": "FLORES JORGE",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380035202",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 10",
            "matricula": "399",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380054100",
            "escuela": "ESCUELA PRIMARIA N 123 GRAL MANUEL BELGRANO",
            "matricula": "778",
            "consultor": "GARCIA DANIEL",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin maternal - Especial\r\nIntegración - Especial\r\nJardin de infantes - Común\r\nJardin de infantes - Especial",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380050800",
            "escuela": "ESCUELA N 53 DR MARCELINO VARGAS",
            "matricula": "25",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CIENAGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380051200",
            "escuela": "ESCUELA N 56 NUESTRO SEÑOR DEL MILAGRO",
            "matricula": "38",
            "consultor": "FLORES JORGE",
            "localidad": "TUSAQUILLA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380101501",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.P. N 2",
            "matricula": "29",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380051600",
            "escuela": "ESCUELA N 121 INGENIEROS ARGENTINOS",
            "matricula": "13",
            "consultor": "CRUZ MIRTA",
            "localidad": "DONCELLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380052400",
            "escuela": "ESCUELA DE FRONTERA N 4 CAPITAL FEDERAL",
            "matricula": "61",
            "consultor": "CRUZ MIRTA",
            "localidad": "CASIRA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053200",
            "escuela": "ESCUELA N 368 MADRE ALPHONS MARÍA EPPINGER",
            "matricula": "10",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN JUAN DE ORO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053600",
            "escuela": "ESCUELA N 405 BRIGADIER JUAN MANUEL DE ROSAS",
            "matricula": "1105",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380054900",
            "escuela": "ESCUELA N 304 ARQ CARLOS ALBERTO FRANZINI",
            "matricula": "90",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL PIQUETE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380055300",
            "escuela": "ESCUELA N 383 FRAY MAMERTO ESQUIU",
            "matricula": "264",
            "consultor": "TORRICO CARINA",
            "localidad": "RODEITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380003200",
            "escuela": "ESCUELA DE COMERCIO N 2 MALVINAS ARGENTINAS",
            "matricula": "1081",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380054800",
            "escuela": "ESCUELA N 301 AUSTRALIA",
            "matricula": "18",
            "consultor": "FLORES JORGE",
            "localidad": "OCUMAZO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380055700",
            "escuela": "COLEGIO CRISTIANO EVANGÉLICO CHE-IL",
            "matricula": "673",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin maternal - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380006600",
            "escuela": "ESCUELA ESPECIAL DE INTEGRACION N 3 SANTA MARIA EUFRASIA PELLETIER",
            "matricula": "0",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Integración - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380054000",
            "escuela": "ESCUELA ESPECIAL N 10",
            "matricula": "156",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Taller de Secundaria/Polimodal - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nSolo Secundaria Orientada req. 9 años - Especial\r\nIntegración - Especial\r\nJardin de infantes - Especial\r\nJardin maternal - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380050400",
            "escuela": "CENTRO POLIVALENTE DE ARTE N 2",
            "matricula": "821",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nCursos y Talleres de Artística - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380055200",
            "escuela": "ESCUELA N 74 ISLAS MALVINAS",
            "matricula": "51",
            "consultor": "TORRICO CARINA",
            "localidad": "VILLA CAROLINA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380056900",
            "escuela": "SENTIR I CENTRO EDUCATIVO TERAPEUTICO",
            "matricula": "71",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Especial\r\nJardin de infantes - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380075000",
            "escuela": "INSTITUTO TÉCNICO SUPERIOR DEL COLEGIO DE TÉCNICOS DE JUJUY",
            "matricula": "798",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380002400",
            "escuela": "CENTRO POLIVALENTE DE ARTE PROF LUIS ALBERTO MARTINEZ",
            "matricula": "755",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Ciclos de Enseñanza Artística - Común\r\nTrayecto Artístico Profesional - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002800",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 2 PROF JESÚS RAUL SALAZAR",
            "matricula": "889",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003600",
            "escuela": "CENTRO EDUCATIVO NIVEL SECUNDARIO N 43 MANUEL BELGRANO",
            "matricula": "210",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380004800",
            "escuela": "ESCUELA N 55 PATRICIAS JUJEÑAS",
            "matricula": "667",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PAMPA BLANCA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380075400",
            "escuela": "COLEGIO DE NIVEL SECUNDARIO N 10",
            "matricula": "48",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "CONDOR",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380052000",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR N 1",
            "matricula": "905",
            "consultor": "CABELLO DELFOR",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Cursos de Capacitación de SNU - Común\r\nSNU - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380035100",
            "escuela": "ESCUELA N 270 CACIQUE GDOR PEDRO QUIPILDOR",
            "matricula": "21",
            "consultor": "CABELLO DELFOR",
            "localidad": "CASABINDO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380095101",
            "escuela": "COLEGIO NUEVO HORIZONTE N 2 - SAN VICENTE DE MONTERRICO",
            "matricula": "127",
            "consultor": "TORRICO CARINA",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380082300",
            "escuela": "ESCUELA N 462",
            "matricula": "17",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "EL MORENO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005200",
            "escuela": "ESCUELA NOCTURNA N 102 PAULA ALBARRACIN DE SARMIENTO",
            "matricula": "27",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005700",
            "escuela": "ESCUELA PRIVADA ANTONIO MARÍA GIANELLI",
            "matricula": "878",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380006100",
            "escuela": "ESCUELA NOCTURNA N 158 MARIANO MORENO",
            "matricula": "11",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007000",
            "escuela": "ESCUELA N 309 PEDRO ORTIZ DE ZARATE",
            "matricula": "86",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL FUERTE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380032700",
            "escuela": "ESCUELA N 348",
            "matricula": "8",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "TIMON CRUZ",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033100",
            "escuela": "ESCUELA N 269 CNEL MARTINIANO CHILAVERT",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "MINA PAN DE AZUCAR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033500",
            "escuela": "ESCUELA N 114",
            "matricula": "4",
            "consultor": "TORRICO CARINA",
            "localidad": "POZUELOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033900",
            "escuela": "ESCUELA N 351 JOSE MARÍA FASCIO",
            "matricula": "24",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "EL DURAZNO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034300",
            "escuela": "ESCUELA N 89 SALVADOR DE BENEDETTI",
            "matricula": "6",
            "consultor": "CRUZ MIRTA",
            "localidad": "YALA DE MONTE CARMELO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034700",
            "escuela": "ESCUELA N 17 25 DE MAYO",
            "matricula": "150",
            "consultor": "CRUZ MIRTA",
            "localidad": "VOLCAN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380035500",
            "escuela": "ESCUELA N 46 CNEL MARIANO SANTIBAÑEZ",
            "matricula": "273",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380035900",
            "escuela": "ESCUELA NORMAL SUPERIOR GENDARMERIA NACIONAL",
            "matricula": "412",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380038800",
            "escuela": "ESCUELA NOCTURNA N 429",
            "matricula": "21",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380058500",
            "escuela": "COLEGIO CATÓLICO CRISTO REY",
            "matricula": "864",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380054301",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 3",
            "matricula": "12",
            "consultor": "TORRICO CARINA",
            "localidad": "SUSQUES",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380055400",
            "escuela": "ESCUELA N 337 ZOILO ROCHA",
            "matricula": "11",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "EL PALMAR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380055800",
            "escuela": "COLEGIO NUEVA SIEMBRA",
            "matricula": "388",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380089201",
            "escuela": "J.I.N. 27",
            "matricula": "114",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "YUTO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380051800",
            "escuela": "ESCUELA N 363 POLICIA FEDERAL ARGENTINA",
            "matricula": "29",
            "consultor": "GARCIA DANIEL",
            "localidad": "PUESTO SEY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380057000",
            "escuela": "ESCUELA ESPECIAL N 12 DR GUILLERMO SNOPEK",
            "matricula": "0",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Integración - Especial",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380052200",
            "escuela": "ESCUELA N 49 NUESTRA SEÑORA DE LA MEDALLA MILAGROSA",
            "matricula": "34",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LA CIENAGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053000",
            "escuela": "ESCUELA N 146 MAESTRO ARGENTINO",
            "matricula": "20",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PAICONE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053400",
            "escuela": "ESCUELA N 431 ISABEL LA CATÓLICA",
            "matricula": "387",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094201",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - CIANZO",
            "matricula": "11",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CIANZO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380100004",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 CASA COLORADA",
            "matricula": "16",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CASA COLORADA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380056600",
            "escuela": "ASOCIACIÓN CAMINEMOS JUNTOS",
            "matricula": "73",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Integración - Especial\r\nJardin de infantes - Especial\r\nJardin maternal - Especial\r\nPrimaria de 7 años - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nEducacion integral para adolescentes y jovenes - Especial\r\nCursos/Talleres de la Escuela Especial - Especial",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380035200",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 10",
            "matricula": "499",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380052001",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR N 1",
            "matricula": "551",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380036000",
            "escuela": "ESCUELA N 245 JUAN XXIII",
            "matricula": "506",
            "consultor": "TORRICO CARINA",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Jardin maternal - Especial\r\nPrimaria de 7 años - Común\r\nIntegración - Especial\r\nJardin de infantes - Especial",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053800",
            "escuela": "ESCUELA N 153",
            "matricula": "114",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380054200",
            "escuela": "ESCUELA PRIMARIA N 336",
            "matricula": "220",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LA BAJADA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380058900",
            "escuela": "BACHILLERATO PROVINCIAL N 24",
            "matricula": "166",
            "consultor": "LEONAR DIEGO",
            "localidad": "LOZANO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380059300",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 1",
            "matricula": "516",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380059700",
            "escuela": "ESCUELA PROFESIONAL N 14 SANTA BARBARA",
            "matricula": "63",
            "consultor": "CRUZ MIRTA",
            "localidad": "YUTO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380060200",
            "escuela": "ESCUELA PROFESIONAL N 2",
            "matricula": "69",
            "consultor": "CRUZ MIRTA",
            "localidad": "EL AGUILAR",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380060700",
            "escuela": "ESCUELA PROFESIONAL N 4 JUANA MANUELA GORRITI",
            "matricula": "331",
            "consultor": "FLORES JORGE",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380061100",
            "escuela": "ESCUELA DE CAPACITACIÓN LABORAL N 615",
            "matricula": "250",
            "consultor": "FLORES JORGE",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380062800",
            "escuela": "CENTRO DE DESARROLLO INFANTIL SANTA RITA",
            "matricula": "120",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380064200",
            "escuela": "ESCUELA N 443 DR ROBERTO RUBEN DOMINGUEZ",
            "matricula": "632",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380008300",
            "escuela": "BACHILLERATO PROVINCIAL NOCTURNO PARA ADULTOS N 1 BACA OJEDA",
            "matricula": "97",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090100",
            "escuela": "INSTITUTO DE ENSEÑANZA DE MECANICA DENTAL SAN SALVADOR DE JUJUY",
            "matricula": "50",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380063600",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 10",
            "matricula": "80",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "HORNILLOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380089300",
            "escuela": "COLEGIO SECUNDARIO N 47",
            "matricula": "367",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380096900",
            "escuela": "JARDIN MATERNAL TIC TAC",
            "matricula": "26",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380063301",
            "escuela": "COLEGIO EVANGELICO CRISTO REY",
            "matricula": "84",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380094200",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 E.V.",
            "matricula": "0",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380100007",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 PASO DE JAMA",
            "matricula": "14",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SUSQUES",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101503",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.P. N 4",
            "matricula": "15",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380100009",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 MIYUYOC",
            "matricula": "7",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "MIYUYOC",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380094205",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - CHORCAN",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CHORCAN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380092200",
            "escuela": "COLEGIO SECUNDARIO DE ARTES N 53",
            "matricula": "219",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380025600",
            "escuela": "ESCUELA N 428",
            "matricula": "26",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN JUAN DE POZO COLORA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380026000",
            "escuela": "ESCUELA N 154",
            "matricula": "3",
            "consultor": "FLORES JORGE",
            "localidad": "COCHAGOSTA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380026400",
            "escuela": "ESCUELA N 72",
            "matricula": "15",
            "consultor": "TORRICO CARINA",
            "localidad": "TAMBILLOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380026800",
            "escuela": "ESCUELA N 195",
            "matricula": "26",
            "consultor": "GARCIA DANIEL",
            "localidad": "PASTOS CHICOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380027200",
            "escuela": "ESCUELA N 174 SANTA RITA",
            "matricula": "240",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380027600",
            "escuela": "ESCUELA N 249 GAUCHOS DE GUEMES",
            "matricula": "56",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "BARRIOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380028000",
            "escuela": "ESCUELA N 382 NARCISO LAPRIDA",
            "matricula": "12",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CASTI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380028400",
            "escuela": "ESCUELA N 25 SARG. JOSE MARIANO GOMEZ",
            "matricula": "64",
            "consultor": "CRUZ MIRTA",
            "localidad": "YAVI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380039000",
            "escuela": "ESCUELA N 200",
            "matricula": "12",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LA LUCRECIA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380039400",
            "escuela": "ESCUELA NOCTURNA N 160 20 DE JUNIO",
            "matricula": "20",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380003601",
            "escuela": "CENTRO EDUCATIVO NIVEL SECUNDARIO N 43 MANUEL BELGRANO",
            "matricula": "303",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380038501",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 4",
            "matricula": "444",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "PALPALA",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380014100",
            "escuela": "ESCUELA N 378",
            "matricula": "75",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LA BANDA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380014500",
            "escuela": "ESCUELA N 70",
            "matricula": "7",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "CHAUPI RODEO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380040200",
            "escuela": "ESCUELA N 247 NICOLAS AVELLANEDA",
            "matricula": "327",
            "consultor": "MARTIN RUARTE",
            "localidad": "CORONEL ARIAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380040600",
            "escuela": "ESCUELA N 37 FELIPE ANTONIO DE IRIARTE",
            "matricula": "412",
            "consultor": "LEONAR DIEGO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380042200",
            "escuela": "BACHILLERATO PROVINCIAL N 7 CARLOS ALBERTO ALVARADO",
            "matricula": "335",
            "consultor": "TORRICO CARINA",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380014900",
            "escuela": "ESCUELA N 26 ENRIQUE SALAZAR CAMARENA",
            "matricula": "15",
            "consultor": "TORRICO CARINA",
            "localidad": "RODERO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015300",
            "escuela": "ESCUELA N 198 CIUDAD DE LA PLATA",
            "matricula": "17",
            "consultor": "FLORES JORGE",
            "localidad": "CHORCAN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380008700",
            "escuela": "ESCUELA MODELO DE EDUCACIÓN INTEGRAL",
            "matricula": "276",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380062900",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA - REGION I",
            "matricula": "121",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380041000",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 4 \"NELLY ARRIETA\"",
            "matricula": "369",
            "consultor": "CRUZ MIRTA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Itinerario formativo - Común\r\nSecundaria Completa req. 7 años - Común\r\nTrayecto técnico profesional - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380042600",
            "escuela": "BACHILLERATO PROVINCIAL N 8 SALVADOR MAZZA",
            "matricula": "345",
            "consultor": "CABELLO DELFOR",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380066000",
            "escuela": "COLEGIO POLIMODAL N 6 CTE BERNARDO JIMENEZ",
            "matricula": "163",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "HUACALERA",
            "ofertaLocal": "Itinerario formativo - Común\r\nPolimodal - Común\r\nTrayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380065500",
            "escuela": "COLEGIO POLIMODAL N 1",
            "matricula": "25",
            "consultor": "FLORES JORGE",
            "localidad": "CUSI CUSI",
            "ofertaLocal": "Polimodal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380081200",
            "escuela": "COLEGIO SECUNDARIO N 25",
            "matricula": "265",
            "consultor": "GARCIA DANIEL",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380084400",
            "escuela": "COLEGIO SECUNDARIO N 35",
            "matricula": "133",
            "consultor": "LEONAR DIEGO",
            "localidad": "LOS ALISOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089400",
            "escuela": "COLEGIO SECUNDARIO N 48",
            "matricula": "227",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094203",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - PALCA DE APARZO",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PALCA DE APARZO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380094213",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - LAS ESCALERAS",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LAS ESCALERAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380017300",
            "escuela": "ESCUELA N 204 CARMEN AVILA DE NAVERAN",
            "matricula": "492",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016900",
            "escuela": "BACHILLERATO PROVINCIAL N 22 HÉROES DE MALVINAS",
            "matricula": "570",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380043000",
            "escuela": "COLEGIO POLIMODAL N 8 JUANA AZURDUY",
            "matricula": "323",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380010500",
            "escuela": "ESCUELA N 322 INDEPENDENCIA DEL PERU",
            "matricula": "278",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LOS ALISOS",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380041400",
            "escuela": "ESCUELA N 40 9 DE JULIO",
            "matricula": "772",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380015700",
            "escuela": "ESCUELA N 24 BRIGADIER GRAL CORNELIO SAAVEDRA",
            "matricula": "584",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380057300",
            "escuela": "ESCUELA NOCTURNA N 105 WENCESLAO GALLARDO",
            "matricula": "37",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016100",
            "escuela": "ESCUELA N 321 PROVINCIA DE BUENOS AIRES",
            "matricula": "218",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016500",
            "escuela": "ESCUELA N 228 ALTOS HORNOS ZAPLA",
            "matricula": "33",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "CENTRO FORESTAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380061200",
            "escuela": "ESCUELA PROFESIONAL N 6",
            "matricula": "604",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PERICO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380061600",
            "escuela": "ESCUELA PROFESIONAL N 9 GREGORIA MATORRAS",
            "matricula": "94",
            "consultor": "FLORES JORGE",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380064300",
            "escuela": "ESCUELA DE GESTIÓN PRIVADA NUEVA ESPERANZA",
            "matricula": "429",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380064700",
            "escuela": "ESCUELA N 445 NUESTRA SEÑORA DE LA MERCED",
            "matricula": "303",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380065100",
            "escuela": "COLEGIO POLIMODAL N 3",
            "matricula": "498",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010900",
            "escuela": "ESCUELA N 19 DELFÍN PUCH",
            "matricula": "231",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN ANTONIO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011700",
            "escuela": "ESCUELA PRIMARIA N 262 LUIS ALBERTO ALVAREZ",
            "matricula": "2",
            "consultor": "TORRICO CARINA",
            "localidad": "TIRAXI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012100",
            "escuela": "ESCUELA N 302 ESTADOS UNIDOS DE BRASIL",
            "matricula": "87",
            "consultor": "LEONAR DIEGO",
            "localidad": "GUERRERO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012500",
            "escuela": "COLEGIO PRIVADO NUEVA GENERACIÓN",
            "matricula": "109",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380084000",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL MUNICIPAL N 1",
            "matricula": "196",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090200",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR INTERCULTURAL CAMPITA GUAZU GLORIA PEREZ",
            "matricula": "615",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380024400",
            "escuela": "ESCUELA N 201 SALVADOR CANUTO MARTINEZ",
            "matricula": "425",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380025300",
            "escuela": "ESCUELA PRIMARIA N 350",
            "matricula": "27",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "COLORADOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380025700",
            "escuela": "ESCUELA N 189 BRIGADIER GRAL JUAN FACUNDO QUIROGA",
            "matricula": "16",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN JUAN DE QUILLAQUES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380027800",
            "escuela": "ESCUELA N 170 EXODO JUJEÑO",
            "matricula": "13",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SURIPUGIO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380026500",
            "escuela": "ESCUELA N 389 PASO DE LOS ANDES",
            "matricula": "101",
            "consultor": "MARTIN RUARTE",
            "localidad": "CATUA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380026900",
            "escuela": "ESCUELA N 361 27 DE FEBRERO",
            "matricula": "281",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SUSQUES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380028600",
            "escuela": "ESCUELA N 134",
            "matricula": "17",
            "consultor": "CABELLO DELFOR",
            "localidad": "LARCAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380027300",
            "escuela": "ESCUELA N 73 MIGUEL ESTANISLAO SOLER",
            "matricula": "201",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380029000",
            "escuela": "ESCUELA N 319 SAN ANTONIO MARIA CLARET",
            "matricula": "14",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN FRANCISCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380063500",
            "escuela": "ESCUELA PRIMARIA N 441 JESUS MAESTRO",
            "matricula": "825",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380027700",
            "escuela": "ESCUELA N 1 DE FRONTERA GRAL. BELGRANO",
            "matricula": "548",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380065300",
            "escuela": "COLEGIO POLIMODAL N 2",
            "matricula": "347",
            "consultor": "TORRICO CARINA",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380066700",
            "escuela": "ESCUELA N 452 LEGADO BELGRANIANO",
            "matricula": "587",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380029400",
            "escuela": "ESCUELA N 334",
            "matricula": "17",
            "consultor": "GARCIA DANIEL",
            "localidad": "PUEBLO VIEJO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380064900",
            "escuela": "COLEGIO SAN JOSE PATRONO",
            "matricula": "232",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nSecundaria Completa req. 7 años - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380023100",
            "escuela": "ESCUELA N 66 MARTÍN MIGUEL DE GUEMES",
            "matricula": "381",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL TALAR",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380028100",
            "escuela": "ESCUELA N 303 TTE DE NAVÍO MARCELO GUSTAVO MARQUEZ",
            "matricula": "14",
            "consultor": "GARCIA DANIEL",
            "localidad": "INTI CANCHA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031000",
            "escuela": "ESCUELA N 315",
            "matricula": "10",
            "consultor": "CRUZ MIRTA",
            "localidad": "POTRERO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029800",
            "escuela": "ESCUELA N 81 12 DE OCTUBRE",
            "matricula": "17",
            "consultor": "FLORES JORGE",
            "localidad": "CHALCAMAYOC",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380028500",
            "escuela": "ESCUELA N 169",
            "matricula": "12",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "RODEO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030200",
            "escuela": "ESCUELA N 297 TIERRA DEL FUEGO",
            "matricula": "12",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "PIEDRA NEGRA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380024900",
            "escuela": "ESCUELA N 280 MAESTRO JOSE RICARDO ESTANI",
            "matricula": "10",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN BERNARDO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380028900",
            "escuela": "ESCUELA N 33",
            "matricula": "12",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PUESTO GRANDE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380013400",
            "escuela": "COLEGIO SECUNDARIO N 1 CRUCERO GRAL BELGRANO",
            "matricula": "601",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380013800",
            "escuela": "ESCUELA N 101 ANIBAL HELGUERA SANCHEZ",
            "matricula": "60",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "COLONIA SAN JOSE",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380030600",
            "escuela": "ESCUELA N 113 ANTARTIDA ARGENTINA",
            "matricula": "92",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LA QUIACA VIEJA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380014200",
            "escuela": "ESCUELA N 129 PACHACAMAC",
            "matricula": "3",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "VOLCAN DE YACORAITE",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015000",
            "escuela": "ESCUELA N 254 ÉXODO JUJEÑO",
            "matricula": "24",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "PALCA DE APARZO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015400",
            "escuela": "ESCUELA N 412 INGENIERO CARLOS SNOPEK",
            "matricula": "619",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380021000",
            "escuela": "ESCUELA NOCTURNA N 165",
            "matricula": "34",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380021400",
            "escuela": "COLEGIO SECUNDARIO PROVINCIA DE MISIONES",
            "matricula": "369",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LA ESPERANZA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380023500",
            "escuela": "ESCUELA N 179 NUESTRA INDEPENDENCIA",
            "matricula": "31",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "VALLE COLORADO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380024000",
            "escuela": "ESCUELA N 311 BERNARDINO RIVADAVIA",
            "matricula": "661",
            "consultor": "CABELLO DELFOR",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380029300",
            "escuela": "ESCUELA N 364 DR JORGE URO",
            "matricula": "13",
            "consultor": "CABELLO DELFOR",
            "localidad": "TAFNA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029700",
            "escuela": "ESCUELA N 275 LIBERTADORES DE AMERICA",
            "matricula": "22",
            "consultor": "GARCIA DANIEL",
            "localidad": "CARA - CARA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031400",
            "escuela": "ESCUELA N 162",
            "matricula": "19",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LLULLUCHAYOC",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030100",
            "escuela": "ESCUELA N 300 MARTÍN MIGUEL DE GUEMES",
            "matricula": "55",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "OJO DE AGUA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030500",
            "escuela": "ESCUELA N 42 FRAY MAMERTO ESQUIU",
            "matricula": "15",
            "consultor": "CABELLO DELFOR",
            "localidad": "SANSANA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032200",
            "escuela": "ESCUELA N 71",
            "matricula": "14",
            "consultor": "FLORES JORGE",
            "localidad": "QUETA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030900",
            "escuela": "ESCUELA N 177",
            "matricula": "11",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LA REDONDA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031300",
            "escuela": "ESCUELA N 58 DR GREGORIO FUNES",
            "matricula": "27",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "TABLADITAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032600",
            "escuela": "ESCUELA N 36 LUIGI LOSI",
            "matricula": "14",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "OROSMAYO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031700",
            "escuela": "ESCUELA N 324 SGTO CRISTINO EUFRACIO ROJAS",
            "matricula": "14",
            "consultor": "FLORES JORGE",
            "localidad": "AGUA CALIENTE DE LA PUNA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033400",
            "escuela": "ESCUELA N 342 MAESTRO HUMBERTO ANGEL BURGOS",
            "matricula": "6",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "YOSCABA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032100",
            "escuela": "ESCUELA N 43",
            "matricula": "6",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "QUICHAGUA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033800",
            "escuela": "ESCUELA N 352",
            "matricula": "4",
            "consultor": "CABELLO DELFOR",
            "localidad": "ALFARCITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034200",
            "escuela": "ESCUELA N 76 SOLDADOS DE MI PATRIA",
            "matricula": "12",
            "consultor": "CABELLO DELFOR",
            "localidad": "MOLULO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380032900",
            "escuela": "ESCUELA N 3 DE FRONTERA",
            "matricula": "15",
            "consultor": "GARCIA DANIEL",
            "localidad": "ORATORIO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380033300",
            "escuela": "ESCUELA N 317 MALVINAS ARGENTINAS",
            "matricula": "18",
            "consultor": "CABELLO DELFOR",
            "localidad": "CHOCOITE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380013600",
            "escuela": "ESCUELA N 411 MAYOR ENRIQUE CARLOS LUTTERAL",
            "matricula": "375",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009000",
            "escuela": "BACHILLERATO PROVINCIAL N 6 ISLAS MALVINAS",
            "matricula": "749",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009400",
            "escuela": "COLEGIO N 2 ARMADA ARGENTINA",
            "matricula": "565",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010700",
            "escuela": "ESCUELA N 394 DR TEOFILO SANCHEZ DE BUSTAMANTE",
            "matricula": "51",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "AVALOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380019400",
            "escuela": "ESCUELA ESPECIAL N 11 PROF LUIS BRAILLE",
            "matricula": "35",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin maternal - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Especial\r\nTaller de Secundaria/Polimodal - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008600",
            "escuela": "COLEGIO SECUNDARIO INFORMÁTICO BLAISE PASCAL",
            "matricula": "319",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380011900",
            "escuela": "BACHILLERATO PROVINCIAL N 19 PROF CLAUDIA GRACIELA ALONSO",
            "matricula": "128",
            "consultor": "MARTIN RUARTE",
            "localidad": "YALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380013200",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1 GRAL SAVIO",
            "matricula": "1013",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380055600",
            "escuela": "COLEGIO MARTÍN PESCADOR",
            "matricula": "634",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380056000",
            "escuela": "ESCUELA N 439",
            "matricula": "19",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAUZALITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380057200",
            "escuela": "ESCUELA N 237 20 DE JUNIO",
            "matricula": "40",
            "consultor": "CABELLO DELFOR",
            "localidad": "CASPALA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380057600",
            "escuela": "ESCUELA NOCTURNA N 397",
            "matricula": "30",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380058400",
            "escuela": "ESCUELA N 440 BATALLA DE QUERA",
            "matricula": "441",
            "consultor": "TORRICO CARINA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380011500",
            "escuela": "ESCUELA N 379",
            "matricula": "20",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "TESORERO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016800",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 2 DR MANUEL BELGRANO",
            "matricula": "1180",
            "consultor": "CABELLO DELFOR",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa - Adultos\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016400",
            "escuela": "COLEGIO FASTA SAN ALBERTO MAGNO O P",
            "matricula": "750",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "RIO BLANCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380018800",
            "escuela": "BACHILLERATO PROVINCIAL N 4 ACELERADO PARA ADULTOS EJERCITO DEL NORTE",
            "matricula": "93",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012400",
            "escuela": "ESCUELA N 147 REG 20 DE INFANTERÍA DE MONTAÑA",
            "matricula": "773",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380070300",
            "escuela": "JARDIN INDEPENDIENTE N 23",
            "matricula": "58",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380070700",
            "escuela": "FUNDACIÓN DARIO RODRIGO LÓPEZ CABANA DARLOCAB",
            "matricula": "44",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380071100",
            "escuela": "JARDÍN DE INFANTES JUAN XXIII",
            "matricula": "136",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094900",
            "escuela": "INSTITUTO SUPERIOR SAN GREGORIO",
            "matricula": "29",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380012800",
            "escuela": "ESCUELA N 298 DR PLINIO ZABALA",
            "matricula": "37",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL PONGO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012300",
            "escuela": "ESCUELA N 190 DR MACEDONIO GRAZ",
            "matricula": "424",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380095300",
            "escuela": "COLEGIO SECUNDARIO N 56",
            "matricula": "114",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011100",
            "escuela": "ESCUELA N 44 JOSE IGNACIO GORRITI",
            "matricula": "100",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LEON",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380014800",
            "escuela": "ESCUELA N 380 RIM 20 CAZADORES DE LOS ANDES",
            "matricula": "4",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "RONQUE",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380013500",
            "escuela": "COLEGIO PRIVADO MODELO PALPALA",
            "matricula": "433",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380012700",
            "escuela": "ESCUELA N 273 FRANCISCO ANTONIO RIZZUTO",
            "matricula": "20",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LOS BLANCOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380013100",
            "escuela": "ESCUELA N 274",
            "matricula": "5",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL CUCHO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380014000",
            "escuela": "ESCUELA N 341 VALENTINA ALVAREZ PRADO DE PEÑA",
            "matricula": "66",
            "consultor": "CRUZ MIRTA",
            "localidad": "HORNILLOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380014400",
            "escuela": "ESCUELA N 122 JULIO ARGENTINO ROCA",
            "matricula": "4",
            "consultor": "FLORES JORGE",
            "localidad": "VICUÑAYOC",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015200",
            "escuela": "ESCUELA N 62 ÁNGEL ESTRADA",
            "matricula": "22",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CIANZO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380015600",
            "escuela": "ESCUELA N 11 TTE. GRAL. BARTOLOME MITRE",
            "matricula": "198",
            "consultor": "LEONAR DIEGO",
            "localidad": "EL CHAMICAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016000",
            "escuela": "ESCUELA N 118 JUAN MANUEL DE ARISMENDI",
            "matricula": "7",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "TERMAS DE REYES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380018000",
            "escuela": "ESCUELA N 210 CARLOS PELLEGRINI",
            "matricula": "5",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "AZUL PAMPA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380018400",
            "escuela": "ESCUELA N 239 DANIEL DOMINGUEZ",
            "matricula": "59",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "HIPOLITO YRIGOYEN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380019800",
            "escuela": "ESCUELA N 318 SAN RAFAEL",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "VILLA MONTES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380020200",
            "escuela": "ESCUELA N 142 PENTI RANI ÑANDETAI",
            "matricula": "197",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "BANANAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380020600",
            "escuela": "ESCUELA N 148 POLICÍA DE LA PROVINCIA DE JUJUY",
            "matricula": "7",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN LUCAS",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380038500",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 4",
            "matricula": "400",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380038900",
            "escuela": "ESCUELA NOCTURNA N 400",
            "matricula": "5",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380039300",
            "escuela": "ESCUELA N 305 EJERCITO ARGENTINO",
            "matricula": "2",
            "consultor": "TORRICO CARINA",
            "localidad": "CORAYA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380060800",
            "escuela": "MISIÓN DE CULTURA RURAL Y DOMESTICA N 23",
            "matricula": "60",
            "consultor": "FLORES JORGE",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380063300",
            "escuela": "COLEGIO EVANGELICO CRISTO REY",
            "matricula": "180",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380085200",
            "escuela": "COLEGIO SECUNDARIO DE ARTES N 42",
            "matricula": "145",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380086000",
            "escuela": "JARDIN INDEPENDIENTE N 4",
            "matricula": "158",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087600",
            "escuela": "JARDÍN INDEPENDIENTE NUCLEADO N 11",
            "matricula": "93",
            "consultor": "CRUZ MIRTA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380097000",
            "escuela": "JARDIN DE INFANTES Y MATERNAL SONRISAS DE LA TIERRA",
            "matricula": "18",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380097400",
            "escuela": "J.I.N. 1",
            "matricula": "241",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380097800",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR N 11",
            "matricula": "2230",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380038100",
            "escuela": "ESCUELA ESPECIAL N 8 GUILLERMO SALIM QUINTAR",
            "matricula": "61",
            "consultor": "FLORES JORGE",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Especial\r\nJardin maternal - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380056700",
            "escuela": "ASOCIACIÓN MARI-JO ATENCIÓN INTEGRAL A LAS PERSONAS CON CAPACIDADES DIFERENTES",
            "matricula": "0",
            "consultor": "CABELLO DELFOR",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Cursos/Talleres de la Escuela Especial - Especial",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380000200",
            "escuela": "ESCUELA N 396 DR ANTONIO ROCHA SOLORZANO",
            "matricula": "386",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380000600",
            "escuela": "COLEGIO PRIVADO PABLO PIZZURNO -NIVEL SECUNDARIO-",
            "matricula": "137",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380015800",
            "escuela": "BACHILLERATO PROVINCIAL N 16 PASO DE JAMA",
            "matricula": "258",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380020000",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 11",
            "matricula": "171",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "VINALITO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380037700",
            "escuela": "BACHILLERATO PROVINCIAL N 12 AVELINO BAZAN",
            "matricula": "61",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL AGUILAR",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380085600",
            "escuela": "COLEGIO SECUNDARIO N 44",
            "matricula": "45",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "VARAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380094209",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - OLAROZ CHICO",
            "matricula": "5",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "OLAROZ CHICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380019600",
            "escuela": "COLEGIO PRIVADO SAN AGUSTIN",
            "matricula": "742",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nJardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380039700",
            "escuela": "ESCUELA NUEVA N 392 SAN JUAN BAUTISTA DE LA SALLE",
            "matricula": "624",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016600",
            "escuela": "ESCUELA N 22 REPÚBLICA DE BOLIVIA",
            "matricula": "55",
            "consultor": "GARCIA DANIEL",
            "localidad": "CARAHUNCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380057100",
            "escuela": "ESCUELA ESPECIAL N 6 DR. VICENTE ARROYABE",
            "matricula": "32",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380001100",
            "escuela": "COLEGIO SANTA BARBARA",
            "matricula": "1063",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380001600",
            "escuela": "COLEGIO NUESTRA SEÑORA DEL HUERTO",
            "matricula": "1280",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380016200",
            "escuela": "ESCUELA TÉCNICA PROVINCIAL N 1 ARISTOBULO VARGAS BELMONTE",
            "matricula": "687",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380017000",
            "escuela": "ESCUELA PRIMARIA N 434 MINERO JUJEÑO",
            "matricula": "670",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380017400",
            "escuela": "ESCUELA N 176 DE LA PATRIA",
            "matricula": "260",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380017800",
            "escuela": "ESCUELA N 31 CORONEL ARIAS",
            "matricula": "258",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "HUACALERA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380018200",
            "escuela": "ESCUELA N 193 BALDOMERO FERNANDEZ MORENO",
            "matricula": "16",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "LA POMA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380019100",
            "escuela": "ESCUELA N 391 PRIMERA FUNDACIÓN DE JUJUY CIUDAD DE NIEVA",
            "matricula": "239",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380020400",
            "escuela": "ESCUELA N 233 MAESTRO JOSE AMANCIO APARICIO",
            "matricula": "3",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SANTA BARBARA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380020800",
            "escuela": "ESCUELA N 234 PARQUES NACIONALES",
            "matricula": "25",
            "consultor": "FLORES JORGE",
            "localidad": "PAMPICHUELA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380021200",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 2 DR HORACIO GUZMAN",
            "matricula": "406",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380021800",
            "escuela": "ESCUELA N 50 OBISPO PADILLA",
            "matricula": "352",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380022200",
            "escuela": "ESCUELA DE COMERCIO N 1 DR JOSE INGENIEROS",
            "matricula": "1512",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380022600",
            "escuela": "ESCUELA N 281 PROVINCIA DE SANTIAGO DEL ESTERO",
            "matricula": "657",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380038600",
            "escuela": "ESCUELA NOCTURNA N 106",
            "matricula": "27",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380059500",
            "escuela": "ESCUELA PROFESIONAL N 7 FELISA S.R.S. DE BUSTAMANTE",
            "matricula": "627",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380059900",
            "escuela": "ESCUELA PROFESIONAL N 3",
            "matricula": "642",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380102800",
            "escuela": "JARDIN INDEPENDIENTE N 24",
            "matricula": "164",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380005401",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA N 195 - CURSO PARA ADULTOS - U.1",
            "matricula": "2",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012601",
            "escuela": "COLEGIO LOS LAPACHOS",
            "matricula": "315",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380088901",
            "escuela": "J.I.N. 24",
            "matricula": "163",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380020900",
            "escuela": "ESCUELA N 130",
            "matricula": "3",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "ALTO CALILEGUA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380097501",
            "escuela": "JARDIN INDEPENDIENTE N 9",
            "matricula": "70",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380004202",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 7 POPULORUM PROGRESSIO",
            "matricula": "354",
            "consultor": "CABELLO DELFOR",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380081402",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR EN FORMACIÓN CAPACITACIÓN Y SUPERACIÓN LIC MYRIAM B. GLOSS",
            "matricula": "44",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA QUIACA",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 1"
        },
        {
            "cue": "380038503",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 4",
            "matricula": "239",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016700",
            "escuela": "ESCUELA ESPECIAL N 2 JUAN PABLO II",
            "matricula": "63",
            "consultor": "FLORES JORGE",
            "localidad": "PALPALA",
            "ofertaLocal": "Cursos/Talleres de la Escuela Especial - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nJardin maternal - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380025000",
            "escuela": "ESCUELA N 308 PROVINCIA DE SAN JUAN",
            "matricula": "42",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "TUNALITO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380017100",
            "escuela": "ESCUELA N 119 GENERAL SAVIO",
            "matricula": "1003",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380016300",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 1 EL BRETE",
            "matricula": "190",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "EL BRETE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380018700",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 3 CORONEL ARIAS",
            "matricula": "140",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380023400",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 5",
            "matricula": "590",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "YUTO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380027400",
            "escuela": "ESCUELA N 265 GRAL SAN MARTÍN",
            "matricula": "238",
            "consultor": "CABELLO DELFOR",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380092506",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - PAICONE",
            "matricula": "12",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PAICONE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380100001",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 CANGREJILLOS",
            "matricula": "34",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "YAVI",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380021300",
            "escuela": "BACHILLERATO PROVINCIAL N 11 MONSENOR SIXTO VILLOLDO",
            "matricula": "220",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380100005",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 COCTACA",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "COCTACA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380035203",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 10",
            "matricula": "51",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "YUTO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380017900",
            "escuela": "ESCUELA N 13 DR ERNESTO EUDORO PADILLA",
            "matricula": "543",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "MAIMARA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nJardin de infantes - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380059100",
            "escuela": "COLEGIO MAYOR JUJUY",
            "matricula": "241",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380025200",
            "escuela": "ESCUELA N 395 MUTUALIDAD DEL CIRCULO DE OFICIALES DE LA POLICIA DE LA PROVINCIA",
            "matricula": "75",
            "consultor": "CRUZ MIRTA",
            "localidad": "SANTUARIO DE 3 POZOS",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380022500",
            "escuela": "ESCUELA N 217 AERONÁUTICA ARGENTINA",
            "matricula": "411",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380091201",
            "escuela": "J.I.N. 35",
            "matricula": "100",
            "consultor": "FLORES JORGE",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380021700",
            "escuela": "ESCUELA NOCTURNA N 9 JUANA MANSO",
            "matricula": "41",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380017500",
            "escuela": "ESCUELA N 207 GRAL DE BRIG ARMANDO PEDRO PIO MARTIJENA",
            "matricula": "755",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380022100",
            "escuela": "ESCUELA N 38 JUANITA STEVENS",
            "matricula": "765",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380018300",
            "escuela": "ESCUELA N 90 CAPITAN DE LOS ANDES",
            "matricula": "54",
            "consultor": "CRUZ MIRTA",
            "localidad": "TRES CRUCES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380019700",
            "escuela": "ESCUELA N 294 PATRICIAS ARGENTINAS",
            "matricula": "44",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "MIRAFLORES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380020100",
            "escuela": "ESCUELA N 286 FRANCISCO NARCISO LAPRIDA",
            "matricula": "199",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "VINALITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380020500",
            "escuela": "ESCUELA N 248 NTRA SRA DEL ROSARIO DE RIO BLANCO Y PAYPAYA",
            "matricula": "90",
            "consultor": "CRUZ MIRTA",
            "localidad": "SANTA ANA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380023000",
            "escuela": "ESCUELA N 202 GRAL ENRIQUE MOSCONI",
            "matricula": "560",
            "consultor": "GARCIA DANIEL",
            "localidad": "YUTO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380025400",
            "escuela": "ESCUELA N 93",
            "matricula": "5",
            "consultor": "GARCIA DANIEL",
            "localidad": "HUACHICZOCANA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380023800",
            "escuela": "ESCUELA N 213 MARTÍN RAUL GALÁN",
            "matricula": "729",
            "consultor": "GARCIA DANIEL",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380025800",
            "escuela": "ESCUELA N 355",
            "matricula": "2",
            "consultor": "TORRICO CARINA",
            "localidad": "QUERA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380024300",
            "escuela": "ESCUELA N 413 DRA ALICIA MOREAU DE JUSTO",
            "matricula": "354",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380026200",
            "escuela": "ESCUELA N 381",
            "matricula": "23",
            "consultor": "GARCIA DANIEL",
            "localidad": "QUEBRALEÑA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380024700",
            "escuela": "ESCUELA N 377",
            "matricula": "29",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "TUMBAYA GRANDE",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380026600",
            "escuela": "ESCUELA N 365 EDUARDO CALSINA",
            "matricula": "103",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "HUANCAR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380027000",
            "escuela": "ESCUELA N 261 PROVINCIA DE TUCUMAN",
            "matricula": "1061",
            "consultor": "CRUZ MIRTA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380047900",
            "escuela": "ESCUELA N 370",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CASILLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380048300",
            "escuela": "ESCUELA N 375 LÁZARO CRUZ",
            "matricula": "25",
            "consultor": "FLORES JORGE",
            "localidad": "MIYUYOC",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380066900",
            "escuela": "INSTITUTO DE REHABILITACION DRA. NORMA BEATRIZ ISSA",
            "matricula": "52",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Integración - Especial\r\nJardin de infantes - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380100500",
            "escuela": "INSTITUTO \"SENTIR IV\"",
            "matricula": "86",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LOS ALISOS",
            "ofertaLocal": "Educacion integral para adolescentes y jovenes - Especial\r\nJardin de infantes - Especial\r\nPrimaria de 7 años - Especial\r\nSolo Secundaria Básica req. 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380048700",
            "escuela": "COLEGIO CATÓLICO JESUS MAESTRO",
            "matricula": "254",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380051900",
            "escuela": "BACHILLERATO PROVINCIAL N 13 COMANDANTE LUIS PIEDRABUENA",
            "matricula": "174",
            "consultor": "MARTIN RUARTE",
            "localidad": "SUSQUES",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380049900",
            "escuela": "ESCUELA N 230 ANTARTIDA ARGENTINA",
            "matricula": "76",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN ANTONIO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380024500",
            "escuela": "ESCUELA N 117 CARLOS ALBERTO BARCENA",
            "matricula": "29",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CHILCAYOC",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380050300",
            "escuela": "ESCUELA N 125 ZAFRA",
            "matricula": "56",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "EL PUESTO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380066100",
            "escuela": "COLEGIO SECUNDARIO N 5",
            "matricula": "391",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380034600",
            "escuela": "COLEGIO N 1 VIRGEN DE PUNTA CORRAL",
            "matricula": "117",
            "consultor": "LEONAR DIEGO",
            "localidad": "VOLCAN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380030800",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1",
            "matricula": "682",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380051100",
            "escuela": "ESCUELA N 325 INDEPENDENCIA",
            "matricula": "22",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN FRANCISCO DE ALFARCITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380035400",
            "escuela": "COLEGIO TECNICO INGENIERO HERMINIO ARRIETA",
            "matricula": "1064",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380029600",
            "escuela": "ESCUELA N 359",
            "matricula": "6",
            "consultor": "CRUZ MIRTA",
            "localidad": "CANGREJOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030000",
            "escuela": "ESCUELA N 292 ADUANA ARGENTINA",
            "matricula": "5",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "MINA BELGICA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380055900",
            "escuela": "COLEGIO SECUNDARIO N 2 FRANCISCA TRIGUERO DE ROCHA SOLORZANO",
            "matricula": "632",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380062100",
            "escuela": "COLEGIO SECUNDARIO N 3 SAN JOSE",
            "matricula": "779",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PERICO",
            "ofertaLocal": "Polimodal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380066500",
            "escuela": "COLEGIO SECUNDARIO N 6",
            "matricula": "338",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380097700",
            "escuela": "COLEGIO SECUNDARIO N 59 OLGA MARQUEZ DE AREDEZ",
            "matricula": "1366",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nSecundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380030400",
            "escuela": "ESCUELA N 333 TTE DE FRAGATA MIGUEL ANIBAL TANCO",
            "matricula": "35",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PUNTA DEL AGUA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380049100",
            "escuela": "ESCUELA N 82 REPÚBLICA DE VENEZUELA",
            "matricula": "68",
            "consultor": "FLORES JORGE",
            "localidad": "DON EMILIO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380049500",
            "escuela": "ESCUELA N 256 JOSE MANUEL ESTRADA",
            "matricula": "83",
            "consultor": "GARCIA DANIEL",
            "localidad": "BARRO NEGRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380035000",
            "escuela": "ESCUELA N 362 HÉROES DE MALVINAS",
            "matricula": "39",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CORANZULI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380035800",
            "escuela": "ESCUELA N 222",
            "matricula": "325",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053100",
            "escuela": "ESCUELA N 423 CIUDAD DE LA PLATA",
            "matricula": "24",
            "consultor": "CABELLO DELFOR",
            "localidad": "MISA RUMI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380036200",
            "escuela": "ESCUELA N 80 VICENTE LOPEZ Y PLANES",
            "matricula": "31",
            "consultor": "CABELLO DELFOR",
            "localidad": "CANGREJILLOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380036600",
            "escuela": "ESCUELA N 131 ALFONSINA STORNI",
            "matricula": "58",
            "consultor": "FLORES JORGE",
            "localidad": "LAGUNILLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380100900",
            "escuela": "JARDÍN INFANTES ADEP SUEÑITOS DEL MAÑANA",
            "matricula": "97",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380037000",
            "escuela": "ESCUELA N 425 DR GUILLERMO EUGENIO SNOPEK",
            "matricula": "51",
            "consultor": "CRUZ MIRTA",
            "localidad": "CIENEGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380037400",
            "escuela": "ESCUELA NORMAL HÉROES HUMAHUAQUEÑOS",
            "matricula": "378",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380072300",
            "escuela": "CENTRO DE DESARROLLO INFANTIL SAN ROQUE",
            "matricula": "226",
            "consultor": "CRUZ MIRTA",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380037800",
            "escuela": "ESCUELA N 133 PTE JUAN DOMINGO PERÓN",
            "matricula": "4",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "ALONSO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380038200",
            "escuela": "ESCUELA PROVINCIAL N 54 PROF. FILIBERTO URBANO CARRIZO",
            "matricula": "333",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN VICENTE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380052300",
            "escuela": "ESCUELA N 271",
            "matricula": "16",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA CIENAGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380052700",
            "escuela": "ESCUELA N 187 MARÍA TERESA URZAGASTI",
            "matricula": "30",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "RACHAITE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380053500",
            "escuela": "ESCUELA N 435 PROF HUMBERTO JUSTO AGUERO",
            "matricula": "550",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380053900",
            "escuela": "ESCUELA N 112 CNEL MANUEL DORREGO",
            "matricula": "601",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380054300",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 3",
            "matricula": "1195",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380056300",
            "escuela": "ESCUELA N 186",
            "matricula": "11",
            "consultor": "FLORES JORGE",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380057800",
            "escuela": "ESCUELA NOCTURNA N 401 DE JÓVENES Y ADULTOS",
            "matricula": "25",
            "consultor": "CABELLO DELFOR",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380058200",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR GUILLERMO CLELAND PATERSON",
            "matricula": "160",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380062500",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA - REGION III",
            "matricula": "373",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380063400",
            "escuela": "ESCUELA FRONTERA N 7",
            "matricula": "280",
            "consultor": "GARCIA DANIEL",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380072700",
            "escuela": "ESCUELA NOCTURNA N 448",
            "matricula": "20",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380071200",
            "escuela": "CENTRO DE ATENCIÓN INFANTIL NUESTRA SEÑORA DEL ROSARIO",
            "matricula": "69",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380073500",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR DE CAPACITACIÓN Y DESARROLLO EMPRESARIAL",
            "matricula": "71",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380072400",
            "escuela": "CENTRO DESARROLLO INFANTIL SAN JOSE",
            "matricula": "297",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380048200",
            "escuela": "ESCUELA N 116 COMANDANTE JUAN FRANCISCO PASTOR",
            "matricula": "4",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA CUEVA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380049800",
            "escuela": "ESCUELA N 313 CONSTANCIO CECILIO VIGIL",
            "matricula": "76",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN LUCAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380098100",
            "escuela": "J.I.N. 36",
            "matricula": "137",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380050200",
            "escuela": "ESCUELA N 306 DOMINGO FAUSTINO SARMIENTO",
            "matricula": "99",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "ARRAYANAL",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380101507",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.F. N 22",
            "matricula": "80",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380050600",
            "escuela": "ESCUELA N 320",
            "matricula": "99",
            "consultor": "CRUZ MIRTA",
            "localidad": "VALLE GRANDE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380052600",
            "escuela": "ESCUELA N 331",
            "matricula": "5",
            "consultor": "TORRICO CARINA",
            "localidad": "RUMI CRUZ",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380066800",
            "escuela": "ESCUELA N 450 DR ELVIO ADAN MARTELLI",
            "matricula": "301",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380036900",
            "escuela": "ESCUELA N 23 MARÍA DE LOS REMEDIOS ESCALADA DE SAN MARTÍN",
            "matricula": "94",
            "consultor": "TORRICO CARINA",
            "localidad": "RINCONADA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380070100",
            "escuela": "JARDÍN MATERNAL ADEP SUEÑITOS DEL MAÑANA",
            "matricula": "142",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380070500",
            "escuela": "JARDÍN INDEPENDIENTE N 16 RINCONCITO AZUL",
            "matricula": "86",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380072100",
            "escuela": "CENTRO DE DESARROLLO INFANTIL EVITA",
            "matricula": "117",
            "consultor": "CABELLO DELFOR",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380077200",
            "escuela": "CENTRO DE EDUCACIÓN NO FORMAL N 1",
            "matricula": "452",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380077600",
            "escuela": "ESCUELA N 456 SGTO JUAN BAUTISTA CABRAL",
            "matricula": "494",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380051400",
            "escuela": "ESCUELA N 290",
            "matricula": "22",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "ABRALAITE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380076800",
            "escuela": "INSTITUTO SUPERIOR DE ARTES",
            "matricula": "0",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común\r\nCursos de Capacitación de SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380034900",
            "escuela": "BACHILLERATO PROVINCIAL N 18 PROF HECTOR HUGO CAZON",
            "matricula": "108",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PURMAMARCA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380035300",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 4 25 DE FEBRERO",
            "matricula": "956",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380078400",
            "escuela": "ESCUELA NORMAL SUPERIOR SGTO JUAN BAUTISTA CABRAL",
            "matricula": "783",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380078000",
            "escuela": "COLEGIO SECUNDARIO N 18",
            "matricula": "32",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "CORANZULI",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380098900",
            "escuela": "ESCUELA TÉCNICA PROVINCIAL N 2",
            "matricula": "31",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "OLAROZ CHICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380048600",
            "escuela": "ESCUELA N 338 DR CARLOS ALBERTO ALVARADO",
            "matricula": "458",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL PIQUETE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380049400",
            "escuela": "ESCUELA N 98",
            "matricula": "21",
            "consultor": "FLORES JORGE",
            "localidad": "DON DAVID",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380051000",
            "escuela": "ESCUELA N 128 LEOPOLDO LUGONES",
            "matricula": "33",
            "consultor": "MARTIN RUARTE",
            "localidad": "RINCONADILLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380097200",
            "escuela": "JARDIN DE INFANTES LUNA DE CRISTAL",
            "matricula": "96",
            "consultor": "TORRICO CARINA",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380098400",
            "escuela": "INSTITUTO TECNICO PROFESIONAL SAN EXPEDITO",
            "matricula": "29",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380001000",
            "escuela": "CENTRO EDUCATIVO NIVEL SECUNDARIO N 310",
            "matricula": "100",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380001400",
            "escuela": "ESCUELA N 282 TAMBOR DE TACUARI",
            "matricula": "134",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "ARROYO COLORADO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380001900",
            "escuela": "ESCUELA N 343 CABO HUMBERTO CESAR ALEMÁN HÉROE DE MALVINAS",
            "matricula": "181",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LOS MANANTIALES",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380033700",
            "escuela": "ESCUELA N 358 ROSARIO VERA PEÑALOZA",
            "matricula": "8",
            "consultor": "TORRICO CARINA",
            "localidad": "ABRA MAYO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034100",
            "escuela": "ESCUELA N 196",
            "matricula": "12",
            "consultor": "CABELLO DELFOR",
            "localidad": "LOMA LARGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380034500",
            "escuela": "ESCUELA N 16 18 DE NOVIEMBRE",
            "matricula": "71",
            "consultor": "CRUZ MIRTA",
            "localidad": "TUMBAYA",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380035700",
            "escuela": "ESCUELA N 86 HIPOLITO IRIGOYEN",
            "matricula": "674",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380036100",
            "escuela": "ESCUELA N 29 PUNA ARGENTINA",
            "matricula": "58",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "CIENEGUILLAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380036500",
            "escuela": "ESCUELA NORMAL SUPERIOR SGTO JUAN BAUTISTA CABRAL",
            "matricula": "341",
            "consultor": "FLORES JORGE",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380037300",
            "escuela": "ESCUELA N 103 MINERIA ARGENTINA",
            "matricula": "37",
            "consultor": "FLORES JORGE",
            "localidad": "R. DE COYAHUAYMA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380099200",
            "escuela": "JARDIN INDEPENDIENTE NIÑOS DE BELEN",
            "matricula": "55",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380095700",
            "escuela": "JARDIN MATERNAL BILINGUE WAWA HUASI",
            "matricula": "155",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 1"
        },
        {
            "cue": "380100400",
            "escuela": "JARDÍN DE INFANTES NUCLEADO N 39",
            "matricula": "172",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380100800",
            "escuela": "COLEGIO PRIVADO ANTONIO BERNI",
            "matricula": "28",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380097300",
            "escuela": "COLEGIO SECUNDARIO N 60",
            "matricula": "141",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380050000",
            "escuela": "ESCUELA N 293 JUSTO JOSE DE URQUIZA",
            "matricula": "155",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PARAPETI",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380061000",
            "escuela": "MISIÓN MONOTÉCNICA Y DE EXTENSIÓN CULTURAL N 69",
            "matricula": "165",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380061400",
            "escuela": "MISIÓN MONOTÉCNICA Y DE EXTENSIÓN CULTURAL N 4",
            "matricula": "31",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SUSQUES",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380066200",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA - REGION II",
            "matricula": "124",
            "consultor": "GARCIA DANIEL",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380066600",
            "escuela": "ESCUELA N 451",
            "matricula": "320",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380079400",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 8 SAGRADO CORAZÓN",
            "matricula": "143",
            "consultor": "LEONAR DIEGO",
            "localidad": "RIO BLANCO",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380079800",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR TECNOLÓGICO MERCANTIL ARGENTINO",
            "matricula": "119",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380083000",
            "escuela": "702 - TALLERES LIBRES DE LA QUEBRADA - SEDE TILCARA",
            "matricula": "170",
            "consultor": "GARCIA DANIEL",
            "localidad": "TILCARA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos\r\nCursos y Talleres de Artística - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380083400",
            "escuela": "TALLER LIBRE DE ARTES Y ARTESANÍAS RAMAL JUJEÑO",
            "matricula": "130",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380084600",
            "escuela": "COLEGIO SECUNDARIO N 37",
            "matricula": "66",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "CHALICAN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380085400",
            "escuela": "ESCUELA N 465",
            "matricula": "432",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380085800",
            "escuela": "JARDIN INDEPENDIENTE N 2 - ESCUELA 147",
            "matricula": "207",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087800",
            "escuela": "J.I.N. 13",
            "matricula": "135",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "TILCARA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380088600",
            "escuela": "J.I.N. 21",
            "matricula": "91",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089800",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 1",
            "matricula": "255",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380091400",
            "escuela": "COLEGIO SECUNDARIO N 52",
            "matricula": "63",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380091800",
            "escuela": "ESCUELA HOSPITALARIA GUILLERMO PATERSON",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380093000",
            "escuela": "COLEGIO SECUNDARIO N 54",
            "matricula": "243",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380093400",
            "escuela": "ESCUELA N 468",
            "matricula": "429",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092508",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - HUANCAR",
            "matricula": "34",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "HUANCAR",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380093800",
            "escuela": "JARDÍN DE INFANTES CONEJIN",
            "matricula": "108",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380096200",
            "escuela": "JARDIN INDEPENDIENTE N 8",
            "matricula": "133",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380096600",
            "escuela": "JARDIN MATERNAL MUNDO MAGICO",
            "matricula": "111",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380102900",
            "escuela": "JARDÍN DE INFANTES NUCLEADOS N 42",
            "matricula": "183",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380076801",
            "escuela": "ESCUELA SUPERIOR DE DANZAS NORMA FONTENLA",
            "matricula": "1321",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común\r\nCiclos de Enseñanza Artística - Común\r\nCursos y Talleres de Artística - Común\r\nTrayecto Artístico Profesional - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380055801",
            "escuela": "JARDÍN MATERNAL FRUTILLITA",
            "matricula": "77",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380021600",
            "escuela": "ESCUELA N 414 ADOLFO KAPELUSZ",
            "matricula": "377",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380022000",
            "escuela": "ESCUELA TALLER ARENI PEPITA CARDOSO",
            "matricula": "0",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Cursos/Talleres de la Escuela Especial - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380022400",
            "escuela": "ESCUELA N 393 TTE GRAL JORGE E CACERES MONIE",
            "matricula": "351",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380095400",
            "escuela": "COLEGIO SECUNDARIO N 57",
            "matricula": "40",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CASPALA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380023300",
            "escuela": "ESCUELA N 426 MONS ENRIQUE ANGELELLI",
            "matricula": "39",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "BANANAL",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380023700",
            "escuela": "COOPERATIVA ESCUELA LIBERTADOR GRAL SAN MARTÍN",
            "matricula": "436",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380025100",
            "escuela": "CENTRO EDUCATIVO DE NIVEL SECUNDARIO N 203",
            "matricula": "286",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380025500",
            "escuela": "ESCUELA N 373",
            "matricula": "26",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PUERTA DE LIPAN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380025900",
            "escuela": "ESCUELA N 278",
            "matricula": "3",
            "consultor": "CRUZ MIRTA",
            "localidad": "AGUA CHICA",
            "ofertaLocal": "Jardin de infantes - Común\r\nEGB 1 y 2 - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380026700",
            "escuela": "ESCUELA N 211 GENDARMERIA NACIONAL",
            "matricula": "65",
            "consultor": "CRUZ MIRTA",
            "localidad": "OLAROZ CHICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380027100",
            "escuela": "ESCUELA N 107 SAN MARTÍN DE PORRES",
            "matricula": "446",
            "consultor": "TORRICO CARINA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380027500",
            "escuela": "ESCUELA NOCTURNA N 155",
            "matricula": "19",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380027900",
            "escuela": "ESCUELA N 208 2 DE JUNIO",
            "matricula": "18",
            "consultor": "FLORES JORGE",
            "localidad": "QUIRQUINCHOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380028300",
            "escuela": "ESCUELA FRONTERA N 2 ROSARIO WAYAR",
            "matricula": "17",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "YAVI CHICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029100",
            "escuela": "ESCUELA N 369 LUCIA RUEDA",
            "matricula": "19",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "EL ANGOSTO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029500",
            "escuela": "ESCUELA N 347 CORRAL BLANCO",
            "matricula": "14",
            "consultor": "CABELLO DELFOR",
            "localidad": "CORRAL BLANCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380029900",
            "escuela": "ESCUELA N 291 WOLF SCHCOLNIK",
            "matricula": "33",
            "consultor": "FLORES JORGE",
            "localidad": "CONDOR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030300",
            "escuela": "ESCUELA N 61 HNA STELLA MARYS ILMING",
            "matricula": "52",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PUMAHUASI",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380030700",
            "escuela": "ESCUELA N 416 SOBERANÍA NACIONAL",
            "matricula": "19",
            "consultor": "GARCIA DANIEL",
            "localidad": "CERRO COLORADO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031900",
            "escuela": "ESCUELA NOCTURNA N 424",
            "matricula": "13",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380032300",
            "escuela": "ESCUELA N 374 9 DE JULIO",
            "matricula": "30",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SANTO DOMINGO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380038700",
            "escuela": "ESCUELA N 232 MARIANO MORENO",
            "matricula": "8",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "NORMENTA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380092600",
            "escuela": "ESCUELA DE EDUCACIÓN ESPECIAL N 14",
            "matricula": "19",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin maternal - Especial\r\nJardin de infantes - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380088200",
            "escuela": "J.I.N. 17",
            "matricula": "157",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380022900",
            "escuela": "ESCUELA PROVINCIAL DE COMERCIO N 7 SAN MARCELINO CHAMPAGÑAT",
            "matricula": "472",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380037900",
            "escuela": "BACHILLERATO PROVINCIAL N 25 GERMAN WALTER CHURQUI CHOQUEVILCA",
            "matricula": "135",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "TILCARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380065700",
            "escuela": "COLEGIO POLIMODAL N 5",
            "matricula": "53",
            "consultor": "CRUZ MIRTA",
            "localidad": "HIPOLITO YRIGOYEN",
            "ofertaLocal": "Polimodal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380084200",
            "escuela": "COLEGIO SECUNDARIO N 33",
            "matricula": "141",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "REYES",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380082600",
            "escuela": "COLEGIO SECUNDARIO DE ARTES N 30",
            "matricula": "36",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CASIRA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380083800",
            "escuela": "COLEGIO SECUNDARIO N 31",
            "matricula": "63",
            "consultor": "FLORES JORGE",
            "localidad": "SANTA ANA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380085000",
            "escuela": "COLEGIO SECUNDARIO N 41",
            "matricula": "137",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SANTO DOMINGO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380086200",
            "escuela": "COLEGIO SECUNDARIO N 45",
            "matricula": "83",
            "consultor": "TORRICO CARINA",
            "localidad": "LOS LAPACHOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380024200",
            "escuela": "ESCUELA N 2 DOMINGO TEOFILO PEREZ",
            "matricula": "741",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380049200",
            "escuela": "ESCUELA N 87 GABRIELA MISTRAL",
            "matricula": "303",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LA MENDIETA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380024600",
            "escuela": "ESCUELA N 276 PROVINCIA DE RIO NEGRO",
            "matricula": "45",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "BARCENA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380073501",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR DE CAPACITACIÓN Y DESARROLLO EMPRESARIAL",
            "matricula": "140",
            "consultor": "MARTIN RUARTE",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 5"
        },
        {
            "cue": "380073900",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 2",
            "matricula": "475",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "TILCARA",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380074600",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR DE SEGURIDAD PÚBLICA",
            "matricula": "156",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380074700",
            "escuela": "CENTRO DE EDUCACION INTEGRADA DE JOVENES Y ADULTOS RENE SALAMANCA",
            "matricula": "375",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380078300",
            "escuela": "ESCUELA NORMAL SUPERIOR GENDARMERIA NACIONAL",
            "matricula": "736",
            "consultor": "TORRICO CARINA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380077500",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 14",
            "matricula": "75",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "CIENEGUILLAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380077900",
            "escuela": "COLEGIO SECUNDARIO N 17",
            "matricula": "46",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "PUMAHUASI",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380077100",
            "escuela": "COLEGIO DE NIVEL SECUNDARIO N 11",
            "matricula": "68",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "TUSAQUILLA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380082400",
            "escuela": "COLEGIO SECUNDARIO N 26",
            "matricula": "73",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "UQUIA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380082200",
            "escuela": "COLEGIO SECUNDARIO N 28",
            "matricula": "29",
            "consultor": "CRUZ MIRTA",
            "localidad": "TRES CRUCES",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380090901",
            "escuela": "J.I.N. 32",
            "matricula": "154",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380092501",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - LOBATON",
            "matricula": "36",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "EL ACHERAL",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380073901",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 2",
            "matricula": "646",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380079100",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR DR GUILLERMO EUGENIO SNOPEK",
            "matricula": "26",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380082000",
            "escuela": "ESCUELA N 461 BICENTENARIO DE LA PATRIA",
            "matricula": "679",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380082800",
            "escuela": "COLEGIO NUEVO HORIZONTE N 1",
            "matricula": "1036",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380061900",
            "escuela": "CENTRO EDUCATIVO FUNDACION SAN PATRICIO",
            "matricula": "319",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380078700",
            "escuela": "ESCUELA NORMAL SUPERIOR GRAL JOSE DE SAN MARTÍN",
            "matricula": "395",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380083500",
            "escuela": "TALLER LIBRE DE ARTES Y ARTESANÍAS RAMAL JUJEÑO",
            "matricula": "192",
            "consultor": "CABELLO DELFOR",
            "localidad": "YUTO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380085700",
            "escuela": "JARDÍN INDEPENDIENTE N 1 - ESCUELA 261",
            "matricula": "234",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380086100",
            "escuela": "JARDIN INDEPENDIENTE N 5",
            "matricula": "131",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380084900",
            "escuela": "COLEGIO SECUNDARIO N 40",
            "matricula": "50",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN JUAN DE DIOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380084800",
            "escuela": "COLEGIO SECUNDARIO N 39",
            "matricula": "59",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LOS BLANCOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380084500",
            "escuela": "COLEGIO SECUNDARIO N 36",
            "matricula": "394",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380085300",
            "escuela": "COLEGIO SECUNDARIO N 43",
            "matricula": "499",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380084100",
            "escuela": "COLEGIO SECUNDARIO N 32 RENE GERONIMO FAVALORO",
            "matricula": "303",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380079500",
            "escuela": "ESCUELA N 457",
            "matricula": "571",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380079900",
            "escuela": "ESCUELA BLAISE PASCAL",
            "matricula": "469",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380086801",
            "escuela": "J.I.N. 2",
            "matricula": "148",
            "consultor": "TORRICO CARINA",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380081000",
            "escuela": "COLEGIO SECUNDARIO N 23 DR RENE GERONIMO FAVALORO",
            "matricula": "63",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "OCLOYAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380081400",
            "escuela": "INSTITUTO SUPERIOR DE FORMACIÓN CAPACITACIÓN Y SUPERACIÓN LIC MYRIAM BEATRIZ GLOSS",
            "matricula": "339",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380087201",
            "escuela": "J.I.N. 6",
            "matricula": "194",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380087601",
            "escuela": "JARDÍN INDEPENDIENTE NUCLEADO N 11",
            "matricula": "134",
            "consultor": "CRUZ MIRTA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380080600",
            "escuela": "COLEGIO SECUNDARIO N 19",
            "matricula": "23",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "CASABINDO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380079901",
            "escuela": "ESCUELA BLAISE PASCAL",
            "matricula": "121",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380088701",
            "escuela": "J.I.N. 22",
            "matricula": "94",
            "consultor": "CRUZ MIRTA",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089101",
            "escuela": "J.I.N. 26",
            "matricula": "91",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380090601",
            "escuela": "J.I.N. 29",
            "matricula": "90",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380079600",
            "escuela": "BACHILLERATO DE NIVELACION PARA EL PERSONAL PENITENCIARIO",
            "matricula": "380",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380080300",
            "escuela": "COLEGIO SECUNDARIO N 21",
            "matricula": "59",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN FRANCISCO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380081100",
            "escuela": "COLEGIO SECUNDARIO N 20",
            "matricula": "70",
            "consultor": "GARCIA DANIEL",
            "localidad": "CATUA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380084700",
            "escuela": "COLEGIO SECUNDARIO N 38",
            "matricula": "162",
            "consultor": "MARTIN RUARTE",
            "localidad": "LAS PAMPITAS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091001",
            "escuela": "J.I.N. 33",
            "matricula": "102",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380082500",
            "escuela": "COLEGIO SECUNDARIO N 27",
            "matricula": "152",
            "consultor": "GARCIA DANIEL",
            "localidad": "BANANAL",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380084300",
            "escuela": "COLEGIO SECUNDARIO N 34",
            "matricula": "240",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092400",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1",
            "matricula": "284",
            "consultor": "TORRICO CARINA",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380081500",
            "escuela": "ESCUELA COOPERATIVA TIERRA DEL SOL",
            "matricula": "72",
            "consultor": "FLORES JORGE",
            "localidad": "TILCARA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 2"
        },
        {
            "cue": "380083100",
            "escuela": "TALLERES LIBRES DE ARTES Y ARTESANIAS DE LA QUEBRADA DE HUMAHUACA - SEDE PURMAMARCA",
            "matricula": "308",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PURMAMARCA",
            "ofertaLocal": "Cursos y Talleres de Artística - Común\r\nFormación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380080700",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 15",
            "matricula": "65",
            "consultor": "CABELLO DELFOR",
            "localidad": "YAVI",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380083900",
            "escuela": "ESCUELA N 463 PADRE JESUS OLMEDO",
            "matricula": "247",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380083200",
            "escuela": "TALLER LIBRE DE ARTES Y ARTESANIAS QUEBRADA",
            "matricula": "179",
            "consultor": "CABELLO DELFOR",
            "localidad": "UQUIA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380085100",
            "escuela": "ESCUELA N 464 PROVINCIA DE JUJUY",
            "matricula": "278",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380085500",
            "escuela": "JARDIN INDEPENDIENTE LOS PATITOS",
            "matricula": "79",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 1"
        },
        {
            "cue": "380085900",
            "escuela": "JARDIN INDEPENDIENTE N 3",
            "matricula": "215",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380086300",
            "escuela": "COLEGIO SECUNDARIO N 46 ROSARIO DEL RÍO GRANDE",
            "matricula": "66",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "BARRO NEGRO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380086700",
            "escuela": "J.I.N. 8",
            "matricula": "129",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380087100",
            "escuela": "J.I.N. 5",
            "matricula": "301",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088000",
            "escuela": "J.I.N. 15",
            "matricula": "98",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088400",
            "escuela": "J.I.N. 19",
            "matricula": "122",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090400",
            "escuela": "J.I.N. 28",
            "matricula": "111",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091600",
            "escuela": "ESCUELA HOSPITALARIA Y DOMICILIARIA SEÑORA DEL CARMEN",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Primario/EGB domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común\r\nInicial domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092000",
            "escuela": "ESCUELA HOSPITALARIA Y DOMICILIARIA PLINIO ZABALA",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "PERICO",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092800",
            "escuela": "INSTITUTO SUPERIOR EN CIENCIAS DE LA SALUD",
            "matricula": "223",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380088800",
            "escuela": "J.I.N. 23 ESC. SEDE N 207 GRAL DE BGD. ARMANDO P.P. MARTIJENA",
            "matricula": "137",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380104600",
            "escuela": "ESCUELA DE EDUCACIÓN ESPECIAL MUNICIPAL SARADO CORAZON DE JESÚS",
            "matricula": "24",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Primaria de 7 años - Especial\r\nEducacion integral para adolescentes y jovenes - Especial\r\nIntegración - Especial\r\nJardin de infantes - Especial",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380088201",
            "escuela": "J.I.N. 17",
            "matricula": "133",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009501",
            "escuela": "COLEGIO SANTA TERESITA ANEXO ALTO COMEDERO",
            "matricula": "85",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380086901",
            "escuela": "J.I.N. 3",
            "matricula": "117",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087301",
            "escuela": "J.I.N. 9",
            "matricula": "109",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380087801",
            "escuela": "J.I.N. 13",
            "matricula": "90",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "TILCARA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380088601",
            "escuela": "J.I.N. 21",
            "matricula": "70",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380086701",
            "escuela": "J.I.N. 8",
            "matricula": "129",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380087101",
            "escuela": "J.I.N. 5",
            "matricula": "162",
            "consultor": "MARTIN RUARTE",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088001",
            "escuela": "J.I.N. 15",
            "matricula": "109",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088401",
            "escuela": "J.I.N. 19",
            "matricula": "145",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087402",
            "escuela": "J.I.N. 7",
            "matricula": "65",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380087602",
            "escuela": "JARDÍN INDEPENDIENTE NUCLEADO N 11",
            "matricula": "128",
            "consultor": "CRUZ MIRTA",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380089500",
            "escuela": "ESCUELA DE EDUCACIÓN ESPECIAL N 13",
            "matricula": "18",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "Jardin maternal - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nEducacion integral para adolescentes y jovenes - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380087500",
            "escuela": "J.I.N. 10",
            "matricula": "164",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380089001",
            "escuela": "J.I.N. 25",
            "matricula": "112",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380088801",
            "escuela": "J.I.N. 23",
            "matricula": "75",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090701",
            "escuela": "J.I.N. 30",
            "matricula": "81",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089900",
            "escuela": "COLEGIO SECUNDARIO DE ARTES N 49",
            "matricula": "271",
            "consultor": "TORRICO CARINA",
            "localidad": "TILCARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nCursos y Talleres de Artística - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380087302",
            "escuela": "J.I.N. 9",
            "matricula": "40",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380088602",
            "escuela": "J.I.N. 21",
            "matricula": "70",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087900",
            "escuela": "J.I.N. 14",
            "matricula": "94",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088300",
            "escuela": "J.I.N. 18",
            "matricula": "144",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380086800",
            "escuela": "J.I.N. 2",
            "matricula": "106",
            "consultor": "TORRICO CARINA",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380087200",
            "escuela": "J.I.N. 6",
            "matricula": "269",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380087700",
            "escuela": "J.I.N. 12",
            "matricula": "77",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380088100",
            "escuela": "J.I.N. 16",
            "matricula": "96",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087000",
            "escuela": "J.I.N. 4",
            "matricula": "269",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089200",
            "escuela": "J.I.N. 27",
            "matricula": "152",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "YUTO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380090500",
            "escuela": "ESCUELA DE ENSEÑANZA PRIVADA LUZ",
            "matricula": "252",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común\r\nPrimaria de 7 años - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380086500",
            "escuela": "JARDIN MATERNAL PATITO COLETON",
            "matricula": "285",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380088500",
            "escuela": "J.I.N. 20",
            "matricula": "205",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090300",
            "escuela": "COLEGIO SECUNDARIO N 51",
            "matricula": "26",
            "consultor": "LEONAR DIEGO",
            "localidad": "COLORADOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090700",
            "escuela": "J.I.N. 30",
            "matricula": "134",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091100",
            "escuela": "J.I.N. 34",
            "matricula": "101",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380081401",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR EN FORMACIÓN CAPACITACIÓN Y SUPERACIÓN LIC MYRIAM B. GLOSS",
            "matricula": "34",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380089000",
            "escuela": "J.I.N. 25",
            "matricula": "83",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380092503",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - SANTO DOMINGO",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SANTO DOMINGO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380098101",
            "escuela": "J.I.N. 36",
            "matricula": "91",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380093100",
            "escuela": "CENTRO EDUCATIVO PROVINCIAL HOSPITALARIO Y DOMICILIARIA DR CARLOS ALBERTO ALVARADO",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Inicial domiciliaria-hospitalaria - Común\r\nPrimario/EGB domiciliaria-hospitalaria - Común\r\nSecundario/Polimodal domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099001",
            "escuela": "J.I.N. 38",
            "matricula": "55",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LA ESPERANZA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380089102",
            "escuela": "J.I.N. 26",
            "matricula": "102",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380093200",
            "escuela": "ESCUELA HOSPITALARIA Y DOMICILIARIA SNOPEK",
            "matricula": "0",
            "consultor": "MARIANA SUDAÑES",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundario/Polimodal domiciliaria-hospitalaria - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101502",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.P. N 3",
            "matricula": "20",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380093900",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL NUEVO HORIZONTE",
            "matricula": "755",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380091101",
            "escuela": "J.I.N. 34",
            "matricula": "160",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "CAIMANCITO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380094000",
            "escuela": "JARDIN INDEPENDIENTE N 7",
            "matricula": "104",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380095200",
            "escuela": "JARDÍN DE INFANTES SANTA TERESITA",
            "matricula": "39",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380092507",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - QUEBRALEÑA",
            "matricula": "11",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "QUEBRALEÑA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380095600",
            "escuela": "ESCUELA N 469",
            "matricula": "337",
            "consultor": "CRUZ MIRTA",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380097401",
            "escuela": "J.I.N. 1",
            "matricula": "14",
            "consultor": "GARCIA DANIEL",
            "localidad": "LA QUIACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380092504",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - LOMA BLANCA",
            "matricula": "18",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LOMA BLANCA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380096000",
            "escuela": "CENTRO DE DESARROLLO INFANTIL 7 COLORES",
            "matricula": "65",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380097100",
            "escuela": "CENTRO EDUCATIVO INFANTIL JARDIN MATERNAL ARCA DE NOE",
            "matricula": "0",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380097600",
            "escuela": "ESCUELA N 470 BARTOLINA SISA",
            "matricula": "645",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380098300",
            "escuela": "INFINITO POR DESCUBRIR",
            "matricula": "962",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099900",
            "escuela": "COLEGIO SECUNDARIO N 63",
            "matricula": "26",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA CIENAGA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380098000",
            "escuela": "JARDÍN INDEPENDIENTE N 10",
            "matricula": "146",
            "consultor": "GARCIA DANIEL",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380099100",
            "escuela": "ESCUELA PROFESIONAL OFICIOS SAN PEDRO",
            "matricula": "14",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380099401",
            "escuela": "J.I.N. 37",
            "matricula": "145",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099600",
            "escuela": "JARDIN INDEPENDIENTE N 15",
            "matricula": "161",
            "consultor": "LEONAR DIEGO",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380100300",
            "escuela": "JARDIN MATERNAL \"RAYITO DE SOL\"",
            "matricula": "58",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380100700",
            "escuela": "JARDÍN DE INFANTES BURBUJITA",
            "matricula": "0",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380101100",
            "escuela": "JARDIN MATERNAL SAN PIO X",
            "matricula": "98",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380101500",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2",
            "matricula": "173",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101600",
            "escuela": "CENTRO DE FORMACIÓN Y CAPACITACIÓN PROFESIONAL N 1 LA SALLE",
            "matricula": "25",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380102000",
            "escuela": "JARDÍN INDEPENDIENTE N 25",
            "matricula": "250",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045802",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 9",
            "matricula": "35",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PALMA SOLA",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380101504",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.P. N 6",
            "matricula": "10",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "EL REMATE",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101506",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.F. N 8",
            "matricula": "99",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380100003",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 SAN JUAN DE MISA RUMI",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "MISA RUMI",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380100000",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3",
            "matricula": "0",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101508",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 RÉGIMEN CERRADO PARA ADOLESCENTES EN CONFLICTO CON LA LEY PENAL SAN SALVADOR DE JUJUY",
            "matricula": "8",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380092511",
            "escuela": "ESCUELA SECUNDARIA RURAL N 1 - LIVIARA",
            "matricula": "7",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LIVIARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380094202",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - APARZO",
            "matricula": "7",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "APARZO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380100006",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 RODERO",
            "matricula": "9",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "RODERO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380100008",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 PASTOS CHICOS",
            "matricula": "7",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PASTOS CHICOS",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380094204",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - VALIAZO",
            "matricula": "23",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "VALIAZO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380100010",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 RACHAITE",
            "matricula": "14",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "RACHAITE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380094207",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - VIZCARRA",
            "matricula": "11",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "VIZCARRA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380094214",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - LA ALMONA",
            "matricula": "11",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LA ALMONA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380094211",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - LAGUNA DE TESORERO",
            "matricula": "28",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "TESORERO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380095100",
            "escuela": "COLEGIO NUEVO HORIZONTE N 2 - SAN VICENTE DE MONTERRICO",
            "matricula": "339",
            "consultor": "LEONAR DIEGO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380094505",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 1 AULAS TALLERES MÓVILES",
            "matricula": "23",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380096501",
            "escuela": "COLEGIO PRIVADO LOS OLIVOS",
            "matricula": "134",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380102400",
            "escuela": "JARDÍN INDEPENDIENTE N 20",
            "matricula": "233",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380103200",
            "escuela": "ESCUELA N 471",
            "matricula": "0",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380103100",
            "escuela": "JARDIN INDEPENDIENTE N 22",
            "matricula": "155",
            "consultor": "MARTIN RUARTE",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380103500",
            "escuela": "JARDIN INDEPENDIENTE N 29",
            "matricula": "171",
            "consultor": "TORRICO CARINA",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101200",
            "escuela": "JARDIN DE INFANTES NUCLEADOS N 40",
            "matricula": "278",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101900",
            "escuela": "JARDÍN DE INFANTES NUCLEADOS N 41",
            "matricula": "293",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380102300",
            "escuela": "JARDÍN INDEPENDIENTE N 19",
            "matricula": "151",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380103900",
            "escuela": "JARDIN INDEPENDIENTE N 28",
            "matricula": "145",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "REYES",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002300",
            "escuela": "ESCUELA ESPECIAL N 1 DR. OSCAR ORIAS",
            "matricula": "28",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Taller de nivel Primario/EGB1 y 2 - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial\r\nTaller de Secundaria/Polimodal - Especial\r\nCursos/Talleres de la Escuela Especial - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380104700",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 NUEVO HORIZONTE",
            "matricula": "67",
            "consultor": "LEONAR DIEGO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380103000",
            "escuela": "JARDÍN INDEPENDIENTE N 26",
            "matricula": "209",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380101505",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 U.P.P. N 7",
            "matricula": "212",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380101509",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 2 RÉGIMEN CERRADO PARA ADOLESCENTES EN CONFLICTO CON LA LEY PENAL SAN PEDRO",
            "matricula": "10",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380007400",
            "escuela": "ESCUELA DE COMERCIO N 1 SDOR DOMINGO TEOFILO PEREZ",
            "matricula": "514",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PAMPA BLANCA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007800",
            "escuela": "ESCUELA TÉCNICA N 1 MAESTRO HUMBERTO SAMUEL LUNA",
            "matricula": "677",
            "consultor": "LEONAR DIEGO",
            "localidad": "PERICO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008200",
            "escuela": "BACHILLERATO PROVINCIAL ACELERADO N 1 - UNIDAD 1 - MASCULINO",
            "matricula": "138",
            "consultor": "FLORES JORGE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380006700",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR JUJUY",
            "matricula": "80",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380102700",
            "escuela": "COLEGIO SECUNDARIO N 65",
            "matricula": "329",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380100011",
            "escuela": "ESCUELA SECUNDARIA RURAL N 3 PUEBLO VIEJO",
            "matricula": "6",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "PUEBLO VIEJO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380003300",
            "escuela": "ESCUELA N 12 BERNARDO MONTEAGUDO",
            "matricula": "979",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380000100",
            "escuela": "ESCUELA N 157 DR RICARDO ROJAS",
            "matricula": "31",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380000400",
            "escuela": "ESCUELA N 85 23 DE AGOSTO",
            "matricula": "306",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002000",
            "escuela": "ESCUELA N 323 MAESTROS ARGENTINOS",
            "matricula": "217",
            "consultor": "LEONAR DIEGO",
            "localidad": "PUESTO VIEJO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380002900",
            "escuela": "ESCUELA N 8 ESCOLÁSTICO ZEGADA",
            "matricula": "516",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380004400",
            "escuela": "ESCUELA N 10 GRAL JOSE DE SAN MARTÍN",
            "matricula": "340",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005800",
            "escuela": "ESCUELA N 205 BERNARDINA MEJIA MIRAVAL DE ARGAÑARAS",
            "matricula": "132",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380006200",
            "escuela": "ESCUELA N 79 DR ESTANISLAO SEVERO ZEBALLOS",
            "matricula": "698",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380004600",
            "escuela": "ESCUELA N 327 ISLAS MALVINAS",
            "matricula": "21",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN JUANCITO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005000",
            "escuela": "ESCUELA N 34 PEDRO JOSE PORTAL",
            "matricula": "470",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA OVEJERIA/OVEJERIA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380005400",
            "escuela": "CENTRO EDUCATIVO DE TERMINALIDAD PRIMARIA N 195 - CURSO PARA ADULTOS - U.1",
            "matricula": "33",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380007100",
            "escuela": "ESCUELA N 388 JUAN GALO LAVALLE",
            "matricula": "693",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009100",
            "escuela": "ESCUELA DE EDUCACIÓN TÉCNICA N 1 ESCOLÁSTICO ZEGADA",
            "matricula": "1027",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008400",
            "escuela": "ESCUELA N 14 JUAN BAUTISTA ALBERDI",
            "matricula": "596",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380008800",
            "escuela": "ESCUELA N 28 PRESIDENTE AVELLANEDA",
            "matricula": "303",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009200",
            "escuela": "BACHILLERATO PROVINCIAL N 2 GOB BENJAMIN VILLAFAÑE",
            "matricula": "627",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010000",
            "escuela": "ESCUELA N 285 PROVINCIA DE SAN LUIS",
            "matricula": "79",
            "consultor": "LEONAR DIEGO",
            "localidad": "LA TOMA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011300",
            "escuela": "ESCUELA N 326 ROTARY CLUB JUJUY",
            "matricula": "26",
            "consultor": "MARTIN RUARTE",
            "localidad": "OCLOYAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380010400",
            "escuela": "ESCUELA N 108 REPUBLICA DE CHILE",
            "matricula": "40",
            "consultor": "GARCIA DANIEL",
            "localidad": "PAÑO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011200",
            "escuela": "ESCUELA N 65 JOSE QUINTANA",
            "matricula": "245",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "LOZANO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380011600",
            "escuela": "ESCUELA N 329 FUNDACIÓN SCHOLNIK",
            "matricula": "2",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "TILQUIZA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380012900",
            "escuela": "ESCUELA N 260 FUERZA AÉREA ARGENTINA",
            "matricula": "46",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "EL REMATE",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380013300",
            "escuela": "ESCUELA N 432 REPÚBLICA FRANCESA",
            "matricula": "821",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380055000",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 2 ING CARLOS SNOPEK",
            "matricula": "297",
            "consultor": "FLORES JORGE",
            "localidad": "EL PIQUETE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380058600",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 12",
            "matricula": "68",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "EL FUERTE",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380063800",
            "escuela": "COLEGIO SECUNDARIO N 4",
            "matricula": "230",
            "consultor": "CRUZ MIRTA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Secundaria Completa - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380059000",
            "escuela": "ESCUELA N 284 ARMADA NACIONAL",
            "matricula": "17",
            "consultor": "FLORES JORGE",
            "localidad": "RIO BLANCO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380060400",
            "escuela": "CENTRO DE FORMACIÓN PROFESIONAL N 1",
            "matricula": "397",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380059800",
            "escuela": "ESCUELA PROFESIONAL N 10 PATRICIAS ARGENTINAS",
            "matricula": "321",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380062000",
            "escuela": "ESCUELA N 442 MAESTRO RICARDO VILCA",
            "matricula": "521",
            "consultor": "GARCIA DANIEL",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380062400",
            "escuela": "CENTRO DE DESARROLLO INFANTIL HERMINIO ARRIETA",
            "matricula": "117",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099300",
            "escuela": "JARDIN INDEPENDIENTE N 14",
            "matricula": "78",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380099700",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR NUEVO HORIZONTE",
            "matricula": "36",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "MONTERRICO",
            "ofertaLocal": "SNU - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380057900",
            "escuela": "COMPLEJO EDUCATIVO JOSE HERNANDEZ",
            "matricula": "762",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380100100",
            "escuela": "JARDÍN INDEPENDIENTE PBRO FRANCISCO CONTAMIN",
            "matricula": "99",
            "consultor": "CRUZ MIRTA",
            "localidad": "SANTA CLARA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380082100",
            "escuela": "CENTRO EDUCATIVO TERAPEUTICO IDEA",
            "matricula": "90",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380060500",
            "escuela": "CENTRO DE EDUCACIÓN AGRICOLA N 3",
            "matricula": "243",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA BAJADA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380060900",
            "escuela": "CENTRO DE EDUCACIÓN AGRÍCOLA",
            "matricula": "185",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "TUNALITO",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380064000",
            "escuela": "ESCUELA PROVINCIAL AGROTÉCNICA N 9",
            "matricula": "56",
            "consultor": "GARCIA DANIEL",
            "localidad": "EL MORENO",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380061300",
            "escuela": "ESCUELA N 74 MISIÓN MONOTÉCNICA Y DE EXTENSIÓN CULTURAL",
            "matricula": "71",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380065600",
            "escuela": "COLEGIO POLIMODAL N 4",
            "matricula": "50",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "RINCONADA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380079700",
            "escuela": "COLEGIO SECUNDARIO CANÓNIGO GORRITI",
            "matricula": "60",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380101700",
            "escuela": "JARDÍN MATERNAL \" EL REINO DEL REVÉS\"",
            "matricula": "144",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380102100",
            "escuela": "JARDÍN INDEPENDIENTE N 17",
            "matricula": "162",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380102500",
            "escuela": "JARDÍN INDEPENDIENTE N 21",
            "matricula": "134",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "LA OVEJERIA/OVEJERIA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380064400",
            "escuela": "ESCUELA N 444 ABANDERADA DE LA PATRIA",
            "matricula": "624",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380103700",
            "escuela": "JARDIN INDEPENDIENTE N 33",
            "matricula": "127",
            "consultor": "GARCIA DANIEL",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380064800",
            "escuela": "ESCUELA N 447 REPÚBLICA DE ITALIA",
            "matricula": "443",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380054700",
            "escuela": "ESCUELA N 188 RICARDO GUIRALDES",
            "matricula": "122",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "REAL DE LOS TOROS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380055500",
            "escuela": "ESCUELA N 21 PEDRO GOYENA",
            "matricula": "196",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "PURMAMARCA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nIntegración - Especial",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380043101",
            "escuela": "ESCUELA N 77 BERNARDINO RIVADAVIA ANEXO EDUC.ESPECIAL",
            "matricula": "6",
            "consultor": "CABELLO DELFOR",
            "localidad": "HUMAHUACA",
            "ofertaLocal": "Integración - Especial\r\nJardin maternal - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nPrimaria de 7 años - Especial",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380104500",
            "escuela": "COLEGIO PABLO PIZZURNO -NIVEL INICIAL Y PRIMARIO-",
            "matricula": "286",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380098701",
            "escuela": "INSTITUTO DE EDUCACION PRIMARIA 17 DE SETIEMBRE - CEDEMS",
            "matricula": "85",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380083300",
            "escuela": "TALLER LIBRE DE ARTES Y ARTESANIAS DE LA PUNA",
            "matricula": "39",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "RINCONADA",
            "ofertaLocal": "Cursos y Talleres de Artística - Común\r\nFormación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380073700",
            "escuela": "INSTITUTO SUPERIOR BALCER",
            "matricula": "18",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380079300",
            "escuela": "ESCUELA DE CAPACITACIÓN LABORAL DE VOLCAN",
            "matricula": "53",
            "consultor": "GARCIA DANIEL",
            "localidad": "VOLCAN",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380087300",
            "escuela": "J.I.N. 9",
            "matricula": "125",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380104900",
            "escuela": "JARDIN MATERNAL Y DE INFANTES KRECER",
            "matricula": "35",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380088900",
            "escuela": "J.I.N. 24",
            "matricula": "107",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380089700",
            "escuela": "ESCUELA N 466 NUESTRA SEÑORA DE FATIMA",
            "matricula": "254",
            "consultor": "FLORES JORGE",
            "localidad": "FRAILE PINTADO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380090900",
            "escuela": "J.I.N. 32",
            "matricula": "110",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380093700",
            "escuela": "INSTITUTO DE EDUCACION SUPERIOR NUEVO HORIZONTE",
            "matricula": "693",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380094100",
            "escuela": "INSTITUTO SUPERIOR DE FORMACIÓN TÉCNICA MELBY CLAROS",
            "matricula": "150",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "SNU - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380096100",
            "escuela": "JARDIN SAN RAMON",
            "matricula": "43",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PERICO",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 3"
        },
        {
            "cue": "380096500",
            "escuela": "COLEGIO PRIVADO LOS OLIVOS",
            "matricula": "44",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380058201",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR GUILLERMO CLELAND PATERSON",
            "matricula": "66",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "SNU - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380087701",
            "escuela": "J.I.N. 12",
            "matricula": "135",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "ABRA PAMPA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380088101",
            "escuela": "J.I.N. 16",
            "matricula": "82",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380088501",
            "escuela": "J.I.N. 20",
            "matricula": "103",
            "consultor": "MARTIN RUARTE",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380090801",
            "escuela": "J.I.N. 31",
            "matricula": "108",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380081800",
            "escuela": "A.S.P.E.R. ASOCIACIÓN PARA LA ESTIMULACIÓN Y REHABILITACIÓN",
            "matricula": "16",
            "consultor": "GARCIA DANIEL",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin maternal - Especial\r\nPrimaria de 7 años - Especial\r\nIntegración - Especial\r\nTaller de nivel Primario/EGB1 y 2 - Especial\r\nCursos/Talleres de la Escuela Especial - Especial",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380059600",
            "escuela": "ESCUELA PROFESIONAL N 11",
            "matricula": "257",
            "consultor": "CRUZ MIRTA",
            "localidad": "CALILEGUA",
            "ofertaLocal": "Formación Profesional/Capacitación Laboral - Adultos",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380063100",
            "escuela": "ESCUELA N 197 PEDRO BONIFACIO PALACIOS",
            "matricula": "16",
            "consultor": "FLORES JORGE",
            "localidad": "LIVIARA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380099000",
            "escuela": "J.I.N. 38",
            "matricula": "111",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "LA ESPERANZA",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380074200",
            "escuela": "ESCUELA NORMAL SUPERIOR JUAN IGNACIO GORRITI",
            "matricula": "824",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380073000",
            "escuela": "COLEGIO POLIMODAL N 7",
            "matricula": "105",
            "consultor": "CABELLO DELFOR",
            "localidad": "SANTA CATALINA",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común\r\nSecundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380100600",
            "escuela": "JARDÍN INDEPENDIENTE NIÑO JESUS",
            "matricula": "37",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Privado",
            "region": "R 4"
        },
        {
            "cue": "380101400",
            "escuela": "JARDIN MATERNAL GENIOS EN ACCION",
            "matricula": "31",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380045101",
            "escuela": "INSTITUTO DE EDUCACIÓN SUPERIOR N 6",
            "matricula": "646",
            "consultor": "CABELLO DELFOR",
            "localidad": "EL CARMEN",
            "ofertaLocal": "SNU - Común\r\nCursos de Capacitación de SNU - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380103400",
            "escuela": "JARDIN INDEPENDIENTE N 31",
            "matricula": "132",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "EL TALAR",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380068200",
            "escuela": "JARDÍN MATERNAL BURBUJITAS",
            "matricula": "22",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380072600",
            "escuela": "HOGAR ESCUELA N 1 JOSE DE LA IGLESIA",
            "matricula": "60",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380078600",
            "escuela": "ESCUELA NORMAL SUPERIOR DR EDUARDO CASANOVA",
            "matricula": "461",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "TILCARA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380069800",
            "escuela": "CENTRO DE DESARROLLO INFANTIL DR GUILLERMO EUGENIO SNOPEK",
            "matricula": "205",
            "consultor": "TORRICO CARINA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380056901",
            "escuela": "SENTIR II CENTRO EDUCATIVO TERAPEUTICO",
            "matricula": "30",
            "consultor": "LEONAR DIEGO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Integración - Especial\r\nSecundaria Completa req. 7 años - Especial",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380070600",
            "escuela": "ASOCIACION CIVIL LA CASA DEL NIÑO RINCON DE VIDA",
            "matricula": "85",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380071000",
            "escuela": "HOGAR VIRGEN DE GUADALUPE",
            "matricula": "120",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Servicios Alternativos/Complementarios - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380073400",
            "escuela": "GUARDERIA ARCA DE NOE",
            "matricula": "63",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin maternal - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380087400",
            "escuela": "J.I.N. 7",
            "matricula": "179",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380090600",
            "escuela": "J.I.N. 29",
            "matricula": "129",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380091000",
            "escuela": "J.I.N. 33",
            "matricula": "198",
            "consultor": "MIULLER CRISTIAN",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380039800",
            "escuela": "ESCUELA NOCTURNA N 150 REPÚBLICA DEL PERU",
            "matricula": "90",
            "consultor": "LEONAR DIEGO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380043900",
            "escuela": "ESCUELA N 257 PROVINCIA DE CORDOBA",
            "matricula": "727",
            "consultor": "CRUZ MIRTA",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380045200",
            "escuela": "ESCUELA NOCTURNA N 94 JESUS OBRERO",
            "matricula": "55",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PERICO",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380098200",
            "escuela": "JARDÍN INDEPENDIENTE N 11",
            "matricula": "192",
            "consultor": "GARCIA DANIEL",
            "localidad": "LIBERTADOR GENERAL SAN MARTIN",
            "ofertaLocal": "Jardin de infantes - Común",
            "sector": "Estatal",
            "region": "R 5"
        },
        {
            "cue": "380046400",
            "escuela": "ESCUELA N 30 JUANA AZURDUY DE PADILLA",
            "matricula": "459",
            "consultor": "CABELLO DELFOR",
            "localidad": "MONTERRICO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380009500",
            "escuela": "COLEGIO SANTA TERESITA",
            "matricula": "1767",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común\r\nPrimaria de 7 años - Común\r\nJardin de infantes - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380044300",
            "escuela": "BACHILLERATO PROVINCIAL N 14 JUAN MINETTI",
            "matricula": "202",
            "consultor": "LEONAR DIEGO",
            "localidad": "PUESTO VIEJO",
            "ofertaLocal": "Trayecto técnico profesional - Común\r\nSecundaria Completa req. 7 años - Común\r\nPolimodal - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380046800",
            "escuela": "COLEGIO PARROQUIAL NUESTRA SEÑORA DEL CARMEN",
            "matricula": "391",
            "consultor": "MACHACA ALEJANDRO",
            "localidad": "EL CARMEN",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nJardin maternal - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380046300",
            "escuela": "ESCUELA N 312 AGUA Y ENERGÍA ELÉCTRICA",
            "matricula": "256",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "DIQUE LA CIENAGA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380047600",
            "escuela": "ESCUELA N 339 JUAN IGNACIO GORRITI",
            "matricula": "403",
            "consultor": "RODRIGUEZ SILVANA",
            "localidad": "EL AGUILAR",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380048000",
            "escuela": "ESCUELA N 328 GRAL ANTONIO GONZALEZ BALCARCE",
            "matricula": "86",
            "consultor": "CRUZ MIRTA",
            "localidad": "CHORRILLOS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380048400",
            "escuela": "ESCUELA N 115 AMERO LUIS RUSCONI",
            "matricula": "55",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "VARAS",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380070101",
            "escuela": "JARDÍN INFANTIL ADEP SUEÑITOS DEL MAÑANA",
            "matricula": "75",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Jardin de infantes - Común\r\nJardin maternal - Común",
            "sector": "Gestión social/cooperativa",
            "region": "R 4"
        },
        {
            "cue": "380094208",
            "escuela": "ESCUELA SECUNDARIA RURAL N 2 - AGUA DE CASTILLA",
            "matricula": "21",
            "consultor": "LOPEZ ALEJANDRO",
            "localidad": "AGUA DE CASTILLA",
            "ofertaLocal": "Secundaria Completa req. 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380017700",
            "escuela": "ESCUELA N 7 DOMINGO FAUSTINO SARMIENTO",
            "matricula": "559",
            "consultor": "TORRICO CARINA",
            "localidad": "TILCARA",
            "ofertaLocal": "Jardin maternal - Especial\r\nIntegración - Especial\r\nPrimaria de 7 años - Común\r\nPrimaria de 7 años - Especial\r\nCursos/Talleres de la Escuela Especial - Especial\r\nJardin de infantes - Especial",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380044800",
            "escuela": "ESCUELA N 221 UNIÓN LATINOAMERICANA",
            "matricula": "317",
            "consultor": "FLORES JORGE",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380046000",
            "escuela": "ESCUELA N 220 SAUCE GUACHO",
            "matricula": "49",
            "consultor": "CRUZ MIRTA",
            "localidad": "SAUCE GUACHO",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380032500",
            "escuela": "ESCUELA N 127",
            "matricula": "31",
            "consultor": "TORRICO CARINA",
            "localidad": "CUSI CUSI",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380004000",
            "escuela": "ESCUELA NOCTURNA N 164 PACHI GORRITI",
            "matricula": "26",
            "consultor": "CABELLO DELFOR",
            "localidad": "SAN SALVADOR DE JUJUY",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380013700",
            "escuela": "ESCUELA PRIMARIA NOCTURNA N 421",
            "matricula": "0",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "TILCARA",
            "ofertaLocal": "Primaria - Adultos",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380017200",
            "escuela": "COLEGIO PRIVADO SAGRADO CORAZÓN",
            "matricula": "887",
            "consultor": "GARCIA DANIEL",
            "localidad": "PALPALA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común\r\nSecundaria Completa req. 7 años - Común",
            "sector": "Privado",
            "region": "R 3"
        },
        {
            "cue": "380018600",
            "escuela": "ESCUELA N 41 SOLDADO ARGENTINO",
            "matricula": "30",
            "consultor": "CABELLO DELFOR",
            "localidad": "COCTACA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 2"
        },
        {
            "cue": "380017600",
            "escuela": "ESCUELA N 78 DR JOSÉ BENITO DE LA BARCENA",
            "matricula": "616",
            "consultor": "MAREÑO ARIEL EDUARDO",
            "localidad": "PALPALA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 3"
        },
        {
            "cue": "380021500",
            "escuela": "ESCUELA N 4 ESTEBAN H LEACH",
            "matricula": "325",
            "consultor": "TORRICO CARINA",
            "localidad": "LA ESPERANZA",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380022300",
            "escuela": "ESCUELA N 203 SU SANTIDAD JUAN XXIII",
            "matricula": "592",
            "consultor": "PARRAGA PATRICIA",
            "localidad": "SAN PEDRO",
            "ofertaLocal": "Primaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 4"
        },
        {
            "cue": "380028200",
            "escuela": "ESCUELA N 183 JOSE HERNANDEZ",
            "matricula": "32",
            "consultor": "TORRICO CARINA",
            "localidad": "SANSANA NORTE",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        },
        {
            "cue": "380031800",
            "escuela": "ESCUELA N 344 HERMOGENES CAYO",
            "matricula": "13",
            "consultor": "VILLAGRAN IVAN",
            "localidad": "MIRAFLORES DE LA CANDELARIA",
            "ofertaLocal": "Jardin de infantes - Común\r\nPrimaria de 7 años - Común",
            "sector": "Estatal",
            "region": "R 1"
        }
    ];


