import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConsultoresComponent } from './consultores/consultores.component';
import { TarjetaVideoComponent } from './tarjeta-video/tarjeta-video.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { DatespipeModule } from '../datespipe/datespipe.module';

@NgModule({
  declarations: [NotFoundComponent, ConsultoresComponent, TarjetaVideoComponent],
  imports: [
    CommonModule,
    DatespipeModule,
    ShareButtonsModule.withConfig({}),
  ],
  exports: [NotFoundComponent, ConsultoresComponent,TarjetaVideoComponent]
})
export class ComponentsModule { }
