import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from '../../services/data.service';
import { listaTodosVideos } from '../../data/data';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input() seccionInput: any;
  listaVideos:any[]= listaTodosVideos;
  videoElegido: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: true, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-md modal-lg'
  };
  
  constructor(private dataService: DataService, private modalService: BsModalService) { 
    
  }

  ngOnInit(): void {
    // console.log('llego a seccion:', this.seccionInput);
    this.seccionInput.tituloSeccion = this.dataService.getCategoriaElegida(this.seccionInput.tituloSeccion);
    // console.log(this.seccionInput.tituloSeccion);
  }
  elegir(video: any, template: TemplateRef<any> ): void {
    this.modalRef = this.modalService.show(template, this.config);
    this.videoElegido = this.listaVideos.find(e => e.titulo.toUpperCase().includes(video.toUpperCase()));
  }
  validarObjeto(): boolean {
    return Object.entries(this.videoElegido).length === 0;
  }

}
