import { Documento } from '../models/Documento';

export const formulariosRa: Documento[] = [
    {
        id: 1,
        titulo: 'ADULTOS-TP-2024',
        descripcion: 'ADULTOS-TP-2024',
        urlpdf: 'https://drive.google.com/file/d/1ZTxhHFf8b_fKLbnw_9vPQb_6GKu5Htr0/view?usp=sharing'
    },
    {
        id: 2,
        titulo: 'ARTISTICA-2024',
        descripcion: 'ARTISTICA-2024',
        urlpdf: 'https://drive.google.com/file/d/1YD7iiqEkqjq8fujgwHEHpL6I4wAkBjTj/view?usp=sharing'
    },
    {
        id: 3,
        titulo: 'COMUN-TP-2024 PRIMARIO',
        descripcion: 'COMUN-TP-2024 PRIMARIO',
        urlpdf: 'https://drive.google.com/file/d/1pQdXntVOhQ_7rbJ9_gugnxOuar7Y7jfj/view?usp=sharing'
    },
    {
        id: 4,
        titulo: 'COMUN-TP-2024-INICIAL',
        descripcion: 'COMUN-TP-2024-INICIAL',
        urlpdf: 'https://drive.google.com/file/d/186uVlwyjE8SDZbCFSMCkeW6vpuTlmr7z/view?usp=sharing'
    },
    {
        id: 5,
        titulo: 'COMUN-TP-2024-SECUNDARIO',
        descripcion: 'COMUN-TP-2024-SECUNDARIO',
        urlpdf: 'https://drive.google.com/file/d/1z34aY5UFkBWxFxo3mzun4_qESYMdIyv6/view?usp=sharing'
    },
    {
        id: 6,
        titulo: 'COMUN-TP-2024INICIAL Y PRIMARIO',
        descripcion: 'COMUN-TP-2024INICIAL Y PRIMARIO',
        urlpdf: 'https://drive.google.com/file/d/1ck2YuUrvNDwAVF-odBog62VTWhlUxf7a/view?usp=sharing'
    },
    {
        id: 7,
        titulo: 'DOMICILIARIA-2024',
        descripcion: 'DOMICILIARIA-2024',
        urlpdf: 'https://drive.google.com/file/d/12HdGaFxhv8FRmKCMLu7d7s6uBcwX5Byu/view?usp=sharing'
    },
    {
        id: 8,
        titulo: 'ESPECIAL-2024',
        descripcion: 'ESPECIAL-2024',
        urlpdf: 'https://drive.google.com/file/d/1kDyQ3nYA8Ei-6Jf-kRc-CQuWrWqavWBs/view?usp=sharing'
    },
    {
        id: 9,
        titulo: 'FORM-PROF-CAP-LABORAL-2024',
        descripcion: 'FORM-PROF-CAP-LABORAL-2024',
        urlpdf: 'https://drive.google.com/file/d/1ooktY8JftnnVLc0N9zpnHjcOL7YUNT-3/view?usp=sharing'
    },
    {
        id: 10,
        titulo: 'SUPERIOR-TP-2024',
        descripcion: 'SUPERIOR-TP-2024',
        urlpdf: 'https://drive.google.com/file/d/14q6LH7juB1tEyMCbxfAla-hvAXCsWisU/view?usp=sharing'
    }
]
