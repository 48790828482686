import { Injectable } from '@angular/core';
import { Tarjeta } from '../data/tarjetas';
import { listaTodosVideos } from '../data/data';
import { tarjetasIniciales, tarjetaRecursoActividad,
  videosEstudiantes, preguntas, seccionesPrimerosPasos, secciones } from '../data/datos';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private listasIniciales = tarjetasIniciales;
  private listasRecAct = tarjetaRecursoActividad;
  private listaVideos = listaTodosVideos;
  private listaVideosEstudiantes = videosEstudiantes;
  private listaPreguntasResp = preguntas;
  private listaVideosPrimerosPasos = seccionesPrimerosPasos;
  private listaVideosElegidos = [];
  private tarjetaElegida = '';
  private tarjetaSeccion = '';
  private nuevoVideo = undefined;
  private secciones = secciones;

  constructor() { }
  getTarjeta(cate: string): Tarjeta {
    let listaElegidas = this.listasIniciales.filter( t => this.quitarAcentos(t.titulo).toLowerCase().includes(cate.toLowerCase()));
    return listaElegidas[0];
  }

  getCategoriaElegida(cate: string): string {
    // console.log('antes del servicio get cate:', cate);
    let respuesta;
    switch (cate) {
      case 'Matriculación':
        respuesta = 'Matriculación';
        break;
      case 'VideoConferencias':
        respuesta = 'Video Conferencia';
        break;
      default:
        respuesta = cate;
    }
    // console.log('despues del servicio get cate:', respuesta);
    return respuesta;
  }

  getUrl(url: string): string{
    const posraiz = url.indexOf('#/');
    const poshash = url.lastIndexOf('#');
    // console.log(poshash);
    if (posraiz === poshash ) {
      return url;
    }else{
      return url.slice(0, poshash);
    }
  }
  getUrlsinHash(url: string): string{
    const poshash = url.lastIndexOf('#');
    if ( poshash !== -1) {
      return url.slice(0, poshash);
    }else{
      return  url;
    }
  }
  getSecciones(categoria: string): any[] {
    return this.secciones.filter( t => t.titulo.toUpperCase().includes(categoria.toUpperCase()));
  }
  getSeccion(tarjetaElegida?): any[] {
    if (tarjetaElegida){
      return this.listasIniciales.filter( t => this.quitarAcentos(t.titulo).toUpperCase().includes(tarjetaElegida.toUpperCase()));
    }else{
      return this.listasIniciales.filter( t => t.titulo.toUpperCase().includes(this.tarjetaSeccion.toUpperCase()));
    }
  }

  setEleccion(tarjeta: string): void{
    this.tarjetaSeccion = tarjeta;
    switch (tarjeta) {
      case 'Edición de Audios y Videos':
        tarjeta = 'Edicion';
        break;
      case 'Formato Pestañas':
        tarjeta = 'pestania';
        break;
      case 'Primeros Pasos':
        tarjeta = 'AJUSTES INICIALES';
        break;
    }
    if (this.tarjetaElegida === 'Edición de Audios y Videos'){
      console.log('edicion videos');
      tarjeta = 'Edicion';
    }
    this.tarjetaElegida = tarjeta;
  }
  getEleccion(): string{
    return this.tarjetaElegida;
  }
  getNuevoVideo(): any {
    return this.nuevoVideo;
  }
  getPrimerosPasos(): any[] {
    return this.listaVideosPrimerosPasos;
  }

  quitarAcentos(nombre: string): string{
    nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return nombre.replace(' ', '_').toLowerCase();
  }

  getTarjetasIniciales(): any[] {
    return this.listasIniciales;
  }
  getTarjetasRyA(seccion: string): any[] {
    return this.listasRecAct.filter( t => t.categoria.toLowerCase() === seccion.toLowerCase());
  }
  getTarjetasVideos(categoria: string): any[] {
    this.listaVideosElegidos = this.listaVideos.filter( t => t.categoria.toUpperCase().includes(categoria.toUpperCase()));
    return this.listaVideosElegidos;
  }
  getVideosEstudiantes(): any[] {
    return this.listaVideosEstudiantes;
  }
  getVideos(): any[] {
    this.listaVideosElegidos = this.listaVideos;
    return this.listaVideosElegidos;
  }
  getPreRes(): any[] {
    return this.listaPreguntasResp;
  }
  getVideoElegido(id: number): any {
    return this.listaVideosElegidos.find(v => v.id === id);
  }
  getVideoElegidoTodos(id: number): any {
    return this.listaVideos.find(v => v.id === id);
  }
  getVideoElegidoEstudiante(id: number): any {
    return this.listaVideosEstudiantes.find(v => v.id === id);
  }
  getIndexVideo(id: number): number {
    let video = this.listaVideosElegidos.find(v => v.id === id);
    return this.listaVideosElegidos.indexOf(video);
  }
  isLastVideo(id: number): boolean {
    let video = this.listaVideosElegidos.find(v => v.id === id);
    return this.listaVideosElegidos.indexOf(video) === this.listaVideosElegidos.length - 1;
  }
  getVideoPosAnteriorElegido(id: number): any {
    let pos = this.getIndexVideo(id);
    console.log('video anterior:', this.listaVideosElegidos[pos--]);
    return this.listaVideosElegidos[pos--];
  }
  getVideoPosSiguienteElegido(id: number): any {
    let pos = this.getIndexVideo(id);
    console.log('video siguiente:', this.listaVideosElegidos[pos++]);
    return this.listaVideosElegidos[pos++];
  }

}
