import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { autoridades } from 'src/app/data/datos';
import { quienesSomos } from '../../../data/data';

@Component({
  selector: 'app-presentacion',
  templateUrl: './presentacion.component.html',
  styleUrls: ['./presentacion.component.scss']
})
export class PresentacionComponent implements OnInit {
  listaautoridades = autoridades;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: false, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-sm modal-md modal-lg'
  };
  listaquienessomos = quienesSomos;
  areaElegida: any;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  decline(): void {
    this.modalRef.hide();
  }
  openModal(area: any,template: TemplateRef<any>) {
    this.areaElegida = area;
    this.modalRef = this.modalService.show(template, this.config); //  {class: 'modal-md'} podriagregar esto al config tambien
  }

}
