import { Video } from "../models/video";

export const quienesSomos: any[] = [
    {
        id:1,
        titulo: 'SInIDE',
        descripcion: `
        SInIDE -Sistema Integral de Información Digital Educativa–, fue creado por Resolución del Ministerio de Educación de la Nación N° 1041/12 y luego aprobado por todas las jurisdicciones del país a través de la Resolución del Consejo Federal de Educación N° 215/14 y su Anexo I.
        Tiene como objetivo principal contar con un sistema de información nominal que releve a través de una sola aplicación web, la información sobre las principales variables del sistema educativo.  Este Sistema provee datos sobre titulaciones, cajas curriculares, organización de cursada, formas de agrupamiento de los alumnos, calificaciones, movilidad de los alumnos entre establecimientos, niveles, sectores de gestión y ámbitos, su inclusión en programas educativos y sociales, el acceso y utilización de las TIC, entre otros aspectos.
        
        `,
        imagen: 'sinidelogo.png'
    },
    {
      id:1,
      titulo: 'SInIDE',
      descripcion: `
      SInIDE -Sistema Integral de Información Digital Educativa–, fue creado por Resolución del Ministerio de Educación de la Nación N° 1041/12 y luego aprobado por todas las jurisdicciones del país a través de la Resolución del Consejo Federal de Educación N° 215/14 y su Anexo I.
      Tiene como objetivo principal contar con un sistema de información nominal que releve a través de una sola aplicación web, la información sobre las principales variables del sistema educativo.  Este Sistema provee datos sobre titulaciones, cajas curriculares, organización de cursada, formas de agrupamiento de los alumnos, calificaciones, movilidad de los alumnos entre establecimientos, niveles, sectores de gestión y ámbitos, su inclusión en programas educativos y sociales, el acceso y utilización de las TIC, entre otros aspectos.
      
      `,
      imagen: 'sinidelogo.png'
  },
    {
        id:2,
        titulo: 'RA - Relevamiento Anual',
        descripcion: `
        El RA- Relevamiento Anual- es un operativo educativo de carácter censal, cuya unidad de relevamiento y análisis son las unidades educativas. Recoge al 30 de abril de cada año la información consolidada a nivel nacional sobre las principales variables del sistema educativo. La implementación de este relevamiento se ajusta a un glosario, criterios metodológicos y procedimientos comunes que aseguran la comparabilidad de los datos en adecuadas condiciones de cobertura y calidad
        `,
        imagen: 'ra.png'
    }
];
export const niveles: any[] = [
    {
        id:1,
        titulo: 'Inicial',
        descripcion: `
        Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
        `,
        imagen: 'alumnos.png'
    },
    {
        id:2,
        titulo: 'Primario',
        descripcion: `
        Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
        `,
        imagen: 'alumnos.png'
    },
    {
        id:3,
        titulo: 'Secundario',
        descripcion: `
        Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
        `,
        imagen: 'alumnos.png'
    },
    {
      id:4,
      titulo: 'RA',
      descripcion: `
      Conozca como ingresar, cargar titulaciones planes, matriculas y trayectorias.
      `,
      imagen: 'ralogo2.png'
  }
];

export const sistemas: any[] = [
  //{
    //  id:1,
      //titulo: 'Sinide',
      //descripcion: `
      //Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
      //`,
      //imagen: 'sinidelogo.png',
      //url:'https://sinide.educacion.gob.ar/'
  //},
  {
      id:2,
      titulo: 'RA',
      descripcion: `
      Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
      `,
      imagen: 'ralogo.png',
      url:'https://jujuy.die.educacion.gob.ar/ra2022/'
  },

  {
    id:3,
    titulo: 'Sinide SGE',
    descripcion: `
    Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de este Nivel Educativo.
    `,
    imagen: 'SinIDE-SGE.png',
    url:'https://sge.jujuy.edu.ar/ui/#!/login'
}
];

export const listaTodosVideos: Video[] =[]
/* 

  {
    id: 0,
    titulo: 'Recorrido Básico por SInIDE',
    subtitulo: 'Recorrido Básico para comenzar las cargas en SInIDE',
    imagen: 'icono.png',
    url: 'https://www.youtube.com/embed/x6fGAIesYNI',
    observacion: '',
    categoria: 'INICIO'
  },
    {
      id: 1,
      titulo: 'Carga de Notas FINALES Nivel Primario 2022',
      subtitulo: 'Carga de Notas Nivel Primario',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/_RGJfFV-0gg',
      observacion: '',
      categoria: 'NOTAS,PRIMARIO'
    },
    {
      id: 2,
      titulo: 'Carga de Notas FINALES Nivel Secundario 2022',
      subtitulo: 'Carga de Notas Nivel Secundario',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/kbLnUE3knNw',
      observacion: '',
      categoria: 'NOTAS,SECUNDARIO'
    },
    
      {
        id: 3,
        titulo: 'Solicitar salida con pase a otra Escuela',
        subtitulo: 'Gestionar Salida con Pase a otra escuela mediante Mensajería Interna',
        imagen: 'icono.png',
        url: 'https://www.youtube.com/embed/tn-XLWbRI3c',
        observacion: '',
        categoria: 'INICIAL,PRIMARIO,SECUNDARIO,SALIDAS,MENSAJERIA'
      },
      {
          id: 4,
          titulo: 'Agregar alumnos Nivel Inicial',
          subtitulo: 'Agregar alumnos al Establecimiento en Nivel Inicial',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/tCUWrP22sjc',
          observacion: '',
          categoria: 'INICIAL,ALUMNOS'
        },
        {
          id: 5,
          titulo: 'Agregar alumnos Nivel Primario',
          subtitulo: 'Agregar alumnos al Establecimiento en Nivel Primario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/Yzftjzo3zNE',
          observacion: '',
          categoria: 'ALUMNOS,PRIMARIO'
        },
        {
          id: 6,
          titulo: 'Inscribir alumnos por sección en Nivel Inicial',
          subtitulo: 'Inscribir alumnos por sección en Nivel Inicial',
          imagen: 'angular.png',
          url: 'https://www.youtube.com/embed/NMUTYC-R0Js',
          observacion: '',
          categoria: 'ALUMNOS,INICIAL,INSCRIPCIONES'
        },
        {
          id: 7,
          titulo: 'Inscripción de alumnos Preinscriptos',
          subtitulo: 'Inscripción de alumnos Preinscriptos en SINIDE.',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/NCAnhZXUTag',
          observacion: '',

          categoria: 'INSCRIPCIONES, INICIAL,PRIMARIO',
        },
        {
          id: 8,
          titulo: 'Agregar Autoridades',
          subtitulo: 'Agregar Autoridades en SINIDE.',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/FFqXl55Thh8',
          observacion: '',
          categoria: 'INICIAL,PRIMARIO,SECUNDARIO,INSTITUCIONAL'
        },
        {
          id: 9,
          titulo: 'Cambiar de Turno y División a un alumno',
          subtitulo: 'Cambiar de Turno y División a un alumno en SINIDE.',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/sEGMiWeKWLY',
          observacion: '',
          categoria: 'ALUMNOS,INICIAL,PRIMARIO,SECUNDARIO'
        },
        {
          id: 10,
          titulo: 'Gestionar Salida con Pase sin Pase',
          subtitulo: 'Gestionar Salida con Pase sin Pase en SINIDE.',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/JQQMrQB9x-k',
          observacion: '',
          categoria: 'SALIDAS,INICIAL, PRIMARIO,SECUNDARIO'
        },
        {
          id: 11,
          titulo: 'Carga de Notas Nivel Inicial',
          subtitulo: 'Carga de Notas Nivel Inicial',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/EmFURSELRwk',
          observacion: '',
          categoria: 'INICIAL,NOTAS'
        }
        ,
        {
          id: 12,
          titulo: 'Promover Alumnos Nivel Primario',
          subtitulo: 'Promover Alumnos Nivel Primario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/T1CmHCmUSCs',
          observacion: '',
          categoria: 'ALUMNOS,PRIMARIO,PROMOVER'
        }
        ,
        {
          id: 13,
          titulo: 'Promover Alumnos Nivel Inicial',
          subtitulo: 'Promover Alumnos Nivel Inicial',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/D2z7OYis8XA',
          observacion: '',
          categoria: 'ALUMNOS,INICIAL,PROMOVER'
        }
        ,
        {
          id: 14,
          titulo: 'Promover Alumnos Nivel Secundario',
          subtitulo: 'Promover Alumnos Nivel Secundario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/-I2RqF7JcbA',
          observacion: '',
          categoria: 'ALUMNOS,SECUNDARIO,PROMOVER'
        }
        ,
        {
          id: 15,
          titulo: '2da Capacitación RA Nivel Secundario',
          subtitulo: 'Capacitación Relevamiento Anual Martes 17 de agosto 16 a 18hs Secundario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/dkZRYWBjOlo',
          observacion: '',
          categoria: 'RA'
        }
        ,
        {
          id: 16,
          titulo: 'Capacitación RA Nivel Primario',
          subtitulo: 'Capacitación Relevamiento Anual Lunes 2 de agosto 9 a 12 am Primario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/lNUe21sSGM0',
          observacion: '',
          categoria: 'RA'
        }
        ,
        {
          id: 17,
          titulo: 'Capacitación RA Nivel Secundario',
          subtitulo: 'Capacitación Relevamiento Anual Lunes 2 de agosto 13 a 16hs Secundario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/O2EI1YBVfIc',
          observacion: '',
          categoria: 'RA'
        }

        {
          id: 18,
          titulo: 'Ingresar a Relevamiento Anual',
          subtitulo: 'Ingresar a Relevamiento Anual y marcar recibido',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/uNBGLwJ3rWk',
          categoria: 'RA'
        }
        {
          id: 19,
          titulo: 'Planes y Titulaciones',
          subtitulo: 'Cargar Planes y Titulaciones de mi escuela en Relevamiento Anual',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/dVOAVlckUZA',
          observacion: '',
          categoria: 'RA'
        }
        ,
        {
          id: 20,
          titulo: 'Matricula por Año y División',
          subtitulo: 'Cargar Matricula por Año y División en Relevamiento Anual',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/VHeMdNp7MLo',
          observacion: '',
          categoria: 'RA'
        }
        ,
        {
          id: 21,
          titulo: 'Trayectoria de Matriculas',
          subtitulo: 'Trayectoria de Matriculas de Relevamiento Anual',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/OxandZewNpk',
          observacion: '',
          categoria: 'RA'
        },
        {
          id: 22,
          titulo: 'Carga de Notas Valorativas Semestrales Sinide',
          subtitulo: 'Carga de Notas Valorativas Semestrales Sinide primario secundario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/ZCaKdu-sH_g',
          observacion: '',
          categoria: 'PRIMARIO,SECUNDARIO'
        }
        ,
        {
          id: 23,
          titulo: 'Carga de Notas Valorativas Semestrales Sinide',
          subtitulo: 'Carga de Notas Valorativas Semestrales Sinide primario secundario',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/ZCaKdu-sH_g',
          observacion: 'ya termino la carga de notas semestrales NO USE ESTE VIDEO',
          categoria: 'PRIMARIO,SECUNDARIO, NOTAS'
        },
        {
          id: 24,
          titulo: 'Generar constancia de Alumno Regular',
          subtitulo: 'Generar constancia de Alumno Regular para Alumno de 7mo grado en Sinide',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/LbxDdT7r9nk',
          observacion: '',
          categoria: 'PRIMARIO,CONSTANCIAS'
        },
        
        {
          id: 25,
          titulo: 'Registro y carga de datos Antropométrico',
          subtitulo: 'Registro y carga de datos Antropométrico de Peso y Talla - Crecimiento y Desarrollo Sinide',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/6zDufM_dWxY',
          observacion: '',
          categoria: 'INICIAL, PRIMARIO,SECUNDARIO, ANTROPOMETRIA'
        },
        {
          id: 26,
          titulo: 'Modificar datos Antropométricos',
          subtitulo: 'Modificar datos Antropométricos y generar cuadro nutricional - SINIDE',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/ao8x49eIwH8',
          observacion: '',
          categoria: 'INICIAL, PRIMARIO,SECUNDARIO, ANTROPOMETRIA'
        },
        
        {
          id: 27,
          titulo: 'Carga de Notas Valorativas Finales Nivel Inicial',
          subtitulo: 'Carga de Notas Valorativas Finales Nivel Inicial SINIDE',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/NUJIzMjCu3I',
          observacion: '',
          categoria: 'INICIAL, NOTAS'
        },
        {
          id: 28,
          titulo: 'Carga de Notas Valorativas Finales Nivel Primario',
          subtitulo: 'Carga de Notas Valorativas Finales Nivel Primario SINIDE',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/lsVn1jkjd0I',
          observacion: '',
          categoria: 'PRIMARIO, NOTAS'
        },
        {
          id: 29,
          titulo: 'Carga de Notas Valorativas Finales Nivel Secundario',
          subtitulo: 'Carga de Notas Valorativas Finales Nivel Secundario SINIDE',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/yGj77HgiJ0I',
          observacion: '',
          categoria: 'SECUNDARIO, NOTAS'
        },
        {
          id: 30,
          titulo: '1ra capacitación SINIDE',
          subtitulo: '1ra capacitación SINIDE Lunes 4 de abril 2022',
          imagen: 'icono.png',
          url: 'https://www.youtube.com/embed/fdWYrlWo4gw',
          observacion: '',
          categoria: ''
        }
        
      */
      