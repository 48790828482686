import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, SimpleChanges, OnChanges } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Video } from '../../data/video';
import { documentos } from 'src/app/data/datos';


@Component({
  selector: 'app-tarjetas-videos',
  templateUrl: './tarjetas-videos.component.html',
  styleUrls: ['./tarjetas-videos.component.scss']
})
export class TarjetasVideosComponent implements OnInit, OnChanges {
  
  // direccionAssets = '../../../';
  direccionAssets = '';
  @Input() lista: Video[];
  @Input() categoria: string;
  @Input() videoElegido: any;
  @Input() nuevoVideo: string;
  @Output() nuevoVideoEmit: EventEmitter<any> = new EventEmitter();
  listaOriginal: Video[] = [];
  elegido = 'videoElegido';
  urlActual: string;
  urlOriginal: string;
  location: Location;
  habilitarImg = true;
  urlorigin: string;
  pathname: string;
  url: any;
  videoBus: string = '';
  textoInputBus  = 'Aquí ingrese el nombre del vídeo que quisiera ver...';
  modalRef: BsModalRef;
  message: string;
  bsModalRef: BsModalRef;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: true, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-md modal-lg'
  };
  habilitarTodos = false;
  @Input() todasCategorias: boolean = true;
  @Output() habilitarCategoriasEmit: EventEmitter<any> = new EventEmitter();
  constructor(private modalService: BsModalService, platformLocation: PlatformLocation, location: Location,
     private dataservice: DataService, private router: Router, private activatedRoute: ActivatedRoute) {
       
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    this.url = this.dataservice.getUrl(this.url);
    // console.log('video URL ACTUAL + geturl:', this.url);
    // console.log('categoria al crearse:', this.categoria);
    // console.log('lista que llego al page:',this.lista);
    
  }
  ngOnChanges(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      let url = params.get('pages');
      if(url==='ra'){
        this.categoria = url;
      }
    console.log('CMABIO CATEGORIA:' ,this.categoria);
    console.log('lista que llego:' , this.lista);
    this.lista = this.lista.filter(v=>v.categoria.toUpperCase().includes(this.categoria.toUpperCase())); 
    //console.log('cambio lista categoria:' , this.lista );
    });
  }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(params => {
      //this.categoria = params.get('pages');
      this.urlOriginal = this.router.url;

      //console.log('CMABIO CATEGORIA:' ,this.categoria);
   // console.log('cambio lista:' , this.lista);
    this.lista = this.lista.filter(v=>v.categoria.toUpperCase().includes(this.categoria.toUpperCase())); 
    //console.log('cambio lista categoria:' , this.lista );
      // console.log('videos cris:', this.urlOriginal);
      // console.log('videos cris url:', this.url);
      // console.log(this.urlActual);
      // console.log('CATEGORIA QUE LLEGO DE PAGE:', this.categoria);
      // console.log('VDEO LLEGO DE PAGE:', this.lista);
     
      // console.log('VDEO LISTA ORIGINAL DE PAGE:', this.listaOriginal);
      this.listaOriginal = [];
    });
    // console.log('se volvi a cargar VIDEOS');
  }

  habilitarCategorias():void{
    this.todasCategorias=true;
    this.habilitarCategoriasEmit.emit(this.todasCategorias);
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    //this.listaOriginal = this.lista; // guardo la lista origal que viene del padre
    //console.log('DESPUES lista original',this.listaOriginal);
    //console.log('DESPUES lista ',this.lista);
  }
 
  /**
   * el metodo valida si el objeto esta vacio {}
   */
  validarObjeto(): boolean {
    return Object.entries(this.videoElegido).length === 0;
  }
  elegir(video: any, template: TemplateRef<any> ): void {
    // console.log('ELEGIR URL A MANDAR:', this.urlActual);
    this.openModal(template);
    /*
    if (video.categoria.toLowerCase().includes('estudiante')){
      this.videoElegido = this.dataservice.getVideoElegidoEstudiante(video.id);
    }else{
      if( this.categoria.toLowerCase().includes('todos')){
        this.videoElegido = this.dataservice.getVideoElegidoTodos(video.id);
      }else{
        
      }
    }*/
    this.videoElegido = this.dataservice.getVideoElegido(video.id);
      //  console.log('ELEGIDOOOOOOOOOOOOOOOOOOOOOO');
    // console.log('DESPUES VIDEOS: ', this.videoElegido);
    /*
    if (this.urlOriginal.includes('recurso')){
      this.elegido = 'vrec';
      this.urlActual =  this.url + '#vrec';
      // console.log('RECU ', this.urlActual);
    }else{
      if (this.urlOriginal.includes('actividades')){
        this.elegido = 'vact';
        this.urlActual = this.url + '#vact';
      }else{
        // console.log('ESTA ENTRANDO POR ELEGIDO');
        this.elegido = 'elegido';
        console.log('original con hash:', this.urlOriginal);
        this.urlOriginal = this.dataservice.getUrlsinHash(this.urlOriginal);
        this.urlActual =  this.urlorigin + `/#${this.urlOriginal}` + '#elegido';
        // this.urlActual =  this.url + `#${this.elegido}`;
        // console.log('URL ELEGIDO:', this.urlActual);
      }
    }
    */
    // console.log('URL ELEGIDA:', this.urlActual);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config); //  {class: 'modal-md'} podriagregar esto al config tambien
  }
  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
  }
 
  decline(): void {
    //this.message = 'Declined!';
    this.modalRef.hide();
  }
  buscarVideos(): void {
    // console.log('lista orignales ', this.listaOriginal);
    if (this.listaOriginal.length === 0){
      this.listaOriginal = this.lista;
    }
    if(this.videoBus !== ''){
      let palabras = this.videoBus.split(' ').filter(p => p !== '');
      // console.log('palabras:', palabras);
      let listanueva = [];
      palabras.forEach( p => {
        let bustitulo = this.lista.filter( v => v.titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase()));
        let bussubtitulos = this.lista.filter( v => v.subtitulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase()));
        let busCate = this.lista.filter( v => v.categoria.toUpperCase().includes(p.toUpperCase()));
        listanueva = listanueva.concat(bustitulo.concat(bussubtitulos).concat(busCate));
        let unico = new Set(listanueva);
        listanueva = [...unico];
      });
      // console.log('lista nueva:', listanueva);
      this.lista = listanueva.slice();
    }else{
      this.lista = this.listaOriginal;
    }
  }
  verTodos(): void {
    if (this.listaOriginal.length === 0){
      this.listaOriginal = this.lista;
    }
    // console.log(this.listaOriginal);
    this.lista = this.listaOriginal.slice();
    // console.log('VER TODO lista original', this.listaOriginal);
    // console.log('VER TODO lista ', this.lista);
    this.videoBus = '';
  }
  /*
  ngOnChanges(changes: SimpleChanges): void {
    // detecta los cambios en los input
    // console.log('change first ', changes.lista.firstChange);
    // console.log('change current ', changes.lista.currentValue);
    // console.log('tamaño:',changes.lista.currentValue.length);
    // console.log('change lista ', changes.lista.previousValue);
    
    
    
    this.listaOriginal = Object.assign([], changes.lista.currentValue);
    // console.log(' changelista original', this.listaOriginal);
    // console.log('detecto un cambio');
    if (changes.lista.currentValue.length > 3){
      this.habilitarTodos = true;
    }else{
      this.habilitarTodos = false;
    }
 }
 */

 getClass(video: Video): string{
    return video.id === this.videoElegido.id ? 'bg-dark text-white shadow rounded' : '';
 }
 anterior(): void{
    // this.videoElegido = this.dataservice.getVideoPosAnteriorElegido(this.videoElegido.id);
    let pos = this.getIndexVideo(this.videoElegido.id);
    pos--;
    // console.log('video anterior:', this.lista[pos--]);
    this.videoElegido = this.lista[pos--];
 }
 siguiente(): void{
    // this.videoElegido = this.dataservice.getVideoPosSiguienteElegido(this.videoElegido.id);
    let pos = this.getIndexVideo(this.videoElegido.id);
    pos++;
    // console.log('video siguiente:', this.lista[pos++]);
    this.videoElegido =  this.lista[pos++];
    // console.log('video elegido:', this.videoElegido);
 }
 getPosicion(id: number): number{
  return this.getIndexVideo(id);
 }
 esUltimoVideo(id: number): boolean {
  let video = this.lista.find(v => v.id === id);
  return this.lista.indexOf(video) === this.lista.length - 1;
 }

 getIndexVideo(id: number): number {
  let video = this.lista.find(v => v.id === id);
  return this.lista.indexOf(video);
}

}
