import { Component, OnInit } from '@angular/core';
import { LISTAAVISOS } from 'src/app/data/datos';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent  implements OnInit {
  tarjetas: any[]=LISTAAVISOS.filter(x=>x.id==2);
  tarjetaContacto:any = null;

  constructor() { }

  ngOnInit(): void {
    this.tarjetaContacto = this.tarjetas.find(e=> e.id == 2);
  }

}

