export class Contacto {
    id: number;
    nombre: string;
    email: string;
    emailNuevo: string;
    dni: string;
    comunicacion: string;
    rol: string;
    institucion: string;
    inconveniente: string;
    celular: string;
    horario: string;
    consulta: string;
    fecha: string;
    destinatario: string;
    carrera: string;
}