import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Alumnos } from '../../models/ra/Alumnos';
import { AlumnoTipo } from '../../models/ra/AlumnoTipo';

@Injectable({
  providedIn: 'root'
})
export class AlumnoTipoService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'})
  constructor(private http: HttpClient) {

 }
 getAlumnoTipoTodos(): Observable<AlumnoTipo[]> {
  return this.http.get<AlumnoTipo[]>(this.urlEndPoint + `/alumnotipo`);
}


}

