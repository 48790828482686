import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { TarjetasVideosComponent } from '../tarjetas-videos/tarjetas-videos.component';
import { Video } from '../../models/video';
import { Tarjeta } from '../../data/tarjetas';

@Component({
  selector: 'app-page-acordion',
  templateUrl: './page-acordion.component.html',
  styleUrls: ['./page-acordion.component.scss']
})
export class PageAcordionComponent implements OnInit {
  @ViewChild(TarjetasVideosComponent) tarjetaVideosComponent: TarjetasVideosComponent;
  listaPreguntas = [];
  listaVideos = [];
  listaPrimerosPasos = [];
  categoriaElegida: string;
  nueva: string;
  pagina: string;
  colapse: string;
  collapse: false;
  idvideo= 0;
  videoSeleccionado: Video = new Video();
  seccion: Tarjeta;
  constructor(private router: Router, private dataservice: DataService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    // console.log('VIDEO OBJECT:', this.videoSeleccionado);
    this.activatedRoute.paramMap.subscribe(params => {
      this.pagina = params.get('acordion');
      this.seccion = this.dataservice.getTarjeta(params.get('acordion'));
      if (this.pagina === 'preguntas'){
        this.listaPreguntas = this.dataservice.getPreRes();
        //this.seccion = this.dataservice.getSecciones('preguntas')[0];
      }else{
        this.listaPrimerosPasos  = this.dataservice.getPrimerosPasos();
        //this.seccion = this.dataservice.getSeccion()[0];
      }
      // console.log('seccion:', this.seccion);
    });
  }
  cargarVideos(videoselect: Video): void{
    this.videoSeleccionado = new Video();
    // console.log(this.videoSeleccionado);
    this.categoriaElegida = videoselect.categoria;
    this.nueva = videoselect.titulo;
    this.listaVideos = this.dataservice.getTarjetasVideos(this.categoriaElegida);
  }

}
