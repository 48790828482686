<div class="content">
    <app-section [seccionInput]="seccion"></app-section>

<!--Accordion wrapper-->
<div *ngIf="listaPreguntas.length>0"  class="accordion md-accordion text-center  my-3" id="accordionEx" role="tablist" aria-multiselectable="true">

    <!-- Accordion card -->
    <div  *ngFor="let e of listaPreguntas; let i=index" class="card mx-4" id="acordion">
        <div class="card-header acordion-header" role="tab" id="headingOne{{i}}">
            <a class="collapsed acordion-enlace" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne{{i}}" 
            aria-expanded="true" aria-controls= "collapseOne">
            <h5 class="mb-0"><i class="fas fa-comment-dots mx-2"></i>
                {{e.pregunta}}<i class="fas fa-angle-down rotate-icon mx-2"></i>
            </h5>
            </a>
        </div>
 
            <div id="collapseOne{{i}}" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
           <div class="card-body"> {{e.respuesta}}</div>
        </div>
   
    </div>
    <!-- Accordion card -->
  </div>

  <div *ngIf="listaPrimerosPasos.length>0"  class="accordion md-accordion text-center mx-2 my-3" id="accordionEx" role="tablist" aria-multiselectable="true">

    <!-- Accordion card -->
    <div  *ngFor="let v of listaPrimerosPasos; let i=index" class="card " id="acordion">
        <div class="card-header acordion-header" role="tab" id="headingOne{{i}}">
            <a (click)="cargarVideos(v)" class="collapsed acordion-enlace" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne{{i}}" 
            aria-expanded="true" aria-controls= "collapseOne">
            <h5 class="mb-0"><i class="fab fa-youtube mx-2"></i>
                {{v.titulo}}<i class="fas fa-angle-down rotate-icon mx-2"></i>
            </h5>
            </a>
        </div> 
            <div id="collapseOne{{i}}" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
           <div class="card-body"> {{v.contenido}}
            <a name="videoElegido"></a>
            <br>
            <app-tarjetas-videos *ngIf="listaVideos.length>0"  [lista]="listaVideos" [categoria]="categoriaElegida"
                [videoElegido]="videoSeleccionado">
            </app-tarjetas-videos>
           </div>
        </div>
   
    </div>
    <!-- Accordion card -->
  </div>



</div>