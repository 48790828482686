<a *ngIf="false" href="https://www.facebook.com/profile.php?id=100010079340372" target="_blanck" class="btn btn-primary border-1 border-white mx-1">
    <img src="assets/images/facebook.png" width="30" height="30" alt="">
  </a>
  <!--
  <a href="https://twitter.com/unjuvirtual" target="_blanck" class="btn twitter mx-1">
    <img src="{{direccionAssets}}assets/images/twitter.png" width="30" height="30" alt="">
  </a>
  <a href="https://instagram.com/unjuvirtual?igshid=16dm0q1tbetwp" target="_blanck" class="btn btn-warning mx-1">
    <img src="{{direccionAssets}}assets/images/instagram.png" width="30" height="30" alt="">
  </a>

  <a  href="https://www.youtube.com/channel/UCUbmnlLl8zsIR4o6eTHDnLA/" target="_blanck"
    class="btn btn-danger mx-1">
    <img src="assets/images/youtube.png" width="30" height="30" alt="">
  </a>
  -->
  <a href="https://sinide.educacion.gob.ar/" *ngIf="false" target="_blanck" class="btn btn-light border-1 border-white mx-1">
    <img src="assets/images/chrome.png" width="30" height="30" alt="">
  </a>

  <a class="btn  border-1 border-white mx-1" href="https://api.whatsapp.com/send?phone=+5493884580977&text=
Hola SINIDE, me llamo ......., de la escuela .......  y  quiero consultar sobre: ...." target="_blanck">
    <img class="imagen" src="assets/images/whatsapp.png"  width="30" height="30"  alt="WhatsApp">
  </a>
