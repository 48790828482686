<div class="mc-banner-type-c-personas img-fluid" style="background-image: url('assets/images/informe1.png');">
  <div class="bg-color-9 d-flex justify-content-center py-5">
    <div class="row container text-white text-left">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="display-4 font-weight-bold animated fadeIn">
          Formularios
          <div class="animated fadeIn">
            <h3>
              En esta sección podrás ver y descargar formularios importantes
            </h3>

          </div>
          <a routerLink="/" class="btn rounded-pill botonesInicio m-t-10 font-14">
            <i class="fas fa-school"></i>
            Regresar a Inicio
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid text-center my-2">
    <div>
      <div class="alert text-center fondo text-white m-0 rounded shadow">
        <H1> <i class="fas fa-bookmark"></i> Formularios Importantes</H1>
      </div>

      <!--<div class="container-fluid  d-flex justify-content-center flex-wrap my-1">
      <div *ngFor="let d of listaDocumentos; let i=index" class="card  p-0 col-lg-3 my-2 cards text-center ">
        <div class='card-header fondo-celeste'>
          <h4 class='card-title text-white col-12'>{{d.titulo}} </h4>
        </div>
        <div class='card-body px-0'>-->
      <!--<div class="badge-warning rounded mx-5">Nuevo</div>-->
      <!--<div class="embed-responsive embed-responsive-16by9">
            <iframe width="100%" [src]="getUrlPdfPreview(d.urlpdf)  | safeUrl"></iframe>
          </div>
        </div>
        <div class='card-footer card-footer-button text-muted mx-auto'>
          <a class='btn botonver btn-dark col-12 my-1' (click)="elegir(d,templateDocumentacion)">
            Ver Documento <i class="fas fa-eye"></i> </a>
          <a class='btn botondes btn-dark col-12' href="{{getUrlPdfView(d.urlpdf)}}" target="_blanck">
            Descargar <i class="fas fa-external-link-alt"></i> </a>

        </div>
      </div>
    </div>
  </div>-->
      <div class="container-fluid  my-2 " style=" background-color: white;">
        <tabset class="text-center nav-fill" type="pills" style="width: 100%; justify-content: center; padding: 5px;">
          <tab  class="tabb" heading="Formularios"><app-form></app-form></tab>
          <tab  class="tabb" heading="Formularios RA"><app-form-ra></app-form-ra></tab>
        </tabset>
      </div>
     
      <ng-template #templateDocumentacion>

        <div class="modal-header azul-violeta text-white header-card text-center">
          <h2 class="modal-title  w-100 text-center">{{documentoElegido.titulo}}</h2>
          <button *ngIf="false" type="button" class="btn btn-primary btn-circle pull-right mx-1 border border-white"
            aria-label="Close">
            <i class="fas fa-chevron-circle-left"></i>
          </button>
          <button *ngIf="false" type="button" class="btn btn-primary btn-circle pull-right mx-1 border border-white"
            aria-label="Close">
            <i class="fas fa-chevron-circle-right"></i>
          </button>
          <button type="button" class="btn btn-danger btn-circle pull-right border border-white" aria-label="Close"
            (click)="modalRef.hide()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body text-dark">
          <div class="text-center d-sm-block d-md-block d-lg-block" id="videoSeleccionado">
            <a class='btn btn-danger col-6 my-1' href="{{getUrlPdfView(documentoElegido.urlpdf)}}" target="_blanck">
              Descargar <i class="fas fa-external-link-alt"></i> </a>

            <div class="embed-responsive embed-responsive-16by9">
              <iframe width="100%" height="500" [src]="getUrlPdfPreview(documentoElegido.urlpdf)  | safeUrl"></iframe>
            </div>
          </div>
        </div>

      </ng-template>