import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TarjetasVideosComponent } from '../tarjetas-videos/tarjetas-videos.component';
import { Video } from '../../models/video';
import { PlatformLocation } from '@angular/common';
import { Tarjeta } from '../../data/tarjetas';
import { listaTodosVideos } from 'src/app/data/data';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild(TarjetasVideosComponent) tarjetaVideosComponent: TarjetasVideosComponent;
  categoriaElegida: string;
  listaTarjetas = [];
  tarjetas: any[] = [];
  listaVideos: Video[] = [];
  listaVideosOriginal: Video[] = listaTodosVideos;
  urlActual: string;
  urlOriginal: string;
  videoSeleccionado: Video = new Video();
  seccion: Tarjeta;
  section: string;
  urlorigin: string;
  pathname: string;
  url: any;
  videoconferia = 'Video Conferencia';
  habilitarTodasCategorias: boolean = true;
  constructor(platformLocation: PlatformLocation, private router: Router, private dataservice: DataService, private activatedRoute: ActivatedRoute) {
    this.categoriaElegida = this.activatedRoute.snapshot.params['pages'];
    //console.log('PAGES elegida:', this.section);
    // this.listaTarjetas = this.dataservice.getTarjetasRyA(this.section);
    // this.seccion = this.dataservice.getSeccion(this.section)[0];
    // this.router.navigate(['seccion', this.section]);
    this.cargarListas();
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    this.url = this.dataservice.getUrl(this.url);
    //console.log('REC URL ACTUAL + geturl:', this.url);
  }

  ngOnInit(): void {
    //this.cargarListas();
  }


  getTipoVideo(tipoVideoElegido: string) {
    this.categoriaElegida = tipoVideoElegido;
    this.habilitarTodasCategorias = false;
    console.log('categoria elegidaaaaaaaaaaaaa:', this.categoriaElegida);

  }
  getHabilitarCategoria(habilitar: boolean): void {
    this.habilitarTodasCategorias = habilitar;
    this.categoriaElegida = this.activatedRoute.snapshot.params['pages'];
  }

  cargarListas(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.categoriaElegida = params.get('pages');
      if(this.categoriaElegida.toUpperCase() == 'RA'){
        this.habilitarTodasCategorias = false;
      }else{
        this.habilitarTodasCategorias = true;
      }
      

      //console.log('CATEGORIAAAAAAAAAAA:', this.categoriaElegida.toUpperCase());
      this.listaVideos = this.listaVideosOriginal.filter(v => v.categoria.toUpperCase().includes(this.categoriaElegida.toUpperCase()));
      //console.log('video POR CATEGORIAAAAAA:', this.listaVideos);

      this.seccion = this.dataservice.getTarjeta(params.get('pages'));
      switch (this.categoriaElegida) {
        case 'docentes':
          this.listaVideos = this.dataservice.getVideos();
          break;
        default:
          this.listaVideos = this.dataservice.getTarjetasVideos(this.categoriaElegida);
          break;
      }
      // console.log('PAGE LISTA:', this.listaVideos);
      this.videoSeleccionado = new Video();
      this.categoriaElegida = this.dataservice.getEleccion();
      if (this.categoriaElegida.toLowerCase() === 'videoconferencias') {
        this.categoriaElegida = 'Video Conferencia';
      }
      if (this.categoriaElegida.toLowerCase().includes('pestania')) {
        this.categoriaElegida = 'Pestañas';
      }

      this.urlOriginal = this.router.url;
      this.videoSeleccionado = new Video();

      // console.log('pages:', this.urlOriginal);
    });
  }

}
