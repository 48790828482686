<div class="position-relative m-0">
    <section class="section section-lg section-hero section-shaped ">
      <div class="shape shape-style-1 violeta-claro"><span class="span-150"></span><span class="span-50"></span><span
          class="span-50"></span><span class="span-75"></span><span class="span-100"></span><span
          class="span-75"></span><span class="span-50"></span><span class="span-100"></span><span
          class="span-50"></span><span class="span-100"></span></div>
      <div class="container shape-container d-flex align-items-center py-lg">
        <div class="col px-0">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center animated fadeIn">
                <div class="row m-0 d-flex justify-content-center flex-wrap">
                    <div class="col-12 text-white">
                        <h3 class="display-4">¿Quiénes somos?</h3>
                        <p >
                            El Sistema Integral de Información Digital Educativa (SiNIDE) y El Relevamiento Anual (RA) son Sistemas que integran el área de Sistemas de Información perteneciente a la Dirección de Información Monitoreo y Evaluación Educativa de la Secretaría de Innovación y Calidad Educativa. Estas áreas tienen la función de trabajar con información relevada mediante un sistema que registra datos de todas las unidades educativas de la Provincia de Jujuy, tanto en sus niveles como en sus Regiones Educativas.
A través de esta Dirección, y por medio de los sistemas mencionados, se recaba información que comprende diferentes aspectos de la trayectoria de los estudiantes del Sistema Educativo Jujeño, lo que permite generar indicadores con respecto a por ejemplo: tasas de egreso, promoción efectiva, repitencia y abandono, así como también, datos de los estudiantes salidos con o sin pase.

                          </p>
                    </div>
                    <div class="col-12">
                        

                        <div class="d-flex justify-content-center flex-wrap my-2">
                            <div *ngFor="let t of listaquienessomos; let i=index" class=" 
                            col-sm-12 col-md-12 col-lg-5 col-xl-5 mx-2  p-0 my-1 text-center animated fadeIn">
                                <a class='btn botonesInicio azul-violeta' (click)="openModal(t,templateArea)">
                                    <img *ngIf="t.titulo !== 'Autoridades'" src="assets/images/{{t.imagen}}" width="150" height="100" class='card-img-top iconos' alt='...'>
                                    <div class="row m-0 text-center d-flex align-items-center">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <img *ngIf="t.titulo === 'Autoridades'" src="assets/images/{{t.imagen}}"  class='card-img-top iconos autoridades' alt='...'>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <h3 *ngIf="t.titulo === 'Autoridades'">{{t.titulo}}</h3>
                                        </div>
                                    </div>
                                    
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100"><svg preserveAspectRatio="none" version="1.1"
          viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg></div>
    </section>
  </div>

  <ng-template #templateArea>
    <div class=" m-0"  tabindex="-1" role="document">
        <div class="modal-content">
            <div class="modal-header headerModal ">
                <div class="row m-0 d-flex align-items-center justify-content-center" *ngIf="areaElegida.titulo!=='Autoridades'">
                    <div class="col-12">
                        <img src="assets/images/{{areaElegida.imagen}}"  class='card-img-top tamanio-img' alt='...'>
                    </div>
                    <div class="col-12">
                        <h4 class="modal-title mx-2" id="exampleModalLabel">
                            {{areaElegida?.titulo}}</h4>    
                    </div>
                    
                    
                </div>
                <div class="row m-0 d-flex align-items-center justify-content-center" *ngIf="areaElegida.titulo==='Autoridades'">
                    <div class="col-12">
                        
                    </div>
                    <div class="col-12">
                        
                    </div>
                    <img src="assets/images/{{areaElegida.imagen}}"  class='card-img-top tamanio-img autoridades' alt='...'>
                        <h4 class="modal-title mx-2" id="exampleModalLabel">{{areaElegida?.titulo}}</h4>    
                    
                </div>
               
                
                <button type="button" class="btn btn-danger btn-circle pull-right" (click)="decline()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert violeta-claro text-white shadow rounded" role="alert" *ngIf="areaElegida.titulo!=='Autoridades'">
                    {{areaElegida?.descripcion}}
                </div>

                <div class='card-body text-left text-dark p-0' *ngIf="areaElegida.titulo==='Autoridades'" >
                        <div class="row p-0">
                            <div
                                class="col-sm-12 col-md-12 col-lg-12 col-xl-12 display-5 animated fadeIn rounded  p-2">
                                <p *ngFor="let autoridad of listaautoridades" class="items-tarjetas shadow rounded sombra ">
                                    <button mat-mini-fab class=" btn-icons">
                                        <i class="fas fa-{{autoridad.icono}}"></i>
                                    </button>
                                    {{autoridad.cargo}}:
                                    <span class="font-weight-bold">{{autoridad.nombre}}</span>
                                </p>
                            </div>
                        </div>
                </div>
                <!--formulario-->
               
            </div>
        </div>
    </div>   
</ng-template>