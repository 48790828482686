import { Categoria } from "./Categoria";
import { Dependencia } from "./Dependencia";
import { Estado } from './Estado';
import { Responsable } from "./Responsable";
import { Sector } from "./Sector";

export class Establecimiento{
    idEstablecimiento: number;
    cArancelado: number;
    cCategoria: Categoria;
    cConfesional: number;
    cDependencia: Dependencia;
    cEstado: Estado;
    cSector: Sector;
    cue: string;
    fechaActualizacion: string;
    fechaAlta: string;
    fechaBaja: string;
    fechaCreacion: string;
    nombre: string;
    responsable: Responsable;
}