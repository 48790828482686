import { Video } from './video';
export const videoscap: Video[] = [
    {
      id: 1,
      titulo: 'Capacitación sge-Nivel Secundario 2023',
      subtitulo: 'Sinide-SGE',
      imagen: 'icono.png',
      url: 'https://youtu.be/39leBrxA3qI',
      observacion: '',
      categoria: 'CAPACITACION',
    },
    {
      id: 2,
      titulo: 'Capacitación 8 de Marzo 2024 -Nivel Secundario',
      subtitulo: 'Temas: Capacitacion de secundario Promocion y cierre de ciclo 2023.',
      imagen: 'icono.png',
      url: 'https://youtu.be/XkbU9gSx5fY',
      observacion: '',
      categoria: 'CAPACITACION',
    },
    {
      id: 3,
      titulo: 'Capacitación de Relevamiento Anual 2024 I P',
      subtitulo: 'Capacitación de Relevamiento Anual 2024 I P',
      imagen: 'icono.png',
      url: 'https://youtu.be/Z5eYeuz9yv8',
      observacion: '',
      categoria: 'CAPACITACION',
    }
]
