import { Component, OnInit } from '@angular/core';
import { niveles } from 'src/app/data/data';
import { Router } from '@angular/router';
import { listaTodosVideos } from '../../data/data';

@Component({
  selector: 'app-noticias-importantes',
  templateUrl: './noticias-importantes.component.html',
  styleUrls: ['./noticias-importantes.component.scss']
})
export class NoticiasImportantesComponent implements OnInit {
  tarjetasNiveles = niveles.filter(n=>n.titulo.toUpperCase().includes('RA'));
  videoGenerarConstancia: any;
  videoNotasFinalNI: any;
  listaVideos: any[]=listaTodosVideos;
  listaNotasFinalesVideos: any[] = listaTodosVideos.filter(v=> [1,2].includes(v.id));

  constructor( private router: Router) { }

  ngOnInit(): void {
    console.log(this.listaVideos);
    this.videoGenerarConstancia = this.listaVideos.find(v => v.id ===24); //generar constancia
    this.videoNotasFinalNI = this.listaVideos.find(v=> v.id === 27); // Carga de Notas Valorativas Finales Nivel Inicial
    this.listaNotasFinalesVideos.unshift(this.videoNotasFinalNI);
  }
  cargarPagina(titulo: string): void{
    console.log('eligio tituto:',titulo);
    this.router.navigate(['seccion', titulo.toLowerCase()]);
  }
  getClassNiveles(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'botonesInicio';
      case 'PRIMARIO':
        return 'botonesInicioCeleste';
        case 'SECUNDARIO':
          return 'botonesInicioRosa';
      default:
        return 'botonesInicioVioleta';
    }
  }
  getClassNivelesTitulo(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'azul-violeta';
      case 'PRIMARIO':
        return 'celeste';
        case 'SECUNDARIO':
          return 'rosa-rojo';
      default:
        return 'violeta-claro';
    }
  }
  getClassNivelesTituloOnda(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'onda-azul-violeta';
      case 'PRIMARIO':
        return 'onda-celeste';
        case 'SECUNDARIO':
          return 'onda-rosa-rojo';
      default:
        return 'onda-violeta-claro';
    }
  }

}