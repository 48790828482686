
<a name="videoElegido"></a>
<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 alert text-white text-center violeta-claro m-0 rounded shadow">
    <H1> <i class="fab fa-youtube"></i> Seleccione una categoría
    
  </H1>

  </div>
  
<div class="d-flex justify-content-center flex-wrap my-5">
    <div *ngFor="let t of tarjetas; let i=index" class="card 
          col-sm-12 col-md-12 col-lg-3 col-xl-2 mx-2  p-0 my-4 cards text-center animated fadeIn">
      <div class='card-header azul-violeta'>
        <img src="assets/images/{{t.imagen}}" class='card-img-top iconos' alt='...'>
      </div>
      <div class='card-body'>
        <h5 class='card-title'>{{t.titulo}} </h5>
        <div *ngIf="false" class="badge-warning rounded mx-5">Nuevo</div>
        <p class='card-text'>{{t.subtitulo}} </p>
      </div>
      <div class='card-footer card-footer-button text-muted mx-auto'>
        <a (click)="cargarPagina(t.titulo)" class='btn botonesInicio '> Seleccionar </a>
      </div>
    </div>
  </div>

