<section class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 mb-3" *ngFor="let c of listaConsultores;let i=index">
                <div class="card rounded shadow text-center">
                    <div class="card-header embed-responsive">
                        <div class="pmd-card">
                            <div>
                                <h2 class="card-title">CUE: 1234567890</h2>
                                <p class="card-subtitle mb-3">Escuela: Normal Superior Rep. de Bolivia</p>
                                <p class="card-text">Nivel: Secundario</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">

                        <img src="/src/assets/images/alumnos.png" class="card-img-top" >

                        <div>
                            <div>
                                <p class="card-subtitle">Consultor</p>
                            </div>
                            <h3 class="card-title">{{c.apellido}}</h3>
                            <h3 class="card-title">{{c.nombre}}</h3>
                            <p>Horario de Atención: {{c.horarioAtencion}}</p>
                            <p>Teléfono: {{c.celular}}</p>
                            <p>E-mail: {{c.correo}}</p>
                        </div>
                    </div>
                    <div class="card-footer">
                        <section class="buttons">
                            <a href="https://wa.me/+{{c.celular}}" class="fab fa-whatsapp"></a>
                            <a href="mailto:+{{c.correo}}" class="far fa-envelope"></a>
                        </section>

                    </div>
                </div>
            </div>



        </div>

    </div>
</section>