<!--<div class="container-fluid p-0 my-2">
    <div class="row m-0 d-flex row justify-content-center flex-wrap my-3">
        <div class="col-sm-12 col-md-12 col-lg-12 text-center my-2">
            <h1>Videos para la Carga de Notas Finales 2022</h1>
        </div>
        <div class="card border-primary mb-3" style="width: 18rem;">
            <img src="..." class="card-img-top" alt="...">
            <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                    content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div><div class="card border-primary mb-3" style="width: 18rem;">
            <img src="..." class="card-img-top" alt="...">
            <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                    content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div><div class="card border-primary mb-3" style="width: 18rem;">
            <img src="..." class="card-img-top" alt="...">
            <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                    content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div>
       <div class="col-sm-12 col-md-6 col-lg-4 text-center my-2" *ngFor="let video of listaNotasFinalesVideos">
            <h4>{{ video.titulo }}</h4>

            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12" [src]="video?.url | safeUrl"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="alert alert-danger" *ngIf="video.id == 27">
                <p><strong>Importante!</strong> DEBE CARGAR LAS NOTAS CON FECHA DE CARGA 29 de Noviembre según
                    Resolución
                    1228-E /2022 ANEXO I</p>
            </div>

        </div>
    </div>
</div>-->

<section class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col text-center mb-5">
                <h1 class="display-4">Videos Tutorial SGE Jujuy</h1>
                <p class="text-muted">Estos son algunos videos tutoriales para comenzar a usar el Sistema SGE</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 mb-4" *ngFor="let v of listaVideosPrincipales;let i=index">
                <div class="card rounded shadow text-center" style="background-color: #00bdf4!important;">
                    <div class="embed-responsive embed-responsive-4by3">
                        <iframe class="embed-responsive-item"
                            [src]="getObtenerIdVideo(v.url) | safeUrl"></iframe>
                    </div>
                    <div class="card-body">

                        <h5 class="card-title"><span class="badge bg-success mx-1">{{i+1}}</span>{{v.titulo}}</h5>
                        <h6 class="card-subtitle mb-2 text-muted"></h6>
                        <p class="card-text">{{v.subtitulo}}</p>
                            <a type="button" target="_blank" [href]="v.url" class="btn btn-danger mx-auto">
                              <i class="fa fa-play"></i>
                              Ver en Youtube</a>
                    </div>
                    <div class="card-footer">
                      <h5>Compartir Video</h5>
                      <div class="row p-0 d-flex justify-content-center">
                        <share-buttons [theme]="'material-dark'" [include]="['whatsapp']" [show]="1" [size]="9"
                          url="{{v.url}}" [autoSetMeta]="false"></share-buttons>
                        <share-buttons mat-mini-fab [theme]="'material-dark'" [include]="['email']" [show]="1" [size]="9"
                          url="{{v.url}   }" [autoSetMeta]="false"></share-buttons>

                        <share-buttons [theme]="'material-dark'" [include]="['copy']" [show]="1" [size]="9"
                          url="{{v.url}   }" [autoSetMeta]="false"></share-buttons>
                      </div>

                    </div>
                </div>
            </div>



        </div>

    </div>
</section>
