import { Component, OnInit } from '@angular/core';
import { documentos } from 'src/app/data/datos';
import { sistemas } from '../../data/data';

@Component({
  selector: 'app-lista-documentacion',
  templateUrl: './lista-documentacion.component.html',
  styleUrls: ['./lista-documentacion.component.scss']
})
export class ListaDocumentacionComponent implements OnInit {
  listaSistemas: any[]=sistemas.filter(s=>s.titulo.toUpperCase().includes('RA'));
  listaDocumentos=[];

  constructor() { 
    this.listaDocumentos=documentos;
    console.log('documentos :', this.listaDocumentos);
  }

  ngOnInit(): void {
    this.listaDocumentos = this.listaDocumentos.filter(x=>x.id == -2);
    console.log('documentos ngon:', this.listaDocumentos);
  }
  getUrlPdfPreview(urlpdf: string): string{
    let pos = urlpdf?.indexOf('view?usp=sharing');
    return pos!==-1 ? urlpdf.slice(0,pos)+'preview':'';
  }
  getUrlPdfView(urlpdf: string): string{
    let pos = urlpdf.indexOf('?usp=sharing');
    return urlpdf.slice(0,pos);
  }

}
