export const quienesSomos: any[] = [
    {
        id:1,
        titulo: 'Sistema Integral de Información Digital Educativa',
        descripcion: `
        SInIDE -Sistema Integral de Información Digital Educativa–, fue creado por Resolución del Ministerio de Educación de la Nación N° 1041/12 y luego aprobado por todas las jurisdicciones del país a través de la Resolución del Consejo Federal de Educación N° 215/14 y su Anexo I.
        Tiene como objetivo principal contar con un sistema de información nominal que releve a través de una sola aplicación web, la información sobre las principales variables del sistema educativo.  Este Sistema provee datos sobre titulaciones, cajas curriculares, organización de cursada, formas de agrupamiento de los alumnos, calificaciones, movilidad de los alumnos entre establecimientos, niveles, sectores de gestión y ámbitos, su inclusión en programas educativos y sociales, el acceso y utilización de las TIC, entre otros aspectos.
        
        `,
        imagen: 'sinidelogo.png'
    },
    {
        id:2,
        titulo: 'Relevamiento Anual',
        descripcion: `
        El RA- Relevamiento Anual- es un operativo educativo de carácter censal, cuya unidad de relevamiento y análisis son las unidades educativas. Recoge al 30 de abril de cada año la información consolidada a nivel nacional sobre las principales variables del sistema educativo. La implementación de este relevamiento se ajusta a un glosario, criterios metodológicos y procedimientos comunes que aseguran la comparabilidad de los datos en adecuadas condiciones de cobertura y calidad
        `,
        imagen: 'ralogo.png'
    },
    {
        id:3,
        titulo: 'Autoridades',
        descripcion: `
        El RA- Relevamiento Anual- es un operativo educativo de carácter censal, cuya unidad de relevamiento y análisis son las unidades educativas. Recoge al 30 de abril de cada año la información consolidada a nivel nacional sobre las principales variables del sistema educativo. La implementación de este relevamiento se ajusta a un glosario, criterios metodológicos y procedimientos comunes que aseguran la comparabilidad de los datos en adecuadas condiciones de cobertura y calidad
        `,
        imagen: 'autoridad.png'
    }
];