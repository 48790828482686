<!-- Footer -->
<footer class="page-footer font-small stylish-color-dark border-top border-1 border-white bg-footer text-white pt-4"
  id="footer">

  <!-- Call to action -->
  <!-- Call to action -->
  <div class="d-flex justify-content-center mr-auto ml-auto">
    <h2 class="text-naranja"> Ministerio de Educación</h2>
  </div>
  <div class="d-flex justify-content-center mr-auto ml-auto">

    <h3 class="text-naranja"> Complejo Ministerial</h3>
  </div>
  <div class="my-2 d-flex justify-content-center mr-auto ml-auto">
    <h5> sinidera@sice.jujuy.edu.ar</h5>

  </div>
  <div class="d-flex justify-content-center mr-auto ml-auto">

    <h5> Teléfono Celular: 3884580977</h5>

  </div>
  <!-- Call to action -->
  <hr>
  <!-- Social buttons -->
  <div class="d-flex justify-content-center mr-auto ml-auto">
    <app-redes-sociales></app-redes-sociales>
  </div>
  <!-- Social buttons -->
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2021 Copyright:

    <a class="text-white"> Área de Sistemas de Información</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
