import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  listaTarjetas = [];
  tarjetas: any[] = [];
  section: string;
  constructor(private dataservice: DataService, private activatedRoute: ActivatedRoute) {
    this.section = this.activatedRoute.snapshot.params['page'];
    this.listaTarjetas = this.dataservice.getTarjetasRyA(this.section);
  }

  ngOnInit(): void {
    // console.log(this.section);
    this.cargarTarjetas();
  }
  cargarTarjetas(): void{
    this.tarjetas = this.listaTarjetas.filter(t => t.categoria === this.section.toString());
    // console.log(this.tarjetas);
  }
  cargarVideos(titulo): void {
    // console.log('elegir');
  }

}
