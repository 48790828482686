import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { niveles } from '../../data/data';

@Component({
  selector: 'app-niveles',
  templateUrl: './niveles.component.html',
  styleUrls: ['./niveles.component.scss']
})
export class NivelesComponent implements OnInit {
  tarjetasNiveles = niveles.slice(0,3);

  constructor( private router: Router) { }

  ngOnInit(): void {
  }
  cargarPagina(titulo: string): void{
    console.log('eligio tituto:',titulo);
    this.router.navigate(['seccion', titulo.toLowerCase()]);
  }
  getClassNiveles(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'botonesInicio';
      case 'PRIMARIO':
        return 'botonesInicioCeleste';
        case 'SECUNDARIO':
          return 'botonesInicioRosa';
      default:
        return 'botonesInicioVioleta';
    }
  }
  getClassNivelesTitulo(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'azul-violeta';
      case 'PRIMARIO':
        return 'celeste';
        case 'SECUNDARIO':
          return 'rosa-rojo';
      default:
        return 'violeta-claro';
    }
  }
  getClassNivelesTituloOnda(titulo: string): string {
    switch(titulo.toUpperCase()){
      case 'INICIAL':
        return 'onda-azul-violeta';
      case 'PRIMARIO':
        return 'onda-celeste';
        case 'SECUNDARIO':
          return 'onda-rosa-rojo';
      default:
        return 'onda-violeta-claro';
    }
  }

}
