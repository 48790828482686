import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { listaTodosVideos } from 'src/app/data/data';
import { formularios } from 'src/app/data/formularios';
import { Video } from 'src/app/data/video';
import { Documento } from 'src/app/models/Documento';

@Component({
  selector: 'app-formularios-sge',
  templateUrl: './formularios-sge.component.html',
  styleUrls: ['./formularios-sge.component.scss']
})
export class FormulariosSgeComponent implements OnInit {
 @Input() listaDocumentos: Documento[];
  documentoElegido: Documento = new Documento();
  tarjetasRec: Video[] = [];
  tarjetasAct: Video[] = [];
  tarjetasMat: Video[] = [];
  modalRef: BsModalRef;
  listaTarjetas: Video[] = listaTodosVideos;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: false, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-sm modal-md modal-lg modal-xl'
  };

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    console.log('se cargo formulario sge')
    this.cargarTarjetas();
    this.listaDocumentos.forEach(e=>{
      console.log(this.getUrlPdfPreview(e.urlpdf));
    })
  }
  getUrlPdfView(urlpdf: string): string{
    let pos = urlpdf.indexOf('?usp=sharing');
    return urlpdf.slice(0,pos);
  }
  getUrlPdfPreview(urlpdf: string): string{
    let pos = urlpdf.indexOf('view?usp=sharing');
    return urlpdf.slice(0,pos)+'preview';
  }
  cargarTarjetas(): void{
    this.tarjetasRec = this.listaTarjetas.filter(t => t.categoria.toLowerCase().includes('recurso'));
    this.tarjetasAct = this.listaTarjetas.filter(t => t.categoria.toLowerCase().includes('actividad'));
    this.tarjetasMat = this.listaTarjetas.filter(t => t.categoria.toLowerCase().includes('matriculacion'));
    // console.log(this.tarjetas);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config); //  {class: 'modal-md'} podriagregar esto al config tambien
  }
  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
  }


  decline(): void {
    //this.message = 'Declined!';
    this.modalRef.hide();
  }
  elegir(documento: Documento, template: TemplateRef<any> ): void {
    this.documentoElegido = documento;
    this.openModal(template);

  }
}
