<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 alert text-white text-center violeta m-0 rounded shadow">
  <H1> <i class="fab fa-youtube"></i> Videos Tutoriales</H1>

</div>
<div class="container my-2">
  <h5>
    Aquí encontrarás una  guía de los procedimientos de carga  con vídeos-tutoriales desde el inicio Datos institucionales, carga de datos personales del alumno, inscribir-preinscriptos, salidos con y sin pase  notas valorativas,  de los Niveles Educativos.
  </h5>

</div>


<div class="d-flex justify-content-center flex-wrap my-3">
  <div *ngFor="let t of tarjetasNiveles; let i=index" class="card 
          col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-1  p-0 my-4 cards text-center animated fadeIn">
    <div class='card-header border-bottom-0 flex-column justify-content-center text-white' [ngClass]="getClassNivelesTitulo(t.titulo)">
      <img src="assets/images/{{t.imagen}}" class='card-img-top iconos' alt='...'>

      <div>
        <img *ngIf="t.titulo!=='RA'" src="assets/images/sinidelogo.png" class='' width="100" alt='...'>

      </div>
      <h3 *ngIf="t.titulo!=='RA'" class='card-title'>Nivel {{t.titulo}} </h3>
      <h3 *ngIf="t.titulo==='RA'" class='card-title'>Relevamiento Anual </h3>
    </div>
    <div style="height: 150px; overflow: hidden; margin-bottom: -91px;"><svg viewBox="0 0 500 150" preserveAspectRatio="none"
        style="height: 100%; width: 100%;">
        <path [ngClass]="getClassNivelesTituloOnda(t.titulo)" d="M-3.15,5.44 C298.19,144.56 262.63,-85.35 502.48,60.69 L516.02,-37.00 L-29.11,-23.19 Z"
          style="stroke: none; "></path>
      </svg></div>
    

    <div class='card-body' *ngIf="false">

      <p  class='card-text'>{{t.descripcion}} </p>
    </div>
    <div class='card-footer card-footer-button text-muted mx-auto'>
      <a (click)="cargarPagina(t.titulo)" class='btn' [ngClass]="getClassNiveles(t.titulo)"> Ingresar </a>

    </div>
  </div>
</div>