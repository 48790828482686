import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Tarjeta } from 'src/app/data/tarjetas';
import { tarjetasIniciales } from '../../data/datos';
import { listaTodosVideos, niveles } from '../../data/data';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.scss']
})
export class TarjetasComponent implements OnInit {
  tarjetas: Tarjeta[] = tarjetasIniciales;
  @Input() nivelElegido: string;
  
  @Output() tipoVideoElegidoEmit: EventEmitter<any> = new EventEmitter();
  listaVideos:any[]= listaTodosVideos;
  videoElegido: any;
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: true, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-md modal-lg'
  };
  urlorigin: string;
  pathname: string;
  url: any;
  urlActual: string;
  constructor(private modalService: BsModalService, 
    platformLocation: PlatformLocation,
    private activatedRoute: ActivatedRoute) { 
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    
    console.log('REC URL ACTUAL + geturl:', this.url);
  }

  ngOnInit(): void {
    console.log('se inicia TARJETAS');
    console.log('CATEGORIA ELEGIDA:', this.nivelElegido);
    this.filtrarTarjetas();
  }

  cargarPagina(tipoVideo: string): void{
    
    this.urlActual = this.url + '#videoElegido';
    console.log('URL ACTUAL:', this.urlActual);
    //alumnos por la tarjeta cargar alumnos
    tipoVideo.toUpperCase().includes('ALUMNOS') ? this.tipoVideoElegidoEmit.emit('Alumnos'):this.tipoVideoElegidoEmit.emit(this.sinAcento(tipoVideo));
    
  }
  sinAcento(texto: string): string{
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  elegir(video: any, template: TemplateRef<any> ): void {
    this.modalRef = this.modalService.show(template, this.config);
    this.videoElegido = this.listaVideos.find(e => e.titulo.toUpperCase().includes(video.toUpperCase()));
  }
  validarObjeto(): boolean {
    return Object.entries(this.videoElegido).length === 0;
  }

  listavideos= listaTodosVideos;
  
  filtrarTarjetas(): void{
    this.tarjetas = tarjetasIniciales;
    this.listavideos = listaTodosVideos;
    let tarjetasElegidas: Tarjeta[] = [];  
    console.log('NIVELLLLLLLLLLL',this.nivelElegido);
    let listaXNivel = this.listavideos.filter(v=>v.categoria.toUpperCase().includes(this.nivelElegido.toUpperCase())); 
    console.log('lista x nivel:', listaXNivel);
    this.tarjetas.forEach(t => {
        if(t.titulo.toUpperCase().includes('ALUMNOS') && 
        listaXNivel.filter(e=>e.categoria.toUpperCase().includes(this.sinAcento('ALUMNOS').toUpperCase())).length>0) {
          tarjetasElegidas.push(t);
        }
       
        if(listaXNivel.filter(e=>e.categoria.toUpperCase().includes(this.sinAcento(t.titulo).toUpperCase())).length>0){
          tarjetasElegidas.push(t);
        }
    });
    console.log('tarjetas elegidas:', tarjetasElegidas);
    this.tarjetas = tarjetasElegidas;
  }

  ngOnChanges(): void {
    console.log('se detecto cambios en tarjetas');
   
    this.activatedRoute.paramMap.subscribe(params => {
      this.nivelElegido = params.get('pages');
      console.log('CATEGORIA ELEGIDA:', this.nivelElegido);
       this. filtrarTarjetas();
    });
  }


}
