import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TarjetasVideosComponent } from '../tarjetas-videos/tarjetas-videos.component';
import { Video } from '../../models/video';
import { Location, PlatformLocation, HashLocationStrategy } from '@angular/common';
import { Tarjeta } from '../../data/tarjetas';

@Component({
  selector: 'app-recursos',
  templateUrl: './recursos.component.html',
  styleUrls: ['./recursos.component.scss']
})
export class RecursosComponent implements OnInit {
  @ViewChild(TarjetasVideosComponent) tarjetaVideosComponent: TarjetasVideosComponent;
  listaTarjetas = [];
  tarjetas: any[] = [];
  section: string;
  categoriaElegida: string;
  categorianueva: string;
  listaVideos = [];
  nuevoVideo: string;
  nuevoVideoActivo: boolean;
  videoSeleccionado: Video = new Video();
  seccion: Tarjeta;
  location: Location;
  urlorigin: string;
  pathname: string;
  url: any;
  constructor(platformLocation: PlatformLocation, location: Location, private router: Router, private dataservice: DataService, private activatedRoute: ActivatedRoute) {
    this.section = this.activatedRoute.snapshot.params['page'];
    this.listaTarjetas = this.dataservice.getTarjetasRyA(this.section);
    // this.seccion = this.dataservice.getSeccion(this.section)[0];
    this.seccion = this.dataservice.getTarjeta(this.section);
    // console.log('SECCION CARGADA:', this.seccion);
    // console.log('LISTA SELECCIONADA:', this.listaTarjetas);
    // this.location = location;
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    this.url = this.dataservice.getUrl(this.url);
    // console.log('REC URL ACTUAL + geturl:', this.url);
    // console.log('SE INICIO RECURSOS');
  }

  ngOnInit(): void {
       
  }

  getVideos( cate): void {
    this.categoriaElegida = cate;
    this.listaVideos = this.dataservice.getTarjetasVideos(cate);
    /*
    if (this.tarjetaVideosComponent){
      this.tarjetaVideosComponent.videoElegido = undefined;
    }
    */
    this.videoSeleccionado = new Video();
  }
}
