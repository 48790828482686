

  <div  *ngIf="video" class="card rounded shadow text-center p-0 mb-4">
    <div class="card-header bg-azul text-white d-flex align-items-center py-3" style="height: 100px;">
      <h5 class=""><span class="badge bg-success mx-1"></span>{{video.titulo}}</h5>
    </div>
    <div class="card-body text-white p-0 ">
      <div class="embed-responsive embed-responsive-4by3 ">
        <iframe class="embed-responsive-item" [src]="getObtenerIdVideo(video.url) | safeUrl"></iframe>
      </div>


    </div>
    <div class="card-body text-dark p-0" *ngIf="video.avisos">
      <div class="alert alert-success" *ngIf="video.tituloAviso">
        <h4>Importante: {{video.tituloAviso}}</h4>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Fechas 2024
              </button>
            </h2>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let aviso of video.avisos">{{aviso.detalle}}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body text-white p-0">
      <a type="button" target="_blank" [href]="video.url" class="btn btn-danger my-3 mx-3 border border-white">
        <i class="fa fa-play"></i>
        Ver en Youtube</a>
      <h6 class="card-subtitle mb-2 text-muted"></h6>
    </div>
    <div class="card-footer text-white bg-azul m-0 p-0 py-2">
      <h5>Compartir Video</h5>
      <div class="row p-0 d-flex justify-content-center">
        <share-buttons [theme]="'material-dark'" [include]="['whatsapp']" [show]="1" [size]="9" url="{{video.url}}"
          [autoSetMeta]="false"></share-buttons>
        <share-buttons mat-mini-fab [theme]="'material-dark'" [include]="['email']" [show]="1" [size]="9"
          url="{{video.url}}" [autoSetMeta]="false"></share-buttons>

        <share-buttons [theme]="'material-dark'" [include]="['copy']" [show]="1" [size]="9" url="{{video.url}}"
          [autoSetMeta]="false"></share-buttons>
      </div>
    </div>
  </div>
