<div class="mc-banner-type-c-personas img-fluid"
    style="background-image: url('assets/images/informe1.png'); background-position: -31.56px -102.544px;">
    <div class="bg-color-9 d-flex justify-content-center py-2">
        <div class="row container text-white text-right ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="display-4 font-weight-bold animated fadeIn" >
                Busca a tu consultor facil y rapido
              </div>
                <div class=" animated fadeIn" >
                   <h4>
                    En esta sección podrás buscar a tu consultor por CUE o nombre de la Escuela, tambien podras 
                    ver sus horarios y mandarle un mensaje de WhatsApp
                   </h4>
                </div>
                <a routerLink="/" class="btn rounded-pill botonesInicio m-t-10 font-14">
                    <i class="fas fa-school"></i>
                    Regresar a Inicio
                </a>
            </div>
        </div>
    </div>
</div>
<!--
<app-titulo-separador [separador] ="armarSeparador(' Escuelas y Consultores','fas fa-chalkboard-teacher','azul-violet')"></app-titulo-separador>
-->
<div class="alert m-1">
  <div fxLayout="row wrap" fxLayoutAlign="center center" >
    
    <div class="titulo d-flex justify-content-center celeste text-white" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="100%">
      
      <h1> <i class="fas fa-search iconos m-1"></i>Buscar tu Escuela por CUE o Nombre:</h1>
    </div>
    <div fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="15%" class="m-0 py-1 my-2">
      <mat-form-field class="col-sm-12 col-lg-12 col-xl-12 " appearance="outline">
        <mat-label>Cue</mat-label>
        <input   placeholder="Ingrese cue de la escuela" type="text" matInput
          [formControl]="myControlCue" [matAutocomplete]="autoCue">
        <mat-autocomplete #autoCue="matAutocomplete">
          <mat-option (onSelectionChange)="selectedCue($event, cue)" *ngFor="let cue of filteredoptionsCue | async"
            [value]="cue">
            {{cue}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="85%" class="m-0 py-1">
      <mat-form-field class="col-sm-12 col-lg-12 col-xl-12"  appearance="outline" >
        <mat-label>Nombre Escuela</mat-label>
        <input class="col-xl-12 auto-complete" placeholder="Ingrese Nombre de la Escuela" 
        type="text" matInput
          [formControl]="myControlNombreEscuela" [matAutocomplete]="autoNombre">
          <button *ngIf="habilitarLimpiar" (click)="limpiarNombreInput()" mat-mini-fab class="btn btn-danger btn-icons my-1">
            <h4><i class="fas fa-trash-alt"></i></h4>
          </button> 
        <mat-autocomplete #autoNombre="matAutocomplete">
          <mat-option (onSelectionChange)="selectedNombreEscuela($event, nombre)"
            *ngFor="let nombre of filteredoptionsNombreEscuela | async" [value]="nombre">
            {{nombre}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      
    </div>
    <div *ngIf="false" class="d-flex align-items-center" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="5%" class="m-0 py-1">
      <button (click)="habilitarBorrar()" mat-mini-fab class="violet btn-icons">
        <h4><i class="fas fa-trash-alt"></i></h4>
    </button>
      
    </div>
  </div>
</div>

<div class="row m-0 d-flex justify-content-center text-center" *ngIf="false">
  <div class="alert alert-success col-12">
    <h1 class="display-4"> En unos segundos podras buscar a su consultor</h1>
    <h3> Estamos calculando la matricula de las escuelas y total de matriculas por consultor</h3>
  </div> 
</div>

<div class="alert m-1" *ngIf="false">
  <div fxLayout="row wrap" fxLayoutAlign="center center" >
    
    <div class="titulo col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center celeste text-white" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="100%">
        <h1> <i class="fas fa-search iconos m-1"></i>Buscar a un consultor:</h1>
        <h2>En total contamos con {{listaConsultores.length}} consultores</h2>
    </div>
    
    <div fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="100%" fxFlex.lt-xl="100%" fxFlex.lg="85%" class="m-0 py-1">
      <mat-form-field class="col-sm-12 col-lg-12 col-xl-12 mt-3"  appearance="outline" >
        <mat-label>Ingresar el Nombre o Apellido del Consultor</mat-label>
        <input class="col-xl-12 auto-complete" placeholder="Ingrese Nombre del consultor" type="text" matInput
          [formControl]="myControlNombreConsultor" [matAutocomplete]="autoNombreConsultor">
        
        <mat-autocomplete #autoNombreConsultor="matAutocomplete">
          <mat-option (onSelectionChange)="selectedNombreConsultor($event, consultor)"
            *ngFor="let consultor of filteredoptionsNombreConsultor | async" [value]="consultor.apellido+' '+consultor.nombre">
            {{consultor.apellido}} {{consultor.nombre}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      
    </div>

    
    
  </div>
</div>



<div class="alert alert-danger row m-0 d-flex justify-content-center text-center" role="alert" *ngIf="listaLocalizacionesActivas.length===0">
  <div class="col-12">
    Estamos cargando las Escuelas.. Espere unos segundos
  </div>
  <mat-spinner color="warn">
  </mat-spinner>
  
</div>

<div *ngIf="listaLocalizacionesActivas.length>0" class="alert col-12 text-center text-white azul-violet my-2 mx-0 rounded shadow">
  <h1 class="titulo-matricula text-white">
    <i class="fas fa-school"></i> Escuelas encontradas: {{listaLocalizacionesActivas.length}} 
    
  </h1>
  <button class="btn btn-primary celeste botones-redondos" *ngIf="listaLocalizacionesActivas.length!==listaLocalizacionesActivasCopiaOriginal.length" (click)="verTodos()"> Todas las Escuelas</button>
  </div>
<!--tabla responsiva material-->

  <button class="btn azul-violet botones-redondos" *ngIf="false" mat-raised-button color="primary" 
     (click)="exporter.exportTable('xlsx', {fileName:'escuelasConsultores'} )">Descargar Escuelas en Excel</button>
  
  <div class="example-container mat-elevation-z8 m-1">
    <mat-table [dataSource]="dataSource" matSort matTableExporter  #exporter="matTableExporter">
      <!-- ID Column -->
      <ng-container matColumnDef="cue" >
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-2" *matHeaderCellDef mat-sort-header> CUE </mat-header-cell>
        <mat-cell  class="col-sm-12 col-md-6 col-lg-2" *matCellDef="let ld" data-label="cue"> 
          {{ld.localizacion?.establecimiento?.cue}}{{ld?.localizacion?.anexo}} 
          <button mat-mini-fab (click)="copyToClipboardTexto(ld.localizacion?.establecimiento?.cue+ld?.localizacion?.anexo)" 
          class="m-1 botones-redondos violet" aria-label="copiar">
            <i class="far fa-copy"></i>
          </button>
        </mat-cell>
      </ng-container>
  
     
  
      <!-- Name Column -->
      <ng-container matColumnDef="nombre">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-3" *matHeaderCellDef mat-sort-header> Escuela </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-3" *matCellDef="let ld" data-label="nombre"> {{ld.localizacion?.nombre}}</mat-cell>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="consultor">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-3" *matHeaderCellDef mat-sort-header> Consultor </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-3" *matCellDef="let ld" data-label="consultor"> 
          <div class="row d-flex justify-content-center">
            <div class="alert violet text-white " 
            *ngIf="getConsultorDeConsultoresRA(ld.localizacion?.establecimiento?.cue+ld?.localizacion?.anexo)!=='NO TIENE'">
              {{getConsultorDeConsultoresRA(ld.localizacion?.establecimiento?.cue+ld?.localizacion?.anexo)}}
            </div>
            <button mat-mini-fab color="accent" class="m-1 botones-redondos" 
             (click)="openModal(ld,templateConsultor)"  aria-label="Ver escuela"
             tooltip="Ver Consultor"
          placement="right"
             >
            <i class="fas fa-eye"></i>
          </button> 
            
          </div>
          
            
        </mat-cell>
      </ng-container>
  
      <!-- Color Column -->
      <ng-container matColumnDef="localidad">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-1" *matHeaderCellDef mat-sort-header> Localidad </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-1" *matCellDef="let ld" data-label="localidad"> {{ld.domicilio?.clocalidad?.nombre}} </mat-cell><!--[style.color]="row.color"-->
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="oferta local">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-2" *matHeaderCellDef mat-sort-header> Oferta local </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-2 " *matCellDef="let ld" data-label="oferta local"> 

          <div class="row d-flex justify-content-start ">
            <div class="" *ngFor="let oferta of getOfertaLocalXLocalizacion(ld.localizacion)">
              {{oferta.coferta.ofertaBaseTipo.descripcion}} - {{oferta.coferta.modalidad1Tipo.descripcion}}
            </div> 
          </div>
        </mat-cell>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="sector-region">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-1" *matHeaderCellDef mat-sort-header> Sector - Region </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-1" *matCellDef="let ld" data-label="sector"> 
          {{ld.localizacion.establecimiento?.cSector.descripcion}} - {{ld.localizacion?.codigoJurisdiccional}}
        </mat-cell>
      </ng-container>
      
       <ng-container matColumnDef="region" *ngIf="false" >
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-1"  *matHeaderCellDef mat-sort-header> Region </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-1" *matCellDef="let ld" data-label="region">  </mat-cell>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="opciones" *ngIf="false">
        <mat-header-cell class="col-sm-12 col-md-6 col-lg-1" *matHeaderCellDef mat-sort-header> Opciones </mat-header-cell>
        <mat-cell class="col-sm-12 col-md-6 col-lg-1" *matCellDef="let ld" data-label="opciones"> 
          <button mat-mini-fab color="accent" class="m-1 botones-redondos" 
        
          (click)="elegirEscuela(ld)" aria-label="Ver escuela">
            <i class="fas fa-eye"></i>
          </button> 
          <button mat-mini-fab color="warn" *ngIf="false" (click)="copyToClipboard(ld)" 
          class="m-1 botones-redondos" aria-label="copiar">
            <i class="far fa-copy"></i>
          </button>
        </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row class="mt-2 mb-2" *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <ng-template #templateConsultor>
    <div class=" m-0"  tabindex="-1" role="document">
        <div class="modal-content">
            <div class="modal-header headerModal ">
                <h4 class="modal-title" id="exampleModalLabel">
                  
                    <i class="fas fa-street-view iconosInit"></i>
                    Consultor/a {{consultorElegido?.apellido}} {{consultorElegido?.nombre}} </h4>

                <button type="button" class="btn btn-danger btn-circle pull-right" (click)="decline()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class='card-body text-left text-white p-0' >
                  <div class="row p-0">
                      <div
                          class=" col-sm-12 col-md-12 col-lg-12 col-xl-12 display-5 animated fadeIn rounded  p-2">
                          <p *ngIf="false" class="items-tarjetas shadow rounded sombra violet-header ">
                              <button mat-mini-fab class="violet btn-icons">
                                  <i class="fas fa-user"></i>
                              </button>
                              Apellido y Nombre:
                              
                          </p>
                          <p class="items-tarjetas shadow rounded violet-header">
                              <button mat-mini-fab class="violet btn-icons">
                                <i class="far fa-clock"></i>
                              </button>
                              Horario:
                              {{consultorElegido?.horarioAtencion}}
                          </p>
                         
                          <p *ngIf="consultorElegido?.correo.length>0" 
                            class="items-tarjetas shadow rounded violet-header">
                            <button mat-mini-fab class="violet btn-icons">
                              <i class="far fa-envelope"></i>
                            </button>
                            Correo:
                            
                              {{consultorElegido?.correo}}
                            
                        </p>
                        <p *ngIf="consultorElegido?.correo.length==0" class="items-tarjetas shadow rounded violet-header">
                          <button mat-mini-fab class="violet btn-icons">
                            <i class="far fa-envelope"></i>
                          </button>
                          Correo: NO TIENE CORREO
                        </p>
                        <p class="items-tarjetas my-1 shadow rounded violet-header" >
                          <button mat-mini-fab class="violet btn-icons">
                            <i class="fas fa-mobile-alt"></i>
                          </button>
                          Celular:
                          {{consultorElegido?.celular}} 
                          
                           
                      </p>
                      </div>
                      <div
                      class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 display-5 animated fadeIn rounded  p-2">
                  
                  </div>
               
            
                  </div>
                  <div class="card-body p-0 text-center">
                    <a class="btn rounded-pill botonesInicioVerde m-t-10" 
                    href="https://api.whatsapp.com/send?phone={{getWhatsApp(consultorElegido.celular)}}&text={{mensajeWhatsApp}}" target="_blanck">
                      <img class="imagen" src="assets/images/whatsapp.png" alt="WhatsApp">
                      Enviar un WhatsApp
                    </a>
                  </div>
              </div>
               
            </div>
        </div>
    </div>   
</ng-template>


  
  
  