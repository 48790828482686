import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Localizacion } from '../../models/ra/Localizacion';

@Injectable({
  providedIn: 'root'
})
export class LocalizacionService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'})
  constructor(private http: HttpClient) {

 }
 getLocalizaciones(): Observable<Localizacion[]> {
    return this.http.get<Localizacion[]>(this.urlEndPoint + '/localizaciones');
 }
 getLocalizacionesXCodEstado(codestado: string): Observable<Localizacion[]> {
  return this.http.get<Localizacion[]>(this.urlEndPoint + '/localizaciones/' + codestado);
}
}
