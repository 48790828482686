<div class="container  text-center col-md-12">
    <div class="tarjeta card text-center my-5 ">
        <div class="card-header">
            <h2>ESCUELA NORMAL SUPERIOR</h2>
        </div>
        <div class="card-body row">
            <div class="col-md-4">
                <h5 class="card-title">CUE: 987987987</h5>
            </div>
            <div class="col-md-4">
                <h5 class="card-text">REGION EDUCATIVA N°2</h5>
            </div>
            <div class="col-md-4">
                <h5 class="card-text">NIVEL SECUNDARIO</h5>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <form class="formulario needs-validation " novalidate [formGroup]="form">
        <h1>Datos de Usuario</h1>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="inputApellido">Apellido</label>
                <input [ngClass]="getClassControl(apellido)" type="text" id="inputApellido"
                    placeholder="Ingrese Apellido" formControlName="apellido" name="apellido" class="form-control"
                    required minlength="3">
                <div class="valid-feedback">
                    completo
                </div>
                <div class="invalid-feedback" *ngIf="form.get('apellido')?.errors?.required">
                    es requerido
                </div>
                <div class="invalid-feedback" *ngIf="apellido?.errors?.minlength">
                    mínimo 3 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="apellido?.errors?.maxlength">
                    máximo 20 caracteres
                </div>
            </div>

            <div class="form-group col-md-6">
                <label for="inputNombre">Nombre</label>
                <input [ngClass]="getClassControl(nombre)" type="text" id="inputNombre" placeholder="Ingrese Nombre"
                    formControlName="nombre" name="nombre" class="form-control" required minlength="3">
                <div class="valid-feedback">
                    completo
                </div>
                <div class="invalid-feedback" *ngIf="nombre.errors?.required">
                    es requerido
                </div>
                <div class="invalid-feedback" *ngIf="nombre?.errors?.minlength">
                    mínimo 3 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="nombre?.errors?.maxlength">
                    máximo 20 caracteres
                </div>
            </div>


            <div class="form-group col-md-4">
                <label for="inputCuil">CUIL</label>
                <input [ngClass]="getClassControl(cuil)" type="number" id="inputCuil" placeholder="Ingrese CUIL" formControlName="cuil" name="cuil"
                    class="form-control">
                    <div class="valid-feedback">
                        completo
                    </div>
                    <div class="invalid-feedback" *ngIf="cuil?.errors?.required">
                        es requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="cuil?.errors?.pattern">
                        cuil invalido
                    </div>
            </div>


            <div class="form-group col-md-4">
                <label for="nacimiento">Fecha de nacimiento<small></small></label>
                <input type="date" id="nacimiento" formControlName="nacimiento" name="nacimiento" class="form-control">
            </div>

            <div class="form-group col-md-4">
                <label for="inputCelular">Teléfono Celular</label>
                <input [ngClass]="getClassControl(celular)" type="number" id="inputCelular" placeholder="Ingrese Telefono Celular" formControlName="celular"
                    name="celular" class="form-control">
                    <div class="valid-feedback">
                        completo
                    </div>
                    <div class="invalid-feedback" *ngIf="celular?.errors?.required">
                        es requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="celular?.errors?.pattern">
                        celular invalido
                    </div>
            </div>

            <div class="form-group col-md-6">
                <label for="inputNacionalidad">Nacionalidad</label>
                <input [ngClass]="getClassControl(nacionalidad)" type="text" id="inputNacionalidad" placeholder="Ingrese Nacionalidad"
                    formControlName="nacionalidad" name="nacionalidad" class="form-control">
                    <div class="valid-feedback">
                        completo
                    </div>
                    <div class="invalid-feedback" *ngIf="nacionalidad?.errors?.required">
                        es requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="nacionalidad?.errors?.minlength">
                        mínimo 3 caracteres
                    </div>
                    <div class="invalid-feedback" *ngIf="nacionalidad?.errors?.maxlength">
                        máximo 20 caracteres
                    </div>
            </div>

            <div class="form-group col-md-6">
                <label for="inputCorreo">Correo Electrónico</label>
                <input [ngClass]="getClassControl(email)" type="email" id="inputEmail" placeholder="Ingrese Correo Electronico" formControlName="email"
                    name="email" class="form-control">
                    <div class="valid-feedback">
                        completo
                    </div>
                    <div class="invalid-feedback" *ngIf="email?.errors?.required">
                        es requerido
                    </div>
                    <div class="invalid-feedback" *ngIf="email?.errors?.pattern">
                        e-mail invalido
                    </div>
            </div>

            <!--<div class="form-check col-md-12">
                <label class="form-label">Tipo de Usuario</label>
                <select formControlName="usuario" class="form-select" aria-label="Default select example">
                    <option value="" disabled>Seleccione una opción</option>
                    <option [ngValue]="e" *ngFor="let e of usuarios">{{e.descripcion}}</option>
                </select>
            </div>-->

            <div class="form-group col-md-12">
                <label for=" ">Seleccione Tipo de Usuario:</label>
                <div class="form-group form-check" *ngFor="let c of usuarios;let i=index">
                    <input formControlName="usuario" type="checkbox" [value]="c" (click)="verificar(c)"
                        class="form-check-input" id="exampleCheck{{i}}">
                    <label class="form-check-label" for="exampleCheck1">{{c.descripcion}}</label>
                </div>
            </div>


            <div class="form-group col-md-12 text-center">
                <button type="button" class="boton btn btn-primary mx-3" (click)="guardar()">Guardar</button>
                <button type="button" class="boton btn btn-danger mx-3" (click)="cancelar()">Cancelar</button>
            </div>
        </div>
    </form>

</div>