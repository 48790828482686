import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { SectionComponent } from './section/section.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { RecursosComponent } from './recursos/recursos.component';
import { TarjetasItemsComponent } from './tarjetas-items/tarjetas-items.component';
import { TarjetasVideosComponent } from './tarjetas-videos/tarjetas-videos.component';

import { PagesComponent } from './pages/pages.component';
import { PageAcordionComponent } from './page-acordion/page-acordion.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {FormsModule} from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { DocumentacionComponent } from './documentacion/documentacion.component';
import { DatespipeModule } from '../datespipe/datespipe.module';
import { TarjetasComponent } from './tarjetas/tarjetas.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MaterialModule } from '../material/material.module';
import { ListaDocumentacionComponent } from './lista-documentacion/lista-documentacion.component';
import { FormularioRaComponent } from './formulario-ra/formulario-ra.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { FormComponent } from './form/form.component';
import { FormRaComponent } from './form-ra/form-ra.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { QuienesSomosComponent} from './quienes-somos/quienes-somos.component';
import { UltimoVideoComponent } from './ultimo-video/ultimo-video.component';
import { TarjetasConsultoresComponent } from './tarjetas-consultores/tarjetas-consultores.component';
@NgModule({
  declarations: [DashboardComponent, SectionComponent, ActividadesComponent,
    RecursosComponent, TarjetasItemsComponent, TarjetasVideosComponent,
     PagesComponent, PageAcordionComponent, DocumentacionComponent, TarjetasComponent, 
     ListaDocumentacionComponent, FormularioRaComponent, FormComponent, FormRaComponent, GaleriaComponent,QuienesSomosComponent, UltimoVideoComponent, TarjetasConsultoresComponent],
  imports: [
    CommonModule,
    SharedModule,
    CollapseModule,
    FormsModule,
    AppRoutingModule,
    DatespipeModule,
    ShareButtonsModule.withConfig({}),
    ShareIconsModule,
    MaterialModule,
    TabsModule.forRoot()
  ],
  exports: [DashboardComponent, SectionComponent,  ActividadesComponent, RecursosComponent, TarjetasItemsComponent,
    TarjetasVideosComponent, PagesComponent, PageAcordionComponent, DocumentacionComponent,
    TarjetasComponent, ListaDocumentacionComponent,FormComponent, FormRaComponent,GaleriaComponent,QuienesSomosComponent,UltimoVideoComponent,TarjetasConsultoresComponent]
})
export class DashboardModule { }
