import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosPrincipalesComponent } from './videos-principales/videos-principales.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { DatespipeModule } from '../datespipe/datespipe.module';



@NgModule({
  declarations: [VideosPrincipalesComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    SharedModule,
    DatespipeModule
  ],
  exports: [VideosPrincipalesComponent]
})
export class VideosPrincipalesModule { }
