import { Tarjeta } from './tarjetas';
import { Documentacion } from './documentacion';
import { Video } from './video';
import { Noticia } from '../models/Noticias';
import { Documento } from '../models/Documento';
export const LISTAAVISOS: any[]=[
  {
    id:1,
    titulo: 'Consultas sobre Peso y Talla',
    nombreInstitucion:'Secretaría de Equidad Educativa',
    area: 'Dirección de Plan de Contingencia y Comedores',
    descripcion: 'Fecha: 01/11/2021 a 9/12/21',
    direccion: 'Av 2 de Abril, esq. Av 10 de Junio B° Malvinas',
    celular: 3884580801,
    correo: 'antropometriaescolar@gmail.com',
    telefono:''
  },
  {
    id:2,
    titulo: 'Consultas técnica sobre SINIDE Y RA',
    nombreInstitucion:'Área de Sistemas de Información',
    area: '',
    descripcion: '',
    direccion: 'Ministerio de Educación - Complejo Ministerial',
    celular: 3884580977,
    correo: 'sinidera@sice.jujuy.edu.ar',
    telefono: null
  }
];

export const autoridades: any[] = [
  {
    id:1,
    nombre:'María Teresa Bovi',
    cargo:'Ministra de Educación',
    icono:'street-view'
  },
  {
    id:2,
    nombre:'Rodolfo Jaramillo',
    cargo:'Secretaria de Innovación y Calidad  Educativa',
    icono:'street-view'
  },
  {
    id:3,
    nombre:'Licenciada: Gisela Gutierrez',
    cargo:'Dirección de  Información, Monitoreo y Evaluación Educativa',
    icono:'street-view'
  },
  {
    id:4,
    nombre:'Responsables: Blanca Soto - Nuñes Adrian',
    cargo:'Área de sistema de Información',
    icono:'street-view'
  }

];

export const tarjetasIniciales: Tarjeta[] = [
  {
    id: 1,
    titulo: 'Institucional',
    subtitulo: 'Conozca los pasos iniciales para cargar los datos de la institución, autoridades, etc.',
    imagen: 'institucion.png',
    url: 'docentes.html',
    categoria: 'GENERAL',
    tituloSeccion: 'Institucional',
    descripcion: 'En la sección de datos de institucionales, encontrarán los pasos para cargar datos de las autoridades.',
    items: ['Autoridades']
  },
  {
    id: 2,
    titulo: 'Cargar Alumnos',
    subtitulo: 'Aprenda como agregar alumnos a su Institución, y agregarlos a una sección.',
    imagen: 'alumnos.png',
    url: 'MATRICULACION.html',
    categoria: 'GENERAL',
    tituloSeccion: 'Alumnos',
    descripcion: 'Aprenda como agregar alumnos a su Institución, y agregarlos a una sección.',
    items: [
      'Agregar alumnos Nivel Inicial',
      'Agregar alumnos Nivel Primario',
      'Agrear alumnos a una sección'
    ]
  },
  {
    id: 3,
    titulo: 'Inscripciones',
    subtitulo: 'Aprenda como realizar la inscripción de alumnos preinscriptos a su Institución.',
    imagen: 'inscripcion.png',
    url: 'recursos.html',
    categoria: 'GENERAL',
    tituloSeccion: 'Inscripciones',
    descripcion: 'Aprenda cómo realizar la inscripción de alumnos preinscriptos a su Institución.',
    items: [
      'Inscripción de alumnos por sección',
      'Inscripción de Preinscriptos'
    ]
  },
  {
    id: 4,
    titulo: 'Salidas',
    subtitulo: 'Conozca cómo  gestionar la salidas con pase o sin pase de los alumnos',
    imagen: 'salidas.png',
    url: '',
    categoria: 'GENERAL',
    tituloSeccion: 'Salidas con pase',
    descripcion: 'Conozca cómo gestionar la salidas con pase o sin pase de los alumnos',
    items: [
      'Salidas con pase - sin pase'
    ]
  },
    {
      id: 5,
      titulo: 'Mensajería',
      subtitulo: 'Conozca cómo solicitar la salida de una alumno a otra institución.',
      imagen: 'mensajes.png',
      url: '',
      categoria: 'GENERAL',
      tituloSeccion: 'Mensajería Interna',
      descripcion: 'Conozca cómo  solicitar la salida de una alumno a otra institución.',
      items: [
        'Mensajería Interna'
      ]
    },
    {
      id: 9,
      titulo: 'Promover',
      subtitulo: 'Conozca cómo promover alumnos al ciclo lectivo siguiente.',
      imagen: 'promover.png',
      url: '',
      categoria: 'GENERAL',
      tituloSeccion: 'Mensajería Interna',
      descripcion: 'Conozca cómo promover alumnos al ciclo lectivo siguiente.',
      items: [
        'Promover Alumnos'
      ]
    },
    {
      id: 10,
      titulo: 'Notas',
      subtitulo: 'Conozca cómo cargar la nota de los alumnos de su escuela.',
      imagen: 'notas.png',
      url: '',
      categoria: 'GENERAL',
      tituloSeccion: 'Mensajería Interna',
      descripcion: 'Conozca cómo solicitar promover alumnos al ciclo lectivo siguiente.',
      items: [
        'Promover Alumnos'
      ]
    },
    {
      id: 11,
      titulo: 'Constancias',
      subtitulo: 'Conozca cómo generar constancias de alumno regular.',
      imagen: 'constancias.png',
      url: '',
      categoria: 'GENERAL',
      tituloSeccion: 'Constancias',
      descripcion: 'Conozca cómo generar constancias de alumno regular.',
      items: [
        'Constancias'
      ]
    },
    {
      id: 12,
      titulo: 'Antropométria',
      subtitulo: 'Conozca cómo cargar el peso y la talla, modificar los datos y generar el cuadro nutricional.',
      imagen: 'pesoytalla.png',
      url: '',
      categoria: 'GENERAL',
      tituloSeccion: 'Constancias',
      descripcion: 'Conozca cómo generar constancias de alumno regular.',
      items: [
        'Constancias'
      ]
    }
    ,
    {
      id: 6,
      titulo: 'Nivel Inicial',
      subtitulo: 'Conozca cómo solicitar la salida de una alumno a otra institución.',
      imagen: 'mensajes.png',
      url: '',
      categoria: 'OTRO',
      tituloSeccion: 'Nivel Inicial',
      descripcion: 'En esta sección encontrará todos los videos del Nivel Inicial.',
      items: [

      ]
    }
    ,
    {
      id: 7,
      titulo: 'Nivel Primario',
      subtitulo: 'Conozca cómo solicitar la salida de una alumno a otra institución.',
      imagen: 'mensajes.png',
      url: '',
      categoria: 'OTRO',
      tituloSeccion: 'Nivel Primario',
      descripcion: 'En esta sección encontrará todos los videos del Nivel Primario.',
      items: [

      ]
    },
    {
      id: 8,
      titulo: 'Nivel Secundario',
      subtitulo: 'Conozca como solicitar la salida de una alumno a otra institución.',
      imagen: 'mensajes.png',
      url: '',
      categoria: 'OTRO',
      tituloSeccion: 'Nivel Secundario',
      descripcion: 'En esta sección encontrará todos los videos del Nivel Secundario.',
      items: [

      ]
    },
    {
      id: 9,
      titulo: 'RA',
      subtitulo: 'Conozca como ingresar, cargar titulaciones planes, matriculas y trayectorias.',
      imagen: 'ralogo2.png',
      url: '',
      categoria: 'OTRO',
      tituloSeccion: 'Relevamiento Anual',
      descripcion: 'En esta sección encontrará todos los videos sobre Relevamiento Anual.',
      items: [

      ]
    }

];
export const secciones = [

];
export const tarjetaRecursoActividad = [
    {
      id: 1,
      titulo: 'Etiqueta',
      subtitulo: 'Empleo del recurso Etiqueta para utilizar textos como títulos o inscrustar vídeos.',
      imagen: 'ETIQUETA.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 2,
      titulo: 'Archivo',
      subtitulo: 'Empleo del recurso Archivo dentro del aula virtual.',
      imagen: 'ARCHIVO.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 3,
      titulo: 'Carpeta',
      subtitulo: 'Empleo del recurso Carpeta para agrupar archivos en el aula virtual.',
      imagen: 'CARPETA.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 4,
      titulo: 'Página',
      subtitulo: 'Creación de Páginas personalizadas en el aula virtual.',
      imagen: 'PAGINA.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 5,
      titulo: 'URL',
      subtitulo: 'Empleo del recurso URL para agregar enlaces externos.',
      imagen: 'URL.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 6,
      titulo: 'Libro',
      subtitulo: 'Empleo de recurso Libro para crear material de estudio digital de varias páginas',
      imagen: 'LIBRO.png',
      url: '',
      categoria: 'recurso'
    },
    {
      id: 7,
      titulo: 'Auto-Selección de Grupo',
      subtitulo: 'Crear actividades de Auto-Selección de Grupos de alumnos en nuestro curso',
      imagen: 'AUTO_SELECCION.png',
      url: '',
      categoria: 'actividades'
    },
    {
      id: 8,
      titulo: 'Cuestionario',
      subtitulo: 'Crear actividades denominadas cuestionarios o exámenes',
      imagen: 'CUESTIONARIO.png',
      url: '',
      categoria: 'actividades'
    },
    {
      id: 9,
      titulo: 'Foro',
      subtitulo: 'Cómo construir una Actividad Foro en las Aulas Virtuales de Moodle.',
      imagen: 'FORO.png',
      url: '',
      categoria: 'actividades'
    },
    {
      id: 10,
      titulo: 'Tarea',
      subtitulo: 'Cómo crear Actividades Tareas Individuales o en Grupos en las Aulas Virtuales de Moodle.',
      imagen: 'TAREA.png',
      url: '',
      categoria: 'actividades'
    },
    {
      id: 11,
      titulo: 'Taller',
      subtitulo: 'Cómo crear la Actividad de Taller en las Aulas Virtuales de Moodle.',
      imagen: 'TALLER.png',
      url: '',
      categoria: 'actividades'
    },
    {
      id: 12,
      titulo: 'Lección',
      subtitulo: 'Empleo de la Actividad de Lección en las Aulas Virtuales de Moodle.',
      imagen: 'FORO.png',
      url: '',
      categoria: 'actividades'
    }
  ];

export const videosEstudiantes = [
    {
      id:0,
      titulo: 'buscar la plataforma de UNJu Virtual',
      subtitulo: 'buscar la plataforma de UNJu Virtual en el buscador',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/COMQhH4NMEM',
      categoria: 'ESTUDIANTE'
    },
    {
      id:1,
      titulo: 'Editar mi perfil y cambiar mi foto',
      subtitulo: 'Editar mi perfil y cambiar mi foto en UNJu Virtual',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/hLovDA0TtzU',
      categoria: 'ESTUDIANTE'
    },
    {
      id:2,
      titulo: 'Como recuperar mi usuario y contraseña',
      subtitulo: 'Como recuperar mi usuario y contraseña de UNJu Virtual',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/PlYJ-HwU-go',
      categoria: 'ESTUDIANTE'
    },
    {
      id:3,
      titulo: 'buscar un aula virtual y matricularme',
      subtitulo: 'buscar un aula virtual y matricularme como estudiante - UNJu Virtual',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/uCm_dNjg1Ug',
      categoria: 'ESTUDIANTE'
    },
    {
      id:4,
      titulo: 'como entregar y subir una tarea',
      subtitulo: 'como entregar y subir una tarea al aula virtual',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/guykXMCdkOc',
      categoria: 'ESTUDIANTE'
    },
    {
      id:5,
      titulo: 'Descargar e instalar winrar',
      subtitulo: 'Descargar e instalar winrar para comprimir y descomprimir archivos',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/vQt3EoJraGo',
      categoria: 'ESTUDIANTE'
    },
    {
      id:6,
      titulo: 'Comprimir y descomprimir archivos',
      subtitulo: 'Comprimir y descomprimir archivos en Winrar',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/k7FGDqyM3Is',
      categoria: 'ESTUDIANTE'
    },
    {
      id:7,
      titulo: 'Crear un documento word',
      subtitulo: 'Crear un documento word en Google_Drive.',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/Lycp4fg0hrA',
      categoria: 'Google_Drive,ESTUDIANTES'
    },
    {
      id:8,
      titulo: 'crear un ecuaciones en un documento',
      subtitulo: 'Cómo crear un ecuaciones en un documento en Google_Drive.',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/_68mYEriW-E',
      categoria: 'Google_Drive,ESTUDIANTES'
    },
    {
      id:9,
      titulo: 'crear una hoja de calculo excel',
      subtitulo: 'Cómo crear una hoja de calculo excel en Google_Drive.',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/YnYJRmHzSNM',
      categoria: 'Google_Drive,ESTUDIANTES'
    },
    {
      id:10,
      titulo: 'crear presentaciones en Power Point',
      subtitulo: 'Cómo crear presentaciones en Power Point en Google_Drive.',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/ImQ577-IJYs',
      categoria: 'Google_Drive,ESTUDIANTES'
    },
    {
      id:11,
      titulo: 'Compartir un documento hoja de calculo o una presentación',
      subtitulo: 'Compartir un documento hoja de calculo o una presentacion en Google_Drive.',
      imagen: 'icono.png',
      url: 'https://www.youtube.com/embed/ysxJezS81dk',
      categoria: 'Google_Drive,ESTUDIANTES'
    }

  ];
export const preguntas = [
    {
      pregunta: ' ¿Qué es UNJu Virtual?',
      respuesta: 'Es una plataforma de aprendizaje a distancia (e-learning) basada en software libre (Moodle), que posibilita el desarrollo de un proceso de aprendizaje. Las tecnologías de la información y la comunicación (TIC) permiten que el estudiante acceda al material de estudio y a su vez, interactúe con el profesor y con otros estudiantes en el momento que considere conveniente, sin tener que ajustarse a horarios específicos de cursado, pudiendo además participar desde su computadora personal.',
    url: ''
     },
     {
      pregunta: ' ¿Cómo se accede a la plataforma UNJu Virtual?',
      respuesta: 'A través de cualquier equipo con conexión a Internet y un Navegador Web. Se ingresa en https://virtual.unju.edu.ar con usuario y contraseña.',
    url: ''
     },
     {
      pregunta: ' ¿SIU Guarani es lo mismo que UNJu Virtual?',
      respuesta: 'No. UNJu Virtual es una plataforma educativa en el que se alojan las AV de materias de la UNJu. En tanto que el SIU es un sistema de información universitaria.',
    url: ''
     },
     {
      pregunta: ' Olvidé mi contraseña ¿puedo recuperarla?',
      respuesta: 'Siguiendo los pasos del enlace que dice: ¿Olvidé mi usuario /contraseña. Haciendo click en el mismo le pedirá que ingrese su correo electrónico o su nombre de usuario. Si el usuario o dirección de correo electrónico ingresados son válidos el sistema le enviará un email de confirmación para realizar el cambio de contraseña o un vínculo con acceso por única vez.',
    url: ''

     },
     {
      pregunta: ' Ingreso mi nombre de usuario y contraseña pero no se loguea, ¿Algo está mal? contienen una mezcla de mayúsculas y minúsculas?',
      respuesta: 'Recuerde que las contraseñas poseen mayúsculas, minúsculas, signos, etc. Revise si está escrita de forma exacta.',
    url: ''
     },
     {
      pregunta: ' ¿Cómo cambio los datos de mi perfil?',
      respuesta: 'La primera vez que ingrese en la plataforma será redirigido de manera automática a la página de su perfil. Si quiere editar su perfil en otro momento posterior debe hacer click sobre su nombre en la esquina inferior izquierda de la pantalla donde dice: Usted se ha autentificado como ... o haciendo click en Perfil en el menú de Administración. Luego deberá dirigirse a Editar Perfil.',
    url: ''
     },
     {
      pregunta: '¿Cómo navego entre mis actividades?',
      respuesta: 'Mediante el enlace Mis Cursos del Menú principal. También puede hacer click sobre su nombre de usuario (bloque arriba a la derecha) e ingresar a Área personal',
    url: ''
     },
     {
      pregunta: ' ¿Cómo regreso a la página principal del curso?',
      respuesta: 'Utilice el botón de Atrás (flecha hacia la izquierda) de su navegador o también examinando la lista de páginas visitadas en la barra de herramientas en la parte superior izquierda. Otra forma es utilizando la navegación del sistema (breadcrumb) inmediatamente abajo del Menú Principal',
    url: ''
     },
     {
      pregunta: ' ¿Cómo puedo encontrar el Aula Virtual de una asignatura?',
      respuesta: 'Si no está matriculado al AV, puede buscar por medio del nombre y descripción en el buscador dentro de la plataforma. Si ya está matriculado le aparecerá de manera automática en la página principal bajo el título Área Personal o Mis Cursos.',
    url: ''
     },
     {
      pregunta: ' ¿Quién puede acceder a las Aulas Virtuales de la Plataforma UNJu Virtual?',
      respuesta: 'Toda la comunidad de la UNJu pueden acceder a las Aulas Virtuales, en particular aquellos que desempeñen tareas afines al perfil de cada curso (docentes, estudiantes, tutores, etc.).',
    url: ''
     },
     {
      pregunta: '  ¿Dónde puedo dirigirme si desconozco, he olvidado o no tengo acceso con usuario y contraseña?',
      respuesta: 'Contáctate con el equipo de soporte de UNJu Virtual o sus referentes de unidades académicas.',
    url: ''
     },
     {
      pregunta: '  En el caso de encontrar una incidencia técnica o comprobar un mal funcionamiento de la Plataforma, ¿A quién puedo dirigirme?',
      respuesta: 'Al equipo de UNJu Virtual a través de sus medios de contacto.',
    url: ''
     },
     {
      pregunta: '¿Por qué luego de un tiempo inactivo dentro de la plataforma se corta la conexión?',
      respuesta: 'Se trata de una medida de seguridad que impide dejar nuestra cuentas abiertas a la exposición de un tercero.',
    url: ''
     },
     {
      pregunta: '¿Cuál es el tamaño permitido para los archivos subidos a la plataforma UNJu Virtual?',
      respuesta: 'Por defecto es 50MB pero dependerá de la configuración que haga el profesor sobre su AV.',
    url: ''
     },
     {
      pregunta: ' ¿Son obligatorias las actividades en el Aula Virtual?',
      respuesta: 'Dependerá de las pautas de la cátedra.',
    url: ''
     }
  ];
export const seccionesPrimerosPasos = [
  {
    id: 1,
    titulo: 'Editar primeros Ajustes del Aula virtual en Moodle ',
    contenido: 'En esta parte aprenderemos a editar el formato de nuestra aula virtual para que lo vean los estudiantes, configuraremos nuestra aula en una forma de temas por pestañas. Aprenderás agregar, ocultar, eliminar, mover, duplicar una pestaña. Aprenderemos a utilizar el editor de texto de Moodle es un editor que te será útil más adelante cuando crees recursos o actividades.',
    categoria: 'primeros'
  },
  {
    id: 2,
    titulo: 'Mis Primeros Recurso',
    contenido: 'En esta parte aprenderás a crear tu primera etiqueta que sirve para poner títulos o descripciones en el aula virtual, también sirven para insertar videos que puede compartir con tus estudiantes, podes poner enlaces y demás. Aprenderás a duplicar una etiqueta, mover, ocultar y mostrar.',
    categoria: 'recurso'
  },
  {
    id: 3,
    titulo: 'Matricular a mis Estudiantes',
    contenido: 'En esta parte aprenderemos a realizar matriculaciones manuales tanto de estudiantes u otros profesores de mi aula, crearemos una auto matriculación para que los estudiantes mediante una clave pueden ingresar a tu aula virtual, aprenderás también a desmatricular participantes de tu aula y a extender la fecha de matriculación y enviar mensaje a los estudiantes.',
    categoria: 'matriculacion'
  },
  {
    id: 4,
    titulo: 'Mis primeras Actividades',
    contenido: 'En esta parte aprenderemos a realizar matriculaciones manuales tanto de estudiantes u otros profesores de mi aula, crearemos una auto matriculación para que los estudiantes mediante una clave pueden ingresar a tu aula virtual, aprenderás también a desmatricular participantes de tu aula y a extender la fecha de matriculación y enviar mensaje a los estudiantes.',
    categoria: 'actividad'
  }
];
export const correoDestino = 'cristianballesterospm50@gmail.com';
// export const dominioServer = 'http://190.52.33.51:8082';
export const dominioServer = 'https://formulariodatoscris.000webhostapp.com';
export const endpointPHP = dominioServer + '/mesaayudasinide/files/';
/*
export const correoDestino = 'virtual@unju.edu.ar';
export const dominioServer = 'https://virtual.unju.edu.ar';
export const endpointPHP = dominioServer + '/MesaDeAyudaVirtual/files/';
*/
export const listaSiu = [
  {
    id: 1,
    contacto: 'Ing. Romina Stach – Ing. Alfredo Espinosa',
    correo: 'dptoalumnos@fce.unju.edu.ar   raquel@fce.unju.edu.ar',
    institucion: 'Fac. Economicas',
    url: 'http://www.alumnos.unju.edu.ar/unju/',
  },
  {
    id: 2,
    contacto: 'Ing. Marcela Tapia, Ing.Daniel Chavez',
    correo: 'asuntosestudiantiles@fhycs.unju.edu.ar',
    institucion: 'Fac.Humanidades',
    url: 'http://www.alumnos.unju.edu.ar/unju/',
  },
  {
    id: 3,
    contacto: 'Ing. Virginia Vietazzati',
    correo: 'info@fi.unju.edu.ar',
    institucion: 'Fac. Ingenieria',
    url: 'http://www.alumnos.fi.unju.edu.ar/ingenieria/',
  },
  {
    id: 4,
    contacto: 'Ing. Ezequiel Molina, Ing. Javier Arguello',
    correo: 'direccionalumnos@fca.unju.edu.ar',
    institucion: 'Fac. Agrarias',
    url: 'http://www.alumnos.unju.edu.ar/unju/',
  },
  {
    id: 5,
    contacto: 'Ing. Estela Corimayo',
    correo: 'escuelademinas@gmail.com',
    institucion: 'Esc. de Minas',
    url: 'http://www.preinscripcion.unju.edu.ar/preinscripcion/alumnoscentral/?__o=',
  },
  {
    id: 6,
    contacto: 'APU. Verónica Posadas',
    correo: 'escuelacciasjuridicas@unju.edu.ar',
    institucion: 'Esc. de Ciencias Politicas',
    url: 'https://www.unju.edu.ar/escuela_superior_ciencias_juridicas_politicas.html',
  }
];

export const tarjetasDocumentacion: Documentacion[] = [
  {
    id: 1,
    tarjeta: tarjetasIniciales[1],
    icon: 'fab fa-youtube',
  },
  {
    id: 2,
    tarjeta: tarjetasIniciales[6],
    icon: 'fab fa-youtube',
  },
  {
    id: 1,
    tarjeta: tarjetasIniciales[2],
    icon: 'fab fa-youtube',
  },
  {
    id: 2,
    tarjeta: tarjetasIniciales[3],
    icon: 'fab fa-youtube',
  }
]

export const noticias: Noticia[] =  [
  {
    id:1,
    titulo:'Novedades Nivel Inicial',
    descripcion: 'Buenos dias a todos avisarles que ya quedaron habilitadas las cargas de Notas valorativas para Nivel Secundario  con la aclaracion de que el alumno que tiene como nota  NV (no vinculado o sin viculacion) se consirera como un alumno en APREDIZAJE EN INICIO. Tambien solicitarles que cualquier tipo de correcciones de Plan de estudio  el establecimiento  deben solicitarlo al correo de sinideluajujuy@gmail.com',
    fecha: '8/02/2021'
  },
  {
    id:2,
    titulo:'Novedades Nivel Primario',
    descripcion: 'Buenos dias a todos avisarles que ya quedaron habilitadas las cargas de Notas valorativas para Nivel Secundario  con la aclaracion de que el alumno que tiene como nota  NV (no vinculado o sin viculacion) se consirera como un alumno en APREDIZAJE EN INICIO. Tambien solicitarles que cualquier tipo de correcciones de Plan de estudio  el establecimiento  deben solicitarlo al correo de sinideluajujuy@gmail.com',
    fecha: '8/02/2021'
  },
  {
    id:3,
    titulo:'Novedades Nivel Secundario',
    descripcion: 'Buenos dias a todos avisarles que ya quedaron habilitadas las cargas de Notas valorativas para Nivel Secundario  con la aclaracion de que el alumno que tiene como nota  NV (no vinculado o sin viculacion) se consirera como un alumno en APREDIZAJE EN INICIO. Tambien solicitarles que cualquier tipo de correcciones de Plan de estudio  el establecimiento  deben solicitarlo al correo de sinideluajujuy@gmail.com',
    fecha: '8/02/2021'
  }
];

export const documentosRA: Documento[] = [
  {
    id:0,
    titulo:'RA LINEAMIENTOS BASICOS',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1WEYIW4CUb04CLDUJBqArPSe_wyS8UFkP/view?usp=sharing'
  },
  {
    id:1,
    titulo:'ADULTO',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1SSqV1fcz1UfgkChwdHp45IxBeAC-quGj/view?usp=sharing'
  }
  ,
  {
    id:2,
    titulo:'ARTISTICAS',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1zyZ5ZhImMEPtqHCmiVTuJz04-Bjig8mJ/view?usp=sharing'
  }
  ,
  {
    id:3,
    titulo:'COMUN',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1PqOj1C3GYRA_oKsXbkPc2nxvisbn7QBA/view?usp=sharing'
  }
  ,
  {
    id:4,
    titulo:'DOMICILIARIA',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1jbodaYKxGa4W_SjNEcRzYBwSkHR221W6/view?usp=sharing'
  }
  ,
  {
    id:5,
    titulo:'ESPECIAL',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1tM9cl-3RdcR_sIpHqPaWNTc6iM8cSD4J/view?usp=sharing'
  }
  ,
  {
    id:6,
    titulo:'FORMACION PROFESIONAL',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/14EhBBX-rW09OALKWqW3eyZVwE-Y6dr6h/view?usp=sharing'
  }
  ,
  {
    id:7,
    titulo:'COMPLEMENTARIO',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/17zTpd5yDmb2i3xlQriqNxz8NYpi4vZhT/view?usp=sharing'
  }
  ,
  {
    id:8,
    titulo:'SUPERIOR',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1L65AcZ-5nsKdqE34Pqq-IPyU-3vewRHU/view?usp=sharing'
  }

];
export const documentos: Documento[] = [
  {
    id:-5,
    titulo:'TUTORIAL CARGA DE NOTAS FINALES EDUCACION INCIAL 2022',
    descripcion: 'TUTORIAL CARGA DE NOTAS FINALES EDUCACION INCIAL 2022',
    urlpdf: 'https://drive.google.com/file/d/1vX3OhpCuRyl4ZxyVMTMZQtY0ATCD68tF/view?usp=sharing'
  },
  {
    id:-4,
    titulo:'PLANILLA DE EDICION DE DATOS PERSONALES',
    descripcion: 'PLANILLA DE EDICION DE DATOS PERSONALES',
    urlpdf: 'https://drive.google.com/file/d/1BYWM-QGItcUC2KTb3xnJAaJgaWsU74D6/view?usp=sharing'
  },
  {
    id:-3,
    titulo:'TUTORIAL CARGA DE NOTAS NUMERICAS 2022',
    descripcion: 'TUTORIAL CARGA DE NOTAS NUMERICAS 2022',
    urlpdf: 'https://drive.google.com/file/d/1FGnUrK4pDqRGcXXzCYQUCmc3NnQ0f7dh/view?usp=sharing'
  },
  {
    id:-2,
    titulo:'RA LINEAMIENTOS BASICOS',
    descripcion: 'RA LINEAMIENTOS BASICOS',
    urlpdf: 'https://drive.google.com/file/d/1WEYIW4CUb04CLDUJBqArPSe_wyS8UFkP/view?usp=sharing'
  },
  {
    id:1,
    titulo:'¿Qué es la Antropométria? ',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://drive.google.com/file/d/1YByE7sttcqSGEGRg-PBBPWFKfO2FDR80/view?usp=sharing'
  },
  {
    id:5,
    titulo:'Planilla de datos Personales Alumnos',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://drive.google.com/file/d/1UTRiEOysF1KMzUfqecB0XSITVyohg-Vh/view?usp=sharing'
  },
  {
    id:6,
    titulo:'Planilla de datos Personales Director',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://docs.google.com/document/d/1n78iCZMkgU2ORHzG-SrOrWHQiAIUMoty/view?usp=sharing'
  },
  {
    id:7,
    titulo:'Formulario para usuario y clave',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://drive.google.com/file/d/1uXySs3C9ukxHlKTMjkeeF1h7tywtnUW0/view?usp=sharing'
  },
  {
    id:8,
    titulo:'Convenio de Confidencialidad para Administración',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://drive.google.com/file/d/1MaW8IqRSP6yLb9RlDGyuk7xZBRwcdoee/view?usp=sharing'
  },
  {
    id:9,
    titulo:'Acuerdo de responsabilidad, y uso del correo electronico',
    descripcion: 'descripcion de la noticia',
    urlpdf: 'https://drive.google.com/file/d/102VpSQArqywZvCtplkVCR9y9U54aTvjS/view?usp=sharing'
  }
]
