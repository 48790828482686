import { Domicilio } from "./Domicilio";
import { Localizacion } from './Localizacion';
import { DomicilioTipo } from './DomicilioTipo';

export class LocalizacionDomicilio{
    ctipoDom: DomicilioTipo;
    fechaActualizacion: string;
    domicilio: Domicilio;
    localizacion: Localizacion;
    constructor(){
        this.localizacion = new Localizacion();
        this.domicilio = new Domicilio();
    }
}
