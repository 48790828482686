
<div [ngClass]="getClass()" class=" row py-4 m-0 d-flex justify-content-center flex-wrap text-white text-center rounded shadow">
  <div class="col-sm-12 col-md-6 col-lg-12">
    <h1 >{{titulo}}</h1>
  </div>


  <div class="col-sm-12 col-md-6 col-lg-6 justify-content-center align-items-center text-center flex-wrap">
    <h4 *ngIf="descripcion"> {{descripcion}}</h4>
    <div *ngIf="habilitarBotones" >
      <a href="{{t.url}}" target="_blanck" *ngFor="let t of listaSistemas.slice(1)"
      class='btn botonesInicio  p-2 m-2 col-sm-12 col-md-5 col-lg-6'>
      <img src="assets/images/{{t.imagen}}" width="200px" height="4px" class="sge img-fluid" alt='...'>

    </a>
    </div>

  </div>
</div>
