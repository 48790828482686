import { Component, OnInit } from '@angular/core';
import { listaTodosVideos } from '../../data/data';
import { Router } from '@angular/router';
import { listaCapacitaciones, listaVideosIncripciones } from './videos';
import { videos } from 'src/app/data/videos-sge';

@Component({
  selector: 'app-ultimos-videos',
  templateUrl: './ultimos-videos.component.html',
  styleUrls: ['./ultimos-videos.component.scss']
})
export class UltimosVideosComponent implements OnInit {
  listaVideos: any[]=videos;
  listaVideosCapacitaciones: any[]=listaCapacitaciones;
  listaVideosInscripciones: any[]=listaVideosIncripciones;

  constructor(private router: Router) {
    this.getUltimos3videos();
   }
   getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }

  ngOnInit(): void {
    console.log('lista de videos:', this.listaVideos);
  }
  getUltimos3videos(): void{
    this.listaVideos = this.listaVideos.slice( this.listaVideos.length-3 ,this.listaVideos.length);
    console.log('lista filtrada ultimos 3', this.listaVideos);
  }
  irAPagina(pagina: string): void {
    this.router.navigate([pagina]);
  }
  getClass(categoria: string): string{
    categoria = categoria.toUpperCase();
    if(categoria.includes('INICIAL')){
      return 'azul-violeta'
    }else{
      if(categoria.includes('PRIMARIO')){
        return 'celeste'
      }else{
        if(categoria.includes('SECUNDARIO')){
          return 'rosa-rojo'
        }else{
          return 'bg-primary'
        }
      }
    }

  }


}
