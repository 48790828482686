import { Component, OnInit, TemplateRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Contacto } from '../data/contacto';
import { EmailService } from '../services/email.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import '../../assets/js/smtp.js';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalsComponent } from './modals/modals.component';
import { Tarjeta } from '../data/tarjetas';
import { correoDestino, listaSiu, noticias } from '../data/datos';
import { Noticia } from '../models/Noticias';
import { Documento } from '../models/Documento';
import { listaTodosVideos } from '../data/data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  listaInicial: any[]=[
    {
      nombre: 'web aplication',
      descripcion: '',
      imagen: ''
    },
    {
      nombre: 'cloud hosting',
      descripcion: '',
      imagen: ''
    },
    {
      nombre: 'social app',
      descripcion: '',
      imagen: ''
    },
    {
      nombre: 'smar desing',
      descripcion: '',
      imagen: ''
    }

  ]



  noticiaElegida: Noticia = new Noticia();
  myInterval = 3500;
  slides: { image: string }[] = [];
  activeSlideIndex = 0;

  videoPrincipal: any;
  direccionAssets = '';
  contactForm: FormGroup;
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  content: string;
  modalRef: BsModalRef;
  message: string;
  bsModalRef: BsModalRef;
  
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: false, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-sm modal-md modal-lg'
  };
  listaContactosSiu = listaSiu;
  listaProblemasUsuarios = ['seleccione', 'cambio de correo', 'no me llega correo para restaurar la contraseña',
   'no me llega correo al crear la cuenta'];
  siuElegido: any;
  modals = false;
  Email: any;
  contacto: Contacto = new Contacto();
  listaTarjetas: Tarjeta[] = [];
  tarjetasGenerales: Tarjeta[] = [];
  tarjetasFrecuentes: Tarjeta[] = [];
  facultadSiu = 'selecione';
  problemaUsuario = 'seleccione';
  listaFacultades = ['seleccione','Fac. Economicas','Fac.Humanidades', 'Fac. Ingenieria',
  'Fac. Agrarias', 'Esc. de Minas', 'Esc. de Ciencias Politicas'];
  listaIncovenientes = [ 'seleccione', 'Otros incovenientes o Consultas', 'Creación Aula Virtual', 'Creación de Usuario', 'Acceso a la Plataforma'];
  listaComunicacion = [
    'Correo', 'Celular(me pueden llamar)', 'Celular(me pueden enviar WhatsApp)'
  ];
  opciones: string[] = [];
  listaNoticias: Noticia[] = noticias;
  constructor(private modalService: BsModalService, private http: HttpClient, private email: EmailService, 
    private dataservice: DataService, private router: Router) {
    this.listaTarjetas = dataservice.getTarjetasIniciales();
    this.cargarDatosForm();
  }
  cargarDatosForm(): void {
    this.opciones = [];
    this.contacto = new Contacto();
    this.contactForm = this.crearFormGroup();
  }
  irAPagina(pagina: string): void {
    this.router.navigate([pagina]);
  }
  videoAnteUltimo: any;
  ngOnInit(): void {
    this.selectDefault();
    this.cargarTarjetas();
    this.videoPrincipal= listaTodosVideos[listaTodosVideos.length-1];
    this.videoAnteUltimo= listaTodosVideos[listaTodosVideos.length-2];
  }
  getClass(index: number): string {
    switch (index) {
      case 1:
        return 'azul-violeta';
      case 2:
        return 'celeste';
      default:
        return 'rosa-rojo'
    }
  }
  verificar(c: string): void{
    this.celular.setValue('');
    this.horario.setValue('');
    let pos = this.opciones.indexOf(c);
    if ( pos === -1 ){
      this.opciones.push(c);
  
    }else{
      this.opciones.splice(pos, 1);
    }
    this.contacto.celular = '';
    this.contacto.horario = '';
  }
  selectDefault(): void {
    this.contacto.rol = 'Profesor';
    this.contacto.institucion = this.listaFacultades[0];
    this.contacto.inconveniente = this.listaIncovenientes[0];
  }
  cargarTarjetas(): void{
    this.tarjetasGenerales = this.listaTarjetas.filter(t => t.categoria === 'GENERAL');
    this.tarjetasFrecuentes = this.listaTarjetas.filter(t => t.categoria === 'FRECUENTE');
  }
  cargarPagina(categoria: string): void{
    this.dataservice.setEleccion(categoria);
    let categoriaSinAcentos;
    let pos = categoria.indexOf(' ');
    if (pos !== -1){
      categoriaSinAcentos = categoria.slice(0, pos);
      categoriaSinAcentos = this.dataservice.quitarAcentos(categoriaSinAcentos);
    }else{
      categoriaSinAcentos = this.dataservice.quitarAcentos(categoria);
    }
    if (categoriaSinAcentos.includes('recurso')){
      this.router.navigate(['seccion1', 'recurso']);
    }else{
      if (categoriaSinAcentos.includes('actividad')){
        this.router.navigate(['seccion2', 'actividades']);
      }else{
        if (categoriaSinAcentos.includes('primeros')){
          this.router.navigate(['page', 'primeros']);
        }else{
          this.router.navigate(['seccion', categoriaSinAcentos]);
        }
      }
    }
  }
  visitarPagina(categoria: string): void {
    this.dataservice.setEleccion(categoria);
    if (categoria.includes('preguntas')){
      this.router.navigate(['page', 'preguntas']);
    }else{
      this.router.navigate(['seccion', categoria]);
    }
  }
  irADocumentacion(): void {
    this.router.navigate(['documentacion']);
  }
  
  cancelar(): void{
    // this.contacto = Object.assign({}, this.contactForm.value);
    // console.log(this.contacto);
    // this.revisarDatos();
    this.cargarDatosForm();
    // console.log(this.contacto);
    this.decline();
    // Swal.fire('Cancelada', `no se envio ninguna consulta`, 'error');
  }
  seleccionoCelular(): boolean {
    let lista = this.opciones.filter( e => e.toLowerCase().includes('celular'));
    return lista.length > 0;
  }
  validarInconveniente(): boolean {
    if(this.inconveniente.value !== this.listaIncovenientes[1] ){
      return true;
    }else{
      if(this.consulta.valid){
        return true;
      }else{
        return false;
      }
    }
  }
  mostrarConsulta(): void {
      this.consulta.setValue('');
  }
  validarInputCheck(): boolean {
    if(!this.seleccionoCelular()){
      return true;
    }else{
      if(this.celular.valid && this.horario.valid){
        return true;
      }else{
        return false;
      }
    }
  }

  enviarCorreo(): void {
    this.revisarDatos();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Enviado',
      html: `${this.contacto.nombre} Su consulta fue enviada con exito!! en breve nos comunicaremos con usted.`,
      showConfirmButton: false,
      timer: 1700
    });
    // Swal.fire('Enviada', , 'success');
    this.email.enviarEmail(this.contacto).subscribe( data => {
        if (data['resultado'] === 'OK') {
          // console.log(data);
          this.cargarDatosForm();
          // Swal.fire('Enviada', `Su consulta fue enviada con exito!! estamos trabajando para resolverla.`, 'success');
          this.confirm();
        }else{
          // console.log('no se envio');
          Swal.fire('Cancelada', `no se envio ninguna consulta`, 'error');
        }
      }, (err) => {
        console.error('codigo del error desde el backend: ' + err.status);
        console.error(err.error.errors);
        Swal.fire('Error', `Intente mas tarde`, 'error');
      }
      );
  }

  onSubmit(contactForm: FormGroup): void { // para mandar los datos al servidor se usa el submit para poder mandarlos sino no lo mandara
    this.contacto = contactForm.value;
    this.enviarCorreo();
    // this.revisarDatos();
  }
  onSubmitVar(formUsu: NgForm): void { // para mandar los datos al servidor se usa el submit para poder mandarlos sino no lo mandara
    this.enviarCorreo();
    // this.revisarDatos();
  }
  validarSelect(value: any): boolean {
    return value === 'seleccione';
  }

  revisarDatos(): void{
    if (this.contacto.institucion === 'seleccione' || this.contacto.institucion === undefined){
      this.contacto.institucion = '';
    }
    if (this.contacto.consulta === undefined){
      this.contacto.consulta = '';
    }
    if (this.contacto.celular === undefined){
      this.contacto.celular = '';
    }

    this.contacto.destinatario = correoDestino;
    const fechaActual = new Date();
    this.contacto.fecha = fechaActual.getDate() + '/' + (fechaActual.getMonth() + 1) + '/' + fechaActual.getFullYear();
    this.contacto.fecha += ' a las ' + fechaActual.getHours() + ':' + fechaActual.getMinutes();
    // console.log('CONTACTO ACTUAL:', this.contacto);
  }
  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
  }
 
  decline(): void {
    this.cargarDatosForm();
    this.modalRef.hide();
  }
  openModal(noticia: Noticia,template: TemplateRef<any>) {
    this.noticiaElegida = noticia;
    this.modalRef = this.modalService.show(template, this.config); //  {class: 'modal-md'} podriagregar esto al config tambien
  }
  cambios(): void {
    // console.log(this.facultadSiu);
    this.siuElegido = this.listaContactosSiu.find(e => e.institucion === this.facultadSiu);
    // console.log('cambios');
  }

crearFormGroup(): FormGroup {
  return new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(4)]),
    institucion: new FormControl('', [Validators.required, Validators.minLength(4)]),
    celular: new FormControl('388', [Validators.required, Validators.minLength(9)]),
    consulta: new FormControl('', [Validators.required, Validators.minLength(4)])
  });
}
validarFormularioConsulta(institucion?, rol?, inconveniente?: FormControl): boolean {
  if (institucion && rol && inconveniente){
    return (institucion.value !== 'seleccione' && rol.value !== 'seleccione' && inconveniente.value !== 'seleccione') ? true : false;
  }else{
    return (institucion.value !== 'seleccione' && rol.value !== 'seleccione') ? true : false;
  }
}
validarForm(institucion?, rol?, inconveniente?: FormControl): boolean {
  return (institucion.value !== 'seleccione' && rol.value !== 'seleccione' && inconveniente.value !== 'seleccione') ? true : false;
}
getClassInput(inputForm: FormControl): string{
  return !inputForm?.valid ? 'is-invalid' : 'is-valid';
}
getClassSelect(selectForm: FormControl): string{
  return !(selectForm.value !== 'seleccione' && selectForm?.valid) ? 'is-invalid' : 'is-valid';
}
getClassSelectVar(selectForm: NgForm): string{
  return !(selectForm.value !== 'seleccione' && selectForm?.valid) ? 'is-invalid' : 'is-valid';
}
onResetContact(): void{
  this.contactForm.reset(); // resetea el formulario
}

get nombre() { return this.contactForm.get('nombre'); }
get getEmail() { return this.contactForm.get('email'); }
get dni() { return this.contactForm.get('dni'); }
get institucion() { return this.contactForm.get('institucion'); }
get rol() { return this.contactForm.get('rol'); }
get comunicacion() { return this.contactForm.get('comunicacion'); }
get inconveniente() { return this.contactForm.get('inconveniente'); }
get celular() { return this.contactForm.get('celular'); }
get horario() { return this.contactForm.get('horario'); }
get consulta() { return this.contactForm.get('consulta'); }
}

