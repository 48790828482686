import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Contacto } from '../../data/contacto';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { EmailService } from '../../services/email.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Tarjeta } from '../../data/tarjetas';
@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {
  modalRef: BsModalRef;
  message: string;
  bsModalRef: BsModalRef;

  modals = false;
  Email: any;
  // Email: any;
  contacto: Contacto = new Contacto();
  listaTarjetas: Tarjeta[] = [];
  tarjetasGenerales: any;
  tarjetasFrecuentes: any;

  listaFacultades = ['seleccione','Fac. Economicas','Fac.Humanidades', 'Fac. Ingenieria',
  'Fac. Agrarias', 'Esc. de Minas', 'Esc. de Ciencias Politicas'];
  listaIncovenientes = [ 'Creación Aula Virtual', 'Creación de Usuario', 'Acceso a la Plataforma','Otros incovenientes o Consultas'
  ];
  listaComunicacion = [
    'Correo', 'Celular(me pueden llamar)', 'Celular(me pueden mandar WhatsApp)'
  ];
  opciones: string[] = [];
  constructor(private modalService: BsModalService,private http: HttpClient, private email: EmailService, private dataservice: DataService, private router: Router) {
    this.listaTarjetas = dataservice.getTarjetasIniciales();
  }
 
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }
 

  ngOnInit(): void {
    this.selectDefault();
    this.cargarTarjetas();
  }
  verificar(c: string): void{
    console.log('value:', c);
    let pos = this.opciones.indexOf(c);
    if ( pos === -1 ){
      this.opciones.push(c);
      // console.log('agrego:', this.opciones);
    }else{
      this.opciones.splice(pos, 1);
      //console.log('elimino:', this.opciones);
    }
    this.contacto.celular = '';
    this.contacto.horario = '';
  }
  selectDefault(): void {
    this.contacto.rol = 'Profesor';
    this.contacto.institucion = this.listaFacultades[0];
    this.contacto.inconveniente = this.listaIncovenientes[0];
  }
  cargarTarjetas(): void{
    this.tarjetasGenerales = this.listaTarjetas.filter(t => t.categoria === 'GENERAL');
    this.tarjetasFrecuentes = this.listaTarjetas.filter(t => t.categoria === 'FRECUENTE');
  }
  cargarPagina(categoria: string): void{
    this.dataservice.setEleccion(categoria);
    let categoriaSinAcentos;
    let pos= categoria.indexOf(' ');
    if (pos !== -1){
      categoriaSinAcentos = categoria.slice(0, pos);
      categoriaSinAcentos = this.dataservice.quitarAcentos(categoriaSinAcentos);
    }else{
      categoriaSinAcentos = this.dataservice.quitarAcentos(categoria);
    }
    if (categoriaSinAcentos.includes('recurso')){
      this.router.navigate(['seccion1', 'recurso']);
    }else{
      if (categoriaSinAcentos.includes('actividad')){
        this.router.navigate(['seccion2', 'actividades']);
      }else{
        if (categoriaSinAcentos.includes('primeros')){
          this.router.navigate(['page', 'primeros']);
        }else{
          this.router.navigate(['seccion', categoriaSinAcentos]);
        }
      }
    }
  }
  visitarPagina(categoria: string): void {
    this.dataservice.setEleccion(categoria);
    if (categoria.includes('preguntas')){
      this.router.navigate(['page', 'preguntas']);
    }else{
      this.router.navigate(['seccion', categoria]);
    }
  }
  enviar(): void {
    console.log(this.contacto);
    /*this.email.postEmail('mensaje').subscribe(e => {
      console.log('servicio', e);
    });*/
    //document.formularioDocente.reset();  
    /*
    if (document.formularioDocente.nombre.value.length>0 && document.formularioDocente.email.value.length>0 && document.formularioDocente.mensaje.value.length>0 && document.formularioDocente.dni.value.length==8){
      //let captchResponse = $('#g-recaptcha-response').val();
      // console.log('response:',captchResponse);
      /*
      if(captchResponse.length == 0 ){
        Swal.fire('Error', `debe seleccionar el recaptcha antes de enviar el formulario`, 'error');  
      }else {
       
      }
    
      
      //e.preventDefault();
      setTimeout(() => {
        document.fo
        document.formularioDocente.submit();  
      }, 1000);
    }else{
      if(document.formularioDocente.nombre.value.length==0){
        Swal.fire('Error', `debe ingresar su apellido y nombre`, 'error');  
      }else{
          if(document.formularioDocente.email.value.length==0){
            Swal.fire('Error', `debe ingresar su email`, 'error');  
          }else{
            if(document.formularioDocente.dni.value.length!=8){
              Swal.fire('Error', `debe ingresar su dni longitud de 8`, 'error');  
            }else{
              if(document.formularioDocente.mensaje.value.length==0){
                Swal.fire('Error', `debe ingresar su consulta`, 'error');  
              }
            }
          }
      }
    }*/
    
  }
  cancelar(): void{
    this.contacto = new Contacto();
    this.decline();
    //Swal.fire('Cancelada', `no se envio ninguna consulta`, 'error');
  }
  seleccionoCelular(): boolean {
    let lista = this.opciones.filter( e => e.toLowerCase().includes('celular'));
    return lista.length > 0;
  }

  enviarCorreo(): void {
    this.revisarDatos();
    Swal.fire('Enviada', `Su consulta fue enviada con exito!! estamos trabajando para resolverla.`, 'success');
    this.email.enviarEmail(this.contacto).subscribe( data => {
        if (data['resultado'] === 'OK') {
          console.log(data);
          this.contacto = new Contacto();
          // Swal.fire('Enviada', `Su consulta fue enviada con exito!! estamos trabajando para resolverla.`, 'success');
          this.confirm();
        }else{
          console.log('no se envio');
          Swal.fire('Cancelada', `no se envio ninguna consulta`, 'error');
        }
      }, (err) => {
        console.error('codigo del error desde el backend: ' + err.status);
        console.error(err.error.errors);
        Swal.fire('Error', `Intente mas tarde`, 'error');
      }
      );
  }

  onSubmit(contactForm: NgForm): void {
    this.enviarCorreo();
    // this.revisarDatos();
  }
  
  revisarDatos(): void{
    if (this.opciones.length > 0){
      let cadenaOpc = '';
      this.opciones.forEach( e => {
        cadenaOpc += e + ' | ';
      });
      this.contacto.comunicacion = cadenaOpc;
    }else{
      this.contacto.comunicacion = 'Correo';
    }
    const fechaActual = new Date();
    this.contacto.fecha = fechaActual.getDate() + '/' + fechaActual.getMonth()+1 + '/' + fechaActual.getFullYear();
    this.contacto.fecha += ' a las ' + fechaActual.getHours() + ':' + fechaActual.getMinutes();
    console.log('contacto actual:', this.contacto);
    
  }
  confirm(): void {
    this.message = 'Confirmed!';
    this.modalRef.hide();
  }
 
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }
}
