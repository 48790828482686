import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-reactivo',
  templateUrl: './form-reactivo.component.html',
  styleUrls: ['./form-reactivo.component.scss']
})
export class FormReactivoComponent implements OnInit {
  expRegEmail: any= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  expRegCuil: any= /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/g;
  expRegTel: any= /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
  expRegNac: any= /^(?:3[01]|[12][0-9]|0?[1-9])([\-/.])(0?[1-9]|1[1-2])\1\d{4}$/;

  usuarios: Usuario[] = [
    {
      id: 1,
      descripcion: 'Director'
    },
    {
      id: 2,
      descripcion: 'Preceptor'
    },
    {
      id: 3,
      descripcion: 'Profesor'
    },
    {
      id: 4,
      descripcion: 'Maestro'
    },
    {
      id: 5,
      descripcion: 'Promotor'
    },
    {
      id: 6,
      descripcion: 'Secretario'
    },
  ]
  form: FormGroup;

  opciones: string[] = [];

  constructor() {
    this.cargarDatosForm();
  }

  ngOnInit(): void {
  }

  cargarDatosForm(): void {

    this.form = this.crearFormGroup();
    this.opciones = [];
  }

  crearFormGroup(): FormGroup {
    return new FormGroup({
      apellido: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      nombre: new FormControl('', [Validators.required, Validators.minLength(3),Validators.maxLength(20)]),
      cuil: new FormControl(null,[Validators.required, Validators.pattern(this.expRegCuil)]),
      nacimiento: new FormControl(null),
      nacionalidad: new FormControl('',[Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      celular: new FormControl(null,[Validators.required, Validators.pattern(this.expRegTel)]),
      email: new FormControl('',[Validators.required, Validators.pattern(this.expRegEmail)]),
      usuario: new FormControl(undefined),
    });
  }
  get nombre() { return this.form.get('nombre'); }
  get apellido() { return this.form.get('apellido'); }
  get nacionalidad() { return this.form.get('nacionalidad'); }
  get email() { return this.form.get('email'); }
  get cuil() { return this.form.get('cuil'); }
  get celular() { return this.form.get('celular'); }
  

  getClassControl(control: any): string {
    return control.invalid ? 'is-invalid' : 'is-valid'
  }

  guardar(): void {
    console.log(this.form);
    console.log(this.form.value);
  }

  cancelar(): void {
    this.form.reset();
  }
  verificar(c: string): void {
    let pos = this.opciones.indexOf(c);
    if (pos === -1) {
      this.opciones.push(c);
      console.log('agrego', this.opciones);
    } else {
      this.opciones.splice(pos, 1);
      console.log('elimino', this.opciones);
    }
  }

}
