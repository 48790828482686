

<!--videos de calificaciones-->
<app-seccion
[titulo]="'Nuestros Últimos videos de carga de notas'"
[fondo]="'azul'"
></app-seccion>

<div class="container-fluid">
  <div class="row m-0 d-flex justify-content-center py-5">
    <div class="col-sm-12 col-md-6 col-lg-4 text-center" *ngFor="let video of listaVideosUltimo3Videos;let i=index">
       <app-tarjeta-video
         [video]="video">
        </app-tarjeta-video>
    </div>
  </div>

</div>
