import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DepartamentoTipo } from '../../models/ra/DepartamentoTipo';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'})
  constructor(private http: HttpClient) {

 }
 getDepartamentos(): Observable<DepartamentoTipo[]> {
    return this.http.get<DepartamentoTipo[]>(this.urlEndPoint + '/departamentos');
 }
 getDepartamentosDeJujuy(codprovincia: number): Observable<DepartamentoTipo[]> {
  return this.http.get<DepartamentoTipo[]>(this.urlEndPoint + '/departamentos/provincia/' + codprovincia);
}
}
