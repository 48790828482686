<div class="content">
    <app-section [seccionInput]="seccion"></app-section>
    
    <app-tarjetas *ngIf="habilitarTodasCategorias" [nivelElegido]="categoriaElegida"
     (tipoVideoElegidoEmit)="getTipoVideo($event)"></app-tarjetas>
    <app-tarjetas-videos *ngIf="!habilitarTodasCategorias" 
        (habilitarCategoriasEmit)="getHabilitarCategoria($event)"
        [todasCategorias]="habilitarTodasCategorias"  [lista]="listaVideos" [categoria]="categoriaElegida"
        [videoElegido]="videoSeleccionado">        
    </app-tarjetas-videos>
</div>
