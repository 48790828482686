export const listaCapacitaciones: any[]=[
    {
        id: 1,
        titulo: '1era Capacitación de carga sinide',
        subtitulo: 'Lunes 4 de abril 2022',
        imagen: 'icono.png',
        url: 'https://www.youtube.com/embed/fdWYrlWo4gw',
        observacion: '',
        categoria: 'CAPACITACION'
      }
];

export const listaVideosIncripciones: any[]=[
    {
        id: 1,
        titulo: 'Inscripción de alumnos Preinscriptos',
        subtitulo: 'Inscripción de alumnos Preinscriptos en SINIDE.',
        imagen: 'icono.png',
        url: 'https://www.youtube.com/embed/NCAnhZXUTag',
        observacion: '',

        categoria: 'INSCRIPCIONES, INICIAL,PRIMARIO',
      }
];