import { Component, Input, OnInit } from '@angular/core';
import { videoscap } from 'src/app/data/capacitaciones';
import { videos } from 'src/app/data/videos-sge';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-nuestros-ultimos-videos',
  templateUrl: './nuestros-ultimos-videos.component.html',
  styleUrls: ['./nuestros-ultimos-videos.component.scss']
})
export class NuestrosUltimosVideosComponent implements OnInit {
  @Input() videoElegido: any;
  listaVideosPrincipales = videoscap.sort((a,b)=> b.id - a.id);
  listaVideosUltimo3Videos:any[]=[];
  listaVideosPrincipalesSGe = videos;
  constructor() { }

  ngOnInit(): void {
    this.listaVideosUltimo3Videos=[];

    this.listaVideosUltimo3Videos.push(this.listaVideosPrincipalesSGe[this.listaVideosPrincipalesSGe.length-3]);

    this.listaVideosUltimo3Videos.push(this.listaVideosPrincipalesSGe[this.listaVideosPrincipalesSGe.length-2]);
    this.listaVideosUltimo3Videos.push(this.listaVideosPrincipalesSGe[this.listaVideosPrincipalesSGe.length-1]);
    console.log('ultimos 3 videos:',this.listaVideosUltimo3Videos)
  }
  getClass(video: Video): string {
    return video.id === this.videoElegido.id ? 'bg-dark text-white shadow rounded' : '';
  }

  getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }
  getUrlVideo(urlVideo: string):string{
    return "https://youtu.be/"+urlVideo.slice(30,urlVideo.length);

  }

}
