export class Video {
    id: number;
    titulo: string;
    subtitulo: string;
    imagen: string;
    url: string;
    observacion: string;
    categoria: string;
    orden:number;
    descripcion: string;
}
