import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LocalizacionService } from '../../services/ra/localizacion.service';
import { LocalizacionDomicilioService } from '../../services/ra/localizacion-domicilio.service';
import { DepartamentoService } from '../../services/ra/departamento.service';
import { OfertaLocalService } from '../../services/ra/oferta-local.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DepartamentoTipo } from '../../models/ra/DepartamentoTipo';
import { LocalizacionDomicilio } from '../../models/ra/LocalizacionDomicilio';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { Localizacion } from '../../models/ra/Localizacion';
import { OfertaLocal } from '../../models/ra/OfertaLocal';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { AlumnosService } from '../../services/ra/alumnos.service';
import { Alumnos } from '../../models/ra/Alumnos';
import { consultores, consultoresEscuela } from '../../data/consultores';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { consultoresXEscuela2021 } from '../../data/consultoresXEscuelas2021';

@Component({
  selector: 'app-consultores-escuelas',
  templateUrl: './consultores-escuelas.component.html',
  styleUrls: ['./consultores-escuelas.component.scss']
})
export class ConsultoresEscuelasComponent implements OnInit {
  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  config = {
    backdrop: true, // hace que el fondo aparezca negro
    ignoreBackdropClick: false, // hace que ignore los clic fuera del modals hasta presionar x o disminuir el modal
    class: 'modal-sm modal-md modal-lg'
  };
  listaAlumnosDeEscuela: Alumnos[] = [];
  listaConsultores = consultores;
  listaConsultoresEscuelas= consultoresXEscuela2021;
  
  // material tabla responsiva

  options = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  };

  displayedColumns = ['cue', 'nombre', 'consultor', 'localidad', 'oferta local', 'sector-region'];
  dataSource: MatTableDataSource<LocalizacionDomicilio>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  listaOfertasLocales: OfertaLocal[] = [];
  listaOfertasLocalesEGB: OfertaLocal[] = [];
  listaEscuelasEGB3: LocalizacionDomicilio[] = [];
  listanueva: LocalizacionDomicilio[] = [];
  filteredoptionsCue: Observable<string[]>;
  myControlCue = new FormControl();
  optionsCue: string[] = [];

  filteredoptionsNombreEscuela: Observable<string[]>;
  myControlNombreEscuela = new FormControl();
  optionsNombreEscuela: string[] = [];

  filteredoptionsNombreConsultor: Observable<any[]>;
  myControlNombreConsultor = new FormControl();
  optionsNombreConsultor: any[] = [];


  filteredoptionsDepartamento: Observable<string[]>;
  myControlDepartamento = new FormControl();
  optionsDepartamento: string[] = [];

  filteredoptionsLocalidad: Observable<string[]>;
  myControlLocalidad = new FormControl();
  optionsLocalidad: string[] = [];

  listaDepartamentosJujuy: DepartamentoTipo[] = [];
  nombreInstitucionBus = '';
  listaLocalizacionesActivas: LocalizacionDomicilio[] = [];
  listaLocalizacionesActivasCopiaOriginal: LocalizacionDomicilio[] = [];
  listaCopiaLocalizacionesActivas: LocalizacionDomicilio[] = [];
  localizaciondomicilioElegida: LocalizacionDomicilio = new LocalizacionDomicilio();
  establecimientoFiltro: LocalizacionDomicilio = new LocalizacionDomicilio();
  ambitos = new FormControl();
  listaAmbitos: string[] = [];

  sectores = new FormControl();
  listaSectores: string[] = [];

  regiones = new FormControl();
  listaRegiones: string[] = [];

  ver(): void {
    console.log(this.ambitos.value);
  }

  constructor(private localizacionService: LocalizacionService,
    private localizacionDomicilioService: LocalizacionDomicilioService,
    private router: Router, private departamentoService: DepartamentoService,
    private ofertaLocalService: OfertaLocalService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private alumnosService: AlumnosService,
    private modalService: BsModalService) {

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addbut() {
    window.alert("addbutton");
  }
  editbut() {
    window.alert("editbutton");
  }



  ngOnInit(): void {
    console.log('CONSULTORES:', this.listaConsultores);

    console.log('CONSULTORES escuela:', this.listaConsultoresEscuelas);
    this.cargarConsultoresAutocomplete();
    this.cargarDepartamentosDeJujuy();

    this.cargarSelectAutocomplete();
    
  }
  cargarSelectAutocomplete(): void {
    this.filteredoptionsDepartamento = this.myControlDepartamento.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterDepartamento(value))
      );

    this.filteredoptionsNombreEscuela = this.myControlNombreEscuela.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNombreEscuela(value))
      );
    this.filteredoptionsCue = this.myControlCue.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCue(value))
      );

    this.filteredoptionsNombreConsultor = this.myControlNombreConsultor.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterConsultor(value))
      );
  }

  cargarLocalidadAutocomplete(): void {
    this.filteredoptionsLocalidad = this.myControlLocalidad.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterLocalidad(value))
      );
  }
  private _filterCue(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsCue.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterDepartamento(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsDepartamento.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterLocalidad(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsLocalidad.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterNombreEscuela(value: string): string[] {
    const filterValue = value.toLowerCase();
    // l => l.localizacion.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase())
    // .split('->')[0]
    return this.optionsNombreEscuela.filter(option => option.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filterValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
  }
  private _filterConsultor(value: string): any[] {
    //const filterValue = value.toLowerCase();
    return this.optionsNombreConsultor.filter(option => option.nombre.toLowerCase().includes(value.toLowerCase()) || option.apellido.toLowerCase().includes(value.toLowerCase()));
  }
  buscarInstitucion(): void {
    if (this.nombreInstitucionBus !== '') {
      const palabras = this.nombreInstitucionBus.split(' ').filter(p => p !== '');
      console.log('palabras:', palabras);
      let listanueva = [];
      palabras.forEach(p => {
        let listaResultado = this.listaLocalizacionesActivas.filter(l => l.localizacion.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase()));
        listanueva = listanueva.concat(listaResultado);
        let unico = new Set(listanueva);
        listanueva = [...unico];
      });
      this.listaCopiaLocalizacionesActivas = this.listaLocalizacionesActivas;
      this.listaLocalizacionesActivas = listanueva.slice();
    } else {
      this.listaLocalizacionesActivas = this.listaCopiaLocalizacionesActivas.slice();
      console.log('no se encontro vuelven todas');
    }
  }

  cargarTodasLocalizacionesActivas(): void {
    this.localizacionService.getLocalizacionesXCodEstado('A').subscribe(
      response => {
        // this.listaLocalizacionesActivas = response as Localizacion[];
      });

  }
  verTodos(): void {
    this.matriculaTotalXconsultor = 0;
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal;
    this.listaLocalizacionesActivas.forEach(l => {
      this.matriculaTotalXconsultor += this.getMatriculaAlumnosPorSexoPorEscuela(l.localizacion);
    });
    this.dataSource = new MatTableDataSource(this.listaLocalizacionesActivas);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nombre': return item.localizacion.nombre;
        case 'region': return item.localizacion.codigoJurisdiccional;
        case 'localidad': return item.domicilio.clocalidad.nombre;
        case 'sector': return item.localizacion.establecimiento.cSector.descripcion;
        default: return item[property];
      }
    };
    this.nombreInstitucionBus = '';
    this.listaCopiaLocalizacionesActivas = [];
    this.limpiarNombreInput();
  }
  ngAfterViewInit() {

  }

  cargarTodosAlumnosActivos(): void {
    // A localizaciones activas 1 alumnos x sexo
    this.alumnosService.getAlumnosXLocalizacionEstadoXidAlumnoTipo('A', 1).subscribe(
      response => {
        this.listaAlumnosDeEscuela = (response as Alumnos[]);
        console.log('lista alumnos todos: ', this.listaAlumnosDeEscuela);
      });
  }
  getMatriculaAlumnosPorSexoPorEscuela(localizacion: Localizacion): number {
    let totalMatricula = 0;
    this.listaAlumnosDeEscuela.filter(a => a.seccion.localizacion.idLocalizacion === localizacion.idLocalizacion)
      .forEach(alu => {
        totalMatricula += alu.total;
      });
    return totalMatricula;
  }

  armarSeparador(title: string, icon: string, col: string): any {
    return { titulo: title, icono: icon, color: col };
  }


  getConsultorDeConsultoresRA(cue: string): string {
    let escuela = this.listaConsultoresEscuelas.find(c => c.cue === cue);
    return escuela?.consultor ? escuela.consultor : 'NO TIENE';
  }

  matriculaTotalXconsultor = 0;
  cargarEscuelasPorConsultor(consultor: any): void {
    this.matriculaTotalXconsultor = 0;
    let lista: LocalizacionDomicilio[] = [];
    this.listaLocalizacionesActivas.forEach(ld => {
      if(this.getConsultorDeConsultoresRA(ld.localizacion.establecimiento.cue + ld.localizacion.anexo).toUpperCase().includes(consultor.apellido.toUpperCase())){
        lista.push(ld);
        this.matriculaTotalXconsultor += this.getMatriculaAlumnosPorSexoPorEscuela(ld.localizacion);
      }
    });
    this.listaLocalizacionesActivas = lista;
    console.log('ELEGIR:', this.listaLocalizacionesActivas);

    this.cargarDatasource(this.listaLocalizacionesActivas);
    
  }

  cargarEscuelasNoTienenConsultores(): void {
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivas.filter(ld =>
      this.getConsultorDeConsultoresRA(ld.localizacion?.establecimiento?.cue + ld?.localizacion?.anexo) === 'NO TIENE'
    );
    this.matriculaTotalXconsultor = 0;
    this.listaLocalizacionesActivas.forEach(l => {
      this.matriculaTotalXconsultor += this.getMatriculaAlumnosPorSexoPorEscuela(l.localizacion);
    });
    this.dataSource = new MatTableDataSource(this.listaLocalizacionesActivas);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nombre': return item.localizacion.nombre;
        case 'region': return item.localizacion.codigoJurisdiccional;
        case 'localidad': return item.domicilio.clocalidad.nombre;
        case 'sector': return item.localizacion.establecimiento.cSector.descripcion;
        default: return item[property];
      }
    };
  }
  verTodasEscuelas(): void {
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal;
    this.dataSource = new MatTableDataSource(this.listaLocalizacionesActivas);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nombre': return item.localizacion.nombre;
        case 'region': return item.localizacion.codigoJurisdiccional;
        case 'localidad': return item.domicilio.clocalidad.nombre;
        case 'sector': return item.localizacion.establecimiento.cSector.descripcion;
        default: return item[property];
      }
    };
  }
  mensajeWhatsApp: string = '';
  getWhatsApp(celu: string): string {
    
    this.mensajeWhatsApp = `Estimado/a ${this.consultorElegido.apellido} 
    ${this.consultorElegido.nombre} soy de la ${this.localizaciondomicilioElegida.localizacion.establecimiento.nombre}
   y queria realizarte la siguiente consulta:  ` 
    let pos = celu.indexOf('5'); //es del 15 obtento el 5
    return '+549388' + celu.substring(pos + 1);
  }

  cargarLocalizacionesDomicilioActivos(): void {
    this.localizacionDomicilioService.getLocalizacionesDomiciliosXLocalizacionCodEstadoTipoDomicilio('A', 1).subscribe(
      response => {

        this.listaLocalizacionesActivas = (response as LocalizacionDomicilio[]);
        this.listaLocalizacionesActivasCopiaOriginal = this.listaLocalizacionesActivas.slice();
        console.log('localizaciones domicilio:', this.listaLocalizacionesActivas);

        this.cargarTodosAlumnosActivos();
        //cargamos la tabla material
        this.cargarDatasource(this.listaLocalizacionesActivas);
        this.cargarAmbitos();
        this.cargarSectores();
        this.cargarRegiones();
        this.cargarOfertasLocales();
      });
  }
  cargarDatasource(lista: LocalizacionDomicilio[]):void{
    this.dataSource = new MatTableDataSource(lista);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'nombre': return item.localizacion.nombre;
            case 'region': return item.localizacion.codigoJurisdiccional;
            case 'localidad': return item.domicilio.clocalidad.nombre;
            case 'sector': return item.localizacion.establecimiento.cSector.descripcion;
            default: return item[property];
          }
        };
  }
  cargarAmbitos(): void {
    this.listaAmbitos = [];
    this.listaLocalizacionesActivas.forEach(l => {
      if (!this.listaAmbitos.includes(l.localizacion.cAmbito.descripcion)) {
        this.listaAmbitos.push(l.localizacion.cAmbito.descripcion);
      }
    });
    console.log('ambitos', this.listaAmbitos);
  }
  cargarSectores(): void {
    this.listaSectores = [];
    this.listaLocalizacionesActivas.forEach(l => {
      if (!this.listaSectores.includes(l.localizacion.establecimiento.cSector.descripcion)) {
        this.listaSectores.push(l.localizacion.establecimiento.cSector.descripcion);
      }
    });
    console.log('sectores', this.listaSectores);
  }

  cargarRegiones(): void {
    this.listaRegiones = [];
    this.listaLocalizacionesActivas.forEach(l => {
      if (!this.listaRegiones.includes(l.localizacion.codigoJurisdiccional)) {
        this.listaRegiones.push(l.localizacion.codigoJurisdiccional);
      }
    });
    console.log('regiones', this.listaRegiones);
  }
  cargarNombresDeEscuela(): void {
    this.optionsNombreEscuela = [];
    this.optionsCue = [];
    this.listaLocalizacionesActivas.forEach(l => {
      if (!this.optionsNombreEscuela.includes(l.localizacion.establecimiento.nombre)) {
        let ofertaLocalDeLocalizacion: OfertaLocal[] = this.getOfertaLocalXLocalizacion(l.localizacion);
        let ofertasString = '';
        ofertaLocalDeLocalizacion.forEach(ofer => {
          ofertasString += ofer.coferta.ofertaBaseTipo.descripcion + ' - ';
        });
        this.optionsNombreEscuela.push(l.localizacion.establecimiento.nombre + '->' +
          l.domicilio.clocalidad.nombre + '->' + ofertasString);
        this.optionsCue.push(l.localizacion.establecimiento.cue + l.localizacion.anexo);
      }
    });
  }

  cargarLocalidades(): void {
    this.optionsLocalidad = [];
    this.listaLocalizacionesActivas.forEach(l => {
      if (!this.optionsLocalidad.includes(l.domicilio.clocalidad.nombre)) {
        this.optionsLocalidad.push(l.domicilio.clocalidad.nombre);
      }
    });
    console.log('optionlocalidades', this.optionsLocalidad);
  }
  elegirEscuela(ld: LocalizacionDomicilio): void {
    this.router.navigate(['establecimiento/institucion', ld.localizacion.idLocalizacion]);
    this.localizaciondomicilioElegida = ld;
  }

  cargarDepartamentosDeJujuy(): void {
    this.departamentoService.getDepartamentosDeJujuy(38).subscribe(
      response => {
        this.listaDepartamentosJujuy = response as DepartamentoTipo[];
        this.optionsDepartamento = [];
        //console.log('departamentos:', this.listaDepartamentosJujuy);
        this.listaDepartamentosJujuy.forEach(e => {
          this.optionsDepartamento.push(e.nombre);
        });

        this.cargarLocalizacionesDomicilioActivos();
      });
  }
  cargarConsultoresAutocomplete(): void {
    this.listaConsultores.forEach(c => {
      this.optionsNombreConsultor.push(c);
    });
  }
  cargarOfertasLocales(): void {
    this.ofertaLocalService.getOfertaLocalXCodEstado('A').subscribe(
      response => {
        console.log('oferta local:', response);
        this.listaOfertasLocales = response as OfertaLocal[];
        this.cargarNombresDeEscuela();
        /*
        this.listaOfertasLocalesEGB = (response as OfertaLocal[]).filter(oferta => oferta.coferta.descripcion.toUpperCase().includes('EGB 3'));
        console.log('escuelas egb', this.listaOfertasLocalesEGB);
        this.listaOfertasLocalesEGB.forEach(oferta=>{
          this.listaEscuelasEGB3.push(this.getLocalizacioDomicilio(oferta.localizacion.idLocalizacion))
        });
        console.log('lista escuelas egb', this.listaEscuelasEGB3);
        */
      });
  }
  getLocalizacioDomicilio(id: number): LocalizacionDomicilio {
    return this.listaLocalizacionesActivas.filter(e => e.localizacion.idLocalizacion === id)[0];
  }
  getOfertaLocalXLocalizacion(l: Localizacion): OfertaLocal[] {
    let listaFiltrada = this.listaOfertasLocales.filter(o => o.localizacion.idLocalizacion === l.idLocalizacion);
    return listaFiltrada;
  }
  getOfertaLocalXLocalizacion2(id: number): OfertaLocal[] {
    return this.listaOfertasLocales.filter(o => o.localizacion.idLocalizacion === id);
  }
  cargarLocalidadesDelDepartamento(nombreDepa: string): void {
    this.optionsLocalidad = [];
    let listaFiltrada: LocalizacionDomicilio[] = [];
    listaFiltrada = this.listaLocalizacionesActivas.filter(l => l.domicilio.clocalidad.departamentoTipo.nombre.toUpperCase().includes(nombreDepa.toUpperCase()));
    listaFiltrada.forEach(l => {
      if (!this.optionsLocalidad.includes(l.domicilio.clocalidad.nombre)) {
        this.optionsLocalidad.push(l.domicilio.clocalidad.nombre);
      }
    })
    this.cargarLocalidadAutocomplete();
    console.log('optionlocalidades del depa', this.optionsLocalidad);
  }

  selectedDepartamento(event: any, nombreDepa: string): void {
    if (event.source.selected) {
      this.myControlLocalidad = new FormControl();
      console.log('DEPA ELEGIDO', nombreDepa);
      this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal;
      this.cargarLocalidadesDelDepartamento(nombreDepa);
      this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre = nombreDepa;
      //this.departamentoElegido = this.listaDepartamentosJujuy.filter(e => e.nombre.toUpperCase().includes(nombreDepa.toUpperCase()))[0];
      //console.log(this.departamentoElegido);
      //this.elegirDepartamento(this.departamentoElegido);
      //Swal.fire({ position: 'center', icon: 'success', title: `Estas en ${nombreDepa}`,
      //showConfirmButton: false, timer: 1500 });
    }
  }

  selectedLocalidad(event: any, nombreLoca: string): void {
    if (event.source.selected) {
      console.log(nombreLoca);
      this.establecimientoFiltro.domicilio.clocalidad.nombre = nombreLoca;
      //this.departamentoElegido = this.listaDepartamentosJujuy.filter(e => e.nombre.toUpperCase().includes(nombreDepa.toUpperCase()))[0];
      //console.log(this.departamentoElegido);
      //this.elegirDepartamento(this.departamentoElegido);

    }
  }
  habilitarLimpiar:boolean = false;
  limpiarNombreInput():void{
    this.habilitarLimpiar=false;
    console.log(this.myControlNombreEscuela.value);
    this.myControlNombreEscuela = new FormControl();
    this.myControlCue = new FormControl();
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal;
    this.listaCopiaLocalizacionesActivas = [];
    this.cargarNombresDeEscuela();
    this.cargarSelectAutocomplete();
    this.cargarDatasource(this.listaLocalizacionesActivas);
  }
  habilitarBorrar(){
    console.log(this.myControlNombreEscuela.value);
    //if(this.myControlNombreEscuela.value !== null && this.myControlNombreEscuela.value.length>0){
      this.habilitarLimpiar = true;
    //}
  }
  selectedNombreEscuela(event: any, nombreEscuela: string): void {
    if (event.source.selected) {
      console.log(nombreEscuela);
      this.establecimientoFiltro.localizacion.establecimiento.nombre = nombreEscuela;
      this.filtrarCueNombre(nombreEscuela.split('->')[0], 'nombre');
      this.habilitarBorrar();
      //console.log('nombre elegido:', nombreEscuela.split('->')[0]);
      //this.departamentoElegido = this.listaDepartamentosJujuy.filter(e => e.nombre.toUpperCase().includes(nombreDepa.toUpperCase()))[0];
      //console.log(this.departamentoElegido);
      //this.elegirDepartamento(this.departamentoElegido);
      Swal.fire({
        position: 'center', icon: 'success', title: `Elegiste ${nombreEscuela.split('->')[0]}`,
        showConfirmButton: false, timer: 2000
      });
    }
  }

  filtrarCueNombre(value: string, nombreFiltro: string): void {
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal.slice();
    let listaNueva: LocalizacionDomicilio[] = [];
    this.listaCopiaLocalizacionesActivas = this.listaLocalizacionesActivas;
    switch (nombreFiltro.toUpperCase()) {
      case 'CUE':
        listaNueva = this.listaLocalizacionesActivas.filter(l => (l.localizacion.establecimiento.cue + l.localizacion.anexo).trim().includes(value.trim()))
        break;
      case 'NOMBRE':
        listaNueva = this.listaLocalizacionesActivas.filter(l => l.localizacion.establecimiento.nombre.trim().includes(value.trim()))
        break;
    }
    if (listaNueva.length > 0) {
      this.listaLocalizacionesActivas = listaNueva.slice();
      this.dataSource = new MatTableDataSource(this.listaLocalizacionesActivas);
    }
  }

  consultorElegido: any;
  selectedNombreConsultor(event: any, consultor: any): void {

    if (event.source.selected) {
      this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal.slice();
      this.consultorElegido = consultor;
      console.log(consultor);
      this.cargarEscuelasPorConsultor(consultor);
      Swal.fire({
        position: 'center', icon: 'success', title: `Consultor Elegido ${consultor.apellido} ${consultor.nombre}`,
        showConfirmButton: false, timer: 2000
      });
    }
  }
  
  decline(): void {
    this.modalRef.hide();
  }
  getConsultorRA(cue: string): any {
    let escuela = this.listaConsultoresEscuelas.find(c => c.cue === cue);
    return escuela.consultor;
  }
  openModal(ld: LocalizacionDomicilio,template: TemplateRef<any>) {
    let nombreConsultor: string = this.getConsultorRA(ld.localizacion?.establecimiento?.cue+ld?.localizacion?.anexo);
    this.consultorElegido= this.listaConsultores.filter( c => nombreConsultor.toUpperCase().includes(c.apellido.toUpperCase())
      )[0];
    console.log('CONSULTOR ELEGIDO:'+this.consultorElegido);
    this.modalRef = this.modalService.show(template, this.config); //  {class: 'modal-md'} podriagregar esto al config tambien
    this.localizaciondomicilioElegida = ld;
  }
  selectedCue(event: any, cue: string): void {
    if (event.source.selected) {
      console.log(cue);
      this.establecimientoFiltro.localizacion.establecimiento.cue = cue;
      this.filtrarCueNombre(cue, 'cue');
      //this.limpiarNombreInput();
      //this.departamentoElegido = this.listaDepartamentosJujuy.filter(e => e.nombre.toUpperCase().includes(nombreDepa.toUpperCase()))[0];
      //console.log(this.departamentoElegido);
      //this.elegirDepartamento(this.departamentoElegido);
      Swal.fire({
        position: 'center', icon: 'success', title: `Estas en ${cue}`,
        showConfirmButton: false, timer: 2000
      });
    }
  }
  
  validarCamposDebusqueda(): boolean {
    let validarDepa = this.myControlDepartamento.value !== null && this.myControlDepartamento.value !== '';
    let validarNombre = this.establecimientoFiltro.localizacion.establecimiento.nombre !== undefined && this.establecimientoFiltro.localizacion.establecimiento.nombre !== '';
    let validarLocaliad = this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre !== null && this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre !== '';
    let validarRegion = false;
    if (this.regiones.value !== null) {
      validarRegion = true;
      if ((this.regiones.value as any[]).length > 0) {
        validarRegion = true;
      } else {
        validarRegion = false;
      }
    }
    let validarSector = false;
    if (this.sectores.value !== null) {
      validarSector = true;
      if ((this.sectores.value as any[]).length > 0) {
        validarSector = true;
      } else {
        validarSector = false;
      }
    }

    let validarAmbito = false;
    if (this.ambitos.value !== null) {
      validarAmbito = true;
      if ((this.ambitos.value as any[]).length > 0) {
        validarAmbito = true;
      } else {
        validarAmbito = false;
      }
    }

    return validarNombre || validarDepa || validarRegion || validarSector || validarAmbito || validarLocaliad;
  }
  buscarEscuelas(): void {
    console.log(this.establecimientoFiltro);
    this.listanueva = [];
    let unico: any;
    let bandera = false;
    this.listaLocalizacionesActivas = this.listaLocalizacionesActivasCopiaOriginal;
    if (this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre !== '' && this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre !== null && this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre !== undefined) {
      if (bandera == false) {
        this.listanueva = this.listanueva.concat(this.listaLocalizacionesActivas.filter(l => l.domicilio.clocalidad.departamentoTipo.nombre.toUpperCase().includes(this.establecimientoFiltro.domicilio.clocalidad.departamentoTipo.nombre.toUpperCase())));
        console.log('entro a depa', this.listanueva);
        // elimino escuelas repetidas

        if (this.listanueva.length > 0) {
          bandera = true;
        }
      }
    }

    if (this.establecimientoFiltro.domicilio.clocalidad.nombre !== '' && this.establecimientoFiltro.domicilio.clocalidad.nombre !== null && this.establecimientoFiltro.domicilio.clocalidad.nombre !== undefined) {
      let listaResultadoTotal = [];
      if (bandera == false) {
        this.listanueva = this.listanueva.concat(this.listaLocalizacionesActivas.filter(l => l.domicilio.clocalidad.nombre.toUpperCase().includes(this.establecimientoFiltro.domicilio.clocalidad.nombre.toUpperCase())));
        console.log('entro a localidad', this.listanueva);
        // elimino escuelas repetidas
        if (this.listanueva.length > 0) {
          bandera = true;
        }
      } else {
        listaResultadoTotal = listaResultadoTotal.concat(this.listanueva.filter(l => l.domicilio.clocalidad.nombre.toUpperCase().includes(this.establecimientoFiltro.domicilio.clocalidad.nombre.toUpperCase())));
        console.log('lista resultados', listaResultadoTotal);
        this.listanueva = listaResultadoTotal;
        console.log('entro localidad  else', this.listanueva);
      }
    }

    if (this.sectores.value) {
      let listasectoresElegidos = this.sectores.value as any[];
      if (listasectoresElegidos.length > 0) {
        // console.log(this.ambitos.value);
        console.log('lista listasectoresElegidos', listasectoresElegidos);
        let listaResultadoTotal = [];
        if (bandera == false) {
          listasectoresElegidos.forEach(p => {
            let listaResultado = this.listaLocalizacionesActivas.filter(l => l.localizacion.establecimiento.cSector.descripcion.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          this.listanueva = this.listanueva.concat(listaResultadoTotal);
          console.log('entro a sectores if', this.listanueva);
          if (this.listanueva.length > 0) {
            bandera = true;
          }
        } else {
          listasectoresElegidos.forEach(p => {
            let listaResultado = this.listanueva.filter(l => l.localizacion.establecimiento.cSector.descripcion.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          console.log('lista resultados sectores', listaResultadoTotal);
          this.listanueva = listaResultadoTotal;
          console.log('entro secotres else', this.listanueva);
        }
      } else {
        console.log('no tiene sectores elegidos');
      }
    } else {
      console.log(this.sectores);
      console.log('no tieene sectores');
    }

    if (this.regiones.value) {
      let listasRegionesElegidos = this.regiones.value as any[];
      if (listasRegionesElegidos.length > 0) {
        // console.log(this.ambitos.value);
        console.log('lista listasRegionesElegidos', listasRegionesElegidos);
        let listaResultadoTotal = [];
        if (bandera == false) {
          listasRegionesElegidos.forEach(p => {
            let listaResultado = this.listaLocalizacionesActivas.filter(l => l.localizacion.codigoJurisdiccional.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          this.listanueva = this.listanueva.concat(listaResultadoTotal);
          console.log('entro a regiones if', this.listanueva);
          if (this.listanueva.length > 0) {
            bandera = true;
          }
        } else {
          listasRegionesElegidos.forEach(p => {
            let listaResultado = this.listanueva.filter(l => l.localizacion.codigoJurisdiccional.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          console.log('lista resultados reg', listaResultadoTotal);
          this.listanueva = listaResultadoTotal;
          console.log('entro reg else', this.listanueva);
        }
      } else {
        console.log('no tiene reg elegidos');
      }
    } else {
      console.log(this.regiones);
      console.log('no tieene reg');
    }

    if (this.ambitos.value) {
      let listaAmbitosElegidos = this.ambitos.value as any[];
      if (listaAmbitosElegidos.length > 0) {
        // console.log(this.ambitos.value);
        console.log('lista ambitos', listaAmbitosElegidos);
        let listaResultadoTotal = [];
        if (bandera == false) {
          listaAmbitosElegidos.forEach(p => {
            let listaResultado = this.listaLocalizacionesActivas.filter(l => l.localizacion.cAmbito.descripcion.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          this.listanueva = this.listanueva.concat(listaResultadoTotal);
          console.log('entro a ambitos if', this.listanueva);
          if (this.listanueva.length > 0) {
            bandera = true;
          }
        } else {
          listaAmbitosElegidos.forEach(p => {
            let listaResultado = this.listanueva.filter(l => l.localizacion.cAmbito.descripcion.toUpperCase().includes(p.toUpperCase()));
            listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
          });
          console.log('lista resultados ambitos', listaResultadoTotal);
          this.listanueva = listaResultadoTotal;
          console.log('entro anombre else', this.listanueva);
        }
      } else {
        console.log('no tiene ambito elegidos');
      }
    } else {
      console.log(this.ambitos);
      console.log('no tieene ambitos');
    }

    if (this.establecimientoFiltro.localizacion.establecimiento.nombre !== '' && this.establecimientoFiltro.localizacion.establecimiento.nombre !== null && this.establecimientoFiltro.localizacion.establecimiento.nombre !== undefined) {
      let listaResultadoTotal = [];
      if (bandera == false) {
        const palabras = this.establecimientoFiltro.localizacion.establecimiento.nombre.split(' ').filter(p => p !== '');
        console.log(palabras);
        palabras.forEach(p => {
          let listaResultado = this.listaLocalizacionesActivas.filter(l => l.localizacion.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase()));
          listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
        });
        this.listanueva = this.listanueva.concat(listaResultadoTotal);
        console.log('entro a nombre if', this.listanueva);
        if (this.listanueva.length > 0) {
          bandera = true;
        }
      } else {
        const palabras = this.establecimientoFiltro.localizacion.establecimiento.nombre.split(' ').filter(p => p !== '');
        console.log(palabras);
        palabras.forEach(p => {
          let listaResultado = this.listanueva.filter(l => l.localizacion.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(p.toUpperCase()));
          listaResultadoTotal = listaResultadoTotal.concat(listaResultado);
        });
        console.log('lista resultados', listaResultadoTotal);
        this.listanueva = listaResultadoTotal;
        console.log('entro anombre else', this.listanueva);
      }
    }

    // elimino escuelas repetidas
    unico = new Set(this.listanueva);
    this.listanueva = [...unico];
    this.listaLocalizacionesActivas = this.listanueva.slice();
    console.log('RESULTADOS:', this.listaLocalizacionesActivas);
    this.dataSource = new MatTableDataSource(this.listaLocalizacionesActivas);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nombre': return item.localizacion.nombre;
        case 'region': return item.localizacion.codigoJurisdiccional;
        case 'localidad': return item.domicilio.clocalidad.nombre;
        case 'sector': return item.localizacion.establecimiento.cSector.descripcion;
        default: return item[property];
      }
    };
    Swal.fire({
      position: 'center', icon: 'success', title: `Encontramos ${this.listaLocalizacionesActivas.length} Escuelas`,
      showConfirmButton: false, timer: 2000
    });
  }
  reiniciarBusquedas(): void {

  }

  copyToClipboard(escuela: LocalizacionDomicilio): void {
    // Se copia el texto del input al portapapeles
    let text = '';
    text += escuela.localizacion.establecimiento.cue + ' - ' + escuela.localizacion.codigoJurisdiccional + ' - ' + escuela.localizacion.nombre;
    this.clipboard.copy(text);

    // Se muestra un snackbar durante 2 segundos en la parte inferior
    this.snackBar.open('¡Info de la escuela copiado al portapapeles!', null, {
      duration: 2000,
      panelClass: 'snackbar'
    });
  }
  copyToClipboardTexto(texto: string): void {
    // Se copia el texto del input al portapapeles
    this.clipboard.copy(texto);
    // Se muestra un snackbar durante 2 segundos en la parte inferior
    this.snackBar.open(texto + ' copiado al portapapeles!', null, {
      duration: 2000,
      panelClass: 'snackbar'
    });
  }
}
