import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // direccionAssets = '../../../';
  direccionAssets = '';
  isCollapsed = true;
  constructor(private dataservice: DataService,private router: Router) { }

  ngOnInit(): void {
  }
  cargarPagina(categoria: string): void{
    this.dataservice.setEleccion(categoria);
    this.tiempoYColapsar();
  }
  tiempoYColapsar():void{
    setTimeout(() => {
      this.isCollapsed = true;  
    }, 1500);
  }

}
