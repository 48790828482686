import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EmailService } from '../services/email.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalsComponent } from './modals/modals.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DatespipeModule } from '../datespipe/datespipe.module';
import { ComponentsModule } from '../components/components.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PresentacionComponent } from './presentacion/presentacion.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NivelesComponent } from './niveles/niveles.component';
import { MaterialModule } from '../material/material.module';
import { UltimosVideosComponent } from './ultimos-videos/ultimos-videos.component';
import { BuscarMiConsultorComponent } from './buscar-mi-consultor/buscar-mi-consultor.component';
import { NoticiasImportantesComponent } from './noticias-importantes/noticias-importantes.component';
import { SeccionVideosPrincipalesComponent } from './seccion-videos-principales/seccion-videos-principales.component';
import { FormReactivoComponent } from './form-reactivo/form-reactivo.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { DashboardModule } from '../dashboard/dashboard.module';
import { LogoSecretariaComponent } from './logo-secretaria/logo-secretaria.component';
import { NuestrosUltimosVideosComponent } from './nuestros-ultimos-videos/nuestros-ultimos-videos.component';
import { NuestrosUltimasCapacitacionesComponent } from './nuestros-ultimas-capacitaciones/nuestros-ultimas-capacitaciones.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ComoLlegarComponent } from './como-llegar/como-llegar.component';
import { SeccionVideosCalificacionesComponent } from './seccion-videos-calificaciones/seccion-videos-calificaciones.component';

@NgModule({
  declarations: [HomeComponent, ModalsComponent, PresentacionComponent,
    NivelesComponent, UltimosVideosComponent, BuscarMiConsultorComponent, NoticiasImportantesComponent, SeccionVideosPrincipalesComponent, FormReactivoComponent, LogoSecretariaComponent, NuestrosUltimosVideosComponent, NuestrosUltimasCapacitacionesComponent, ContactoComponent, ComoLlegarComponent, SeccionVideosCalificacionesComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    DatespipeModule,
    ComponentsModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    MaterialModule,
    ShareButtonsModule.withConfig({}),
    DashboardModule,
    ComponentsModule
  ],
  exports: [HomeComponent, ModalsComponent, PresentacionComponent, NivelesComponent,
    UltimosVideosComponent, NoticiasImportantesComponent,SeccionVideosPrincipalesComponent,FormReactivoComponent],
  providers: [EmailService]
})
export class HomeModule { }
