    <!--slider de una sola imagne para mobile-->
    <div *ngIf="sliderSimple" id="carouselExampleCaptions1" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions1" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleCaptions1" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions1" data-slide-to="2"></li>
        <li data-target="#carouselExampleCaptions1" data-slide-to="3"></li>
        <li data-target="#carouselExampleCaptions1" data-slide-to="4"></li>
      </ol>
      <div class="carousel-inner " >
        <div class="carousel-item active">
          <div class="row d-flex justify-content-center">
            <img src="assets/images/cap1.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          </div>
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item ">
          <div class="row d-flex justify-content-center">
          <img src="assets/images/cap4.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          </div>
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
          <img src="assets/images/cap7.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          </div>
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleCaptions1" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleCaptions1" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>

  <!--slider de multiple imagenes-->
  <div *ngIf="!sliderSimple" id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner " >
      <div class="carousel-item active">
        <div class="row d-flex justify-content-center">
          <img src="assets/images/cap1.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap2.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap3.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/cap4.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap5.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap6.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/cap7.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap8.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap9.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/cap10.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Anterior</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Siguiente</span>
    </a>
  </div>
