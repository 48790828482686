import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-tarjetas-items',
  templateUrl: './tarjetas-items.component.html',
  styleUrls: ['./tarjetas-items.component.scss']
})
export class TarjetasItemsComponent implements OnInit {
  // direccionAssets = '../../../';
  direccionAssets = '';
  @Input() tarjetas = [];
  @Output() categoriaElegidaEmit: EventEmitter<any> = new EventEmitter();
  @Output() nuevoVideoEmit: EventEmitter<any> = new EventEmitter();
  urlActual: string;
  urlOriginal: string;
  location: Location;
  urlorigin: string;
  pathname: string;
  url: any;
  constructor(platformLocation: PlatformLocation, private dataservice: DataService, location: Location, private router: Router) {
    // this.urlOriginal = router.url;
    this.urlorigin = (platformLocation as any).location.origin;
    this.pathname = (platformLocation as any).location.pathname;
    this.url = (platformLocation as any).location.href;
    // console.log('REC ORIGIN', this.urlorigin );
    // console.log('REC PATH ACTUAL: ', this.pathname);
    this.url = this.dataservice.getUrl(this.url);
    // console.log('REC URL ACTUAL + geturl:', this.url);
  }

  ngOnInit(): void {
  }
  cargarVideos(cate: string): void {
    // this.router.navigate(['seccion1/recurso'], { fragment: 'top' });
    if (this.url.includes('recurso')){
      this.urlActual = this.url + '#videosRec';
    }else{
      if (this.url.includes('actividades')){
        this.urlActual = this.url + '#videosAct';
      }
    }
    this.categoriaElegidaEmit.emit(cate.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  }

}
