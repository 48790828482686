import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormulariosComponent } from './formularios/formularios.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AppRoutingModule } from '../app-routing.module';
import { DatespipeModule } from '../datespipe/datespipe.module';
import { MaterialModule } from '../material/material.module';
import { FormulariosSgeComponent } from './formularios-sge/formularios-sge.component';
import { FormulariosRaComponent } from './formularios-ra/formularios-ra.component';



@NgModule({
  declarations: [FormulariosComponent,FormulariosSgeComponent,FormulariosRaComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    SharedModule,
    SharedModule,
    CollapseModule,
    FormsModule,
    AppRoutingModule,
    DatespipeModule,
    ShareButtonsModule.withConfig({}),
    ShareIconsModule,
    MaterialModule,
    TabsModule.forRoot()
  ],
  exports: [FormulariosComponent,FormulariosSgeComponent,FormulariosRaComponent]
})
export class FormulariosModule { }
