<div class="mc-banner-type-c-personas img-fluid" style="background-image: url('assets/images/portada1.png'); background-position: -31.56px -102.544px;" >
    <div class="bg-color-9 d-flex justify-content-center py-4">
        <div class="row container text-center">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-dark">
                <div *ngIf="false" class="display-4 animated fadeIn my-1">
                    Secretaria de Innovación y Calidad
                    Educativa
                </div>
                <h1 *ngIf="false" class="display-4 animated fadeIn " >
                    Secretaría de Innovación y Calidad
                    Educativa
                </h1>
                <h1 *ngIf="false"  class="display-4 animated fadeIn " >
                    Dirección de Información, Monitoreo y Evaluación Educativa
                </h1>
                <h1  class="display-5 animated fadeIn " >
                    ÁREA SISTEMAS DE INFORMACIÓN
                </h1>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mb-5 mt-5">
    <a href="http://190.52.33.51:8082/mesaayudasinide" class="col-sm-12 col-md-8 col-lg-9 col-xl-8 btn rounded-pill botonesInicio m-t-10 font-14  tamanio">
        Ingresar a Mesa de Ayudar SIniDE
    </a>
</div>

<div class="position-relative" *ngIf="false">
    <section class="section section-lg section-hero section-shaped">
        <div class="shape shape-style-1 shape-primary"><span class="span-150"></span><span class="span-50"></span><span
                class="span-50"></span><span class="span-75"></span><span class="span-100"></span><span
                class="span-75"></span><span class="span-50"></span><span class="span-100"></span><span
                class="span-50"></span><span class="span-100"></span></div>
        <div class="container  shape-container d-flex align-items-center py-lg">
            <div class="col px-0">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 text-center">
                        <p class="lead text-white">Sistema de Constultas de
                        </p>
                        <div class="mt-5">
                            <h4 class="text-white font-weight-bold mb-0 mr-2"></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="separator separator-bottom separator-skew zindex-100"><svg preserveAspectRatio="none" version="1.1"
                viewBox="0 0 2560 100" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
                <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
            </svg></div>
    </section>
</div>
