<div class="menu">
    <div class="text-center">
        <h4 style="color: black; padding: 5px;">
            <p></p>
            Seleccione una Categoría:
        </h4>
    </div>
    <div class="d-flex justify-content-center flex-wrap my-2" id="tarjetasRecursos">
        <div *ngFor="let t of tarjetas" class='card my-5 cards text-center rounded' style='width: 18rem;'>
            <div class='card-header card-header-fondo rounded'>
             <img src='{{direccionAssets}}assets/images/{{t.imagen}}' class='card-img-top iconos' alt='...'> </div>  
              <div class='card-body'>
              <h5 class='card-title'> {{t.titulo}}  </h5>
              <p class='card-text'> {{t.subtitulo}} </p></div>
              <div class='card-footer card-footer-button text-muted mx-auto'>
              <a href="{{urlActual}}" (click)="cargarVideos(t.titulo)" class='btn botonesInicio'> Entrar </a>
              </div>
             </div>
    </div>

</div>