<nav class="navbar navbar-expand-md navbar-light  navbar-dark azul-violeta sombra-blanca sticky-top">
    <div class="d-flex justify-content-center col-sm-12 col-md-12 col-lg-12 flex-wrap my-2">
        <div class="text-center  col-sm-12 col-md-12 col-lg-12">
            <a class="navbar-brand" target="_blanck">
              <img src="assets/images/ministeriologo.png" width="100" class="d-inline-block align-top" alt="">
            </a>
            <p class="text-white">Ministerio de Educación</p>
          </div>
      <div *ngIf="false" class="display-4 text-white text-center titulo-header font-weight-bold d-flex align-items-center"> 
          SINIDE / RA
      </div>
    </div>
  </nav>

  