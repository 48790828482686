import { Documento } from '../models/Documento';

export const formularios: Documento[] = [
    {
        id: 1,
        titulo: 'ACTA COMPROMISO PARA EL DIRECTOR ',
        descripcion: 'ACTA COMPROMISO PARA EL DIRECTOR',
        urlpdf: 'https://drive.google.com/file/d/1LUdxPLiycXm7LCrxRCTbwJaR2LmpNaDN/view?usp=sharing'
    },
    {
        id: 2,
        titulo: 'PLANILLA DE DATOS DEL DIRECTOR, VICEDIRECTOR Y SECRETARIO',
        descripcion: 'PLANILLA DE DATOS DEL DIRECTOR, VICEDIRECTOR Y SECRETARIO',
        urlpdf: 'https://docs.google.com/document/d/1-YNWOy96Qx_Ds9V467B7Ko5rva9zTUQv/view?usp=sharing'
    },
    {
        id: 3,
        titulo: 'PLANILLA DE EDICION DE DATOS PERSONALES SGE',
        descripcion: 'PLANILLA DE EDICION DE DATOS PERSONALES SGE',
        urlpdf: 'https://docs.google.com/document/d/1O5eVf0kzdCqBqcE-dFKxvgGwbhBSOQr1/view?usp=sharing'
    },
    {
        id: 4,
        titulo: 'REGISTRO DE ALUMNO SINIDE-SGE',
        descripcion: 'REGISTRO DE ALUMNO SINIDE-SGE',
        urlpdf: 'https://drive.google.com/file/d/11Zl4hg2SaMhCNk8PuneIZV8yEqUj2fbL/view?usp=sharing'
    },
    {
        id: 5,
        titulo: 'SOLICITUD DE USUARIO PARA EL DIRECTOR SECUNDARIO',
        descripcion: 'SOLICITUD DE USUARIO PARA EL DIRECTOR SECUNDARIO',
        urlpdf: 'https://drive.google.com/file/d/1q7gAph4_Mycs4fbEbutDmZVAXx9FDMsU/view?usp=sharing'
    },
    {
        id: 6,
        titulo: 'SOLICITUD DE USUARIO PARA EL SUPERVISOR',
        descripcion: 'SOLICITUD DE USUARIO PARA EL SUPERVISOR',
        urlpdf: 'https://drive.google.com/file/d/1l6ipaFWlk8G-8g9I5zJCpMdCedRRg-KU/view?usp=sharing'
    },
    {
        id: 7,
        titulo: 'SOLICITUD DE USUARIO PARA SISTEMAS - INICIAL - PRIMARIO',
        descripcion: 'SOLICITUD DE USUARIO PARA SISTEMAS - INICIAL - PRIMARIO',
        urlpdf: 'https://drive.google.com/file/d/1IYHiaK11LCjyTBe5J_a8BGUv4A4kXeXc/view?usp=sharing'
    }
]
