import { DepartamentoTipo } from './DepartamentoTipo';
export class Localidad {
    clocalidad: number;
    codLocIndec: string;
    codLocalidad: string;
    nombre: string;
    tipo: string;
    departamentoTipo: DepartamentoTipo;
    constructor(){
        this.departamentoTipo = new DepartamentoTipo();
    }
}
