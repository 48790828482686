<div class="titu">
    <h1 class="">Capacitaciones Presenciales</h1>
</div>
<!--SLIDER CON UNA SOLA IMAGEN PARA RESPONSIVE-->
<div *ngIf="sliderSimple" id="carouselExampleCaptions" class="carousel slide bg-danger" data-ride="carousel">
  <div class="carousel-inner " >
    <div class="carousel-item active">
      <div class="row d-flex justify-content-center">
        <img src="assets/images/c1.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c4.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c7.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c10.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c13.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c16.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">

      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c19.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c22.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item ">
      <div class="row d-flex justify-content-center">
      <img src="assets/images/c25.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">

      </div>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>

</div>

<!--SLIDER CON MAS DE UNA IMAGEN POR SLIDER-->
<div *ngIf="!sliderSimple" id="carouselExampleCaptions" class="carousel slide bg-success" data-ride="carousel">
    <div class="carousel-inner " >
      <div class="carousel-item active">
        <div class="row d-flex justify-content-center">
          <img src="assets/images/c1.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c2.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c3.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c4.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c5.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c6.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c7.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c8.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c9.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c10.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c11.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c12.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c13.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c14.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c15.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c16.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c17.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c18.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c19.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c20.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c21.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c22.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c23.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c24.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
      <div class="carousel-item ">
        <div class="row d-flex justify-content-center">
        <img src="assets/images/c25.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c26.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
          <img src="assets/images/c27.jpeg" class="d-block m-1 p-1 rounded shadow img-fluid portada-tamanio" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>
    </div>

  </div>
