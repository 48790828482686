<app-slider class="d-none d-md-block" [sliderSimple]="false"></app-slider>
<app-slider class="d-block d-md-none" [sliderSimple]="true"></app-slider>

<app-seccion [titulo]="'Videos Tutorial SGE Jujuy'" [descripcion]="'Estos son algunos videos tutoriales para comenzar a usar el Sistema SGE'" [fondo]="'naranja'"></app-seccion>

<section class="wrapper">
  <div class="container-fluid">

      <div class="py-3 row m-0 d-flex justify-content-center align-items-center">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-4" *ngFor="let video of listaVideosPrincipales;let i=index">
            <app-tarjeta-video
            [video]="video">
           </app-tarjeta-video>
          </div>



      </div>

  </div>
</section>

