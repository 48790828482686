import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from '../app-routing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RedesSocialesComponent } from './redes-sociales/redes-sociales.component';
import { PortadaInicioComponent } from './portada-inicio/portada-inicio.component';
import { HeaderBienvenidaComponent } from './header-bienvenida/header-bienvenida.component';
import { PortadaPaginaPrincipalComponent } from './portada-pagina-principal/portada-pagina-principal.component';

import { CarruselComponent } from './carrusel/carrusel.component';
import { SliderComponent } from './slider/slider.component';
import { SeccionComponent } from './seccion/seccion.component';
import { SeccionButtonComponent } from './seccion-button/seccion-button.component';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, RedesSocialesComponent, PortadaInicioComponent,
     HeaderBienvenidaComponent,
     PortadaPaginaPrincipalComponent, CarruselComponent, SliderComponent,
     SeccionComponent,
     SeccionButtonComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
  ],
  exports: [FooterComponent, HeaderComponent, RedesSocialesComponent, PortadaInicioComponent,
  HeaderBienvenidaComponent,CarruselComponent, SliderComponent,
  PortadaPaginaPrincipalComponent,SeccionComponent, SeccionButtonComponent]
})
export class SharedModule { }
