import { Documento } from '../../models/Documento';


export const listaFormularios: Documento[] = [
    {
        id: 1,
        titulo: 'Solicitud de Usuarios para Sistema NI y NP',
        descripcion: 'Solicitud de Usuarios para Sistema NI y NP',
        urlpdf: 'https://drive.google.com/file/d/1jybcl_tUg4lDYn4mUVbc259DgcXLDXGR/view?usp=sharing'
    },
    {
        id: 2,
        titulo: 'Solicitud de usuario para el supervisor',
        descripcion: 'Solicitud de usuario para el supervisor',
        urlpdf: 'https://drive.google.com/file/d/1j-1XgyXVf7sWrykZlXwB5DMbujAtTjA1/view?usp=sharing'
    },
    {
        id: 3,
        titulo: 'Registro de alumno SInIDE-SGE 01',
        descripcion: 'Registro de alumno SInIDE-SGE 01',
        urlpdf: 'https://drive.google.com/file/d/1InMLzUydvRQ4AaL0MYA94kpEfl5w61ee/view?usp=sharing'
    },
    {
        id: 4,
        titulo: 'Planilla de edición de datos Personales SGE',
        descripcion: 'Planilla de edición de datos Personales SGE',
        urlpdf: 'https://drive.google.com/file/d/1Vw7r-xo8e-G8lU5mIMHAPeOyqHeY_t50/view?usp=sharing'
    },
    {
        id: 5,
        titulo: 'Planilla datos Director y ViceDirector y Secretario',
        descripcion: 'Planilla datos Director y ViceDirector y Secreatarios',
        urlpdf: 'https://drive.google.com/file/d/1NvlDtX50nJrFQVkyV9H65OcQpz29lL66/view?usp=sharing'
    },
    {
        id: 6,
        titulo: 'Acta compromiso para el director',
        descripcion: 'Acta compromiso para el director',
        urlpdf: 'https://drive.google.com/file/d/1LUdxPLiycXm7LCrxRCTbwJaR2LmpNaDN/view?usp=sharing'
    }

]

export const formulariosRa: Documento[] = [
  {
      id: 1,
      titulo: 'ADULTOS-TP-2024',
      descripcion: 'ADULTOS-TP-2024',
      urlpdf: 'https://drive.google.com/file/d/1ZTxhHFf8b_fKLbnw_9vPQb_6GKu5Htr0/view?usp=sharing'
  },
  {
      id: 2,
      titulo: 'ARTISTICA-2024',
      descripcion: 'ARTISTICA-2024',
      urlpdf: 'https://drive.google.com/file/d/1YD7iiqEkqjq8fujgwHEHpL6I4wAkBjTj/view?usp=sharing'
  },
  {
      id: 3,
      titulo: 'COMUN-TP-2024 PRIMARIO',
      descripcion: 'COMUN-TP-2024 PRIMARIO',
      urlpdf: 'https://drive.google.com/file/d/1pQdXntVOhQ_7rbJ9_gugnxOuar7Y7jfj/view?usp=sharing'
  },
  {
      id: 4,
      titulo: 'COMUN-TP-2024-INICIAL',
      descripcion: 'COMUN-TP-2024-INICIAL',
      urlpdf: 'https://drive.google.com/file/d/186uVlwyjE8SDZbCFSMCkeW6vpuTlmr7z/view?usp=sharing'
  },
  {
      id: 5,
      titulo: 'COMUN-TP-2024-SECUNDARIO',
      descripcion: 'COMUN-TP-2024-SECUNDARIO',
      urlpdf: 'https://drive.google.com/file/d/1z34aY5UFkBWxFxo3mzun4_qESYMdIyv6/view?usp=sharing'
  },
  {
      id: 6,
      titulo: 'COMUN-TP-2024INICIAL Y PRIMARIO',
      descripcion: 'COMUN-TP-2024INICIAL Y PRIMARIO',
      urlpdf: 'https://drive.google.com/file/d/1ck2YuUrvNDwAVF-odBog62VTWhlUxf7a/view?usp=sharing'
  },
  {
      id: 7,
      titulo: 'DOMICILIARIA-2024',
      descripcion: 'DOMICILIARIA-2024',
      urlpdf: 'https://drive.google.com/file/d/12HdGaFxhv8FRmKCMLu7d7s6uBcwX5Byu/view?usp=sharing'
  },
  {
      id: 8,
      titulo: 'ESPECIAL-2024',
      descripcion: 'ESPECIAL-2024',
      urlpdf: 'https://drive.google.com/file/d/1kDyQ3nYA8Ei-6Jf-kRc-CQuWrWqavWBs/view?usp=sharing'
  },
  {
      id: 9,
      titulo: 'FORM-PROF-CAP-LABORAL-2024',
      descripcion: 'FORM-PROF-CAP-LABORAL-2024',
      urlpdf: 'https://drive.google.com/file/d/1ooktY8JftnnVLc0N9zpnHjcOL7YUNT-3/view?usp=sharing'
  },
  {
      id: 10,
      titulo: 'SUPERIOR-TP-2024',
      descripcion: 'SUPERIOR-TP-2024',
      urlpdf: 'https://drive.google.com/file/d/14q6LH7juB1tEyMCbxfAla-hvAXCsWisU/view?usp=sharing'
  }
]
