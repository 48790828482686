<div class="d-flex justify-content-center flex-wrap my-1 ">
    <a name="general"></a>
    <div *ngIf="false"
      class="border-0 col-sm-12 col-md-6 col-lg-6 col-xl-6 alert alert-success text-center  bg-white rounded animated fadeIn">
      <a (click)="irAPagina('consultores')" class="btn  botones mb-3 rounded-pill">
        <H1> <i class="fas fa-search"></i> Buscar a mi consultor</H1>
      </a>
      <div class="badge-warning rounded mx-4" *ngIf="false">
        <h2>Próximamente </h2>
      </div>
      <img src="assets/images/consultores.jpg" style="height: 400px!important;" class="img-fluid " alt="">
    </div>
    <div
      class="border-0 col-sm-12 col-md-6 col-lg-6 col-xl-6 alert alert-success text-center bg-white  rounded animated fadeIn">

    </div>



  </div>

  <div class="col-sm-12 col-md-12 col-lg-8 col-xl-6" *ngIf="false">
    <div class="alert alert-danger text-center text-white azul-violeta m-0 rounded shadow animated fadeIn">
      <H1> <i class="far fa-bell"></i> Ultimas Novedades</H1>
    </div>
    <div class="d-flex justify-content-center flex-wrap my-1">
      <!--carrusel de imagenes-->
      <carousel [interval]="myInterval" [(activeSlide)]="activeSlideIndex">
        <slide [ngClass]="getClass(noticia.id)" *ngFor="let noticia of listaNoticias; let i=index">
          <div class="card my-5 text-white cards text-center animated fadeIn" [ngClass]="getClass(noticia.id)">
            <div class='card-header text-white '>
              <h3 class='card-title'>{{noticia.titulo}} </h3>
              <p>Publicado el {{noticia.fecha}}</p>
            </div>
            <div class='card-body px-5'>
              <p class='card-text'>{{noticia.descripcion}} </p>
            </div>
            <div class='card-footer border-top-0'>
              <a class="btn  btn-primary border-1 border-white" (click)="openModal(noticia,templateNovedad)">
                <h4>
                  Ver Novedad <i class="fas fa-eye"></i>
                </h4>
              </a>
            </div>
          </div>
          <div *ngIf="false" class="carousel-caption">
            <h4>Slide {{i}}</h4>
            <p>{{noticia.titulo}}</p>
          </div>
        </slide>
      </carousel>
    </div>

  </div>