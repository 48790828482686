<div *ngIf="false" class="row mx-0 my-5 d-flex justify-content-center">
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h1 class="display-4"> Capacitaciones Sinide Abril 2022</h1>
    </div>
    <div class="col-sm-12 col-md-5 col-lg-5" *ngFor="let video of listaVideosCapacitaciones">
        <div class="card border-0 rounded">
            <div class="card-header rounded text-white shadow" [ngClass]="getClass(video?.categoria)">
                <h4>{{video?.titulo}} - {{video?.subtitulo}}</h4>
                <h2 class="badge rounded-pill bg-warning" style="font-size: 15px;">NUEVO</h2>

            </div>
            <div class="card-body p-0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                        [src]="video?.url | safeUrl" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="false" class="row mx-0 my-5 d-flex justify-content-center">
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h1 class="display-4"> Inscripciones de Alumnos Ciclo 2022</h1>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h2 class="badge rounded-pill bg-warning text-white" style="font-size: 25px;">
            Fechas de Inscripciones: INCIAL Y PRIMARIO 02/03/2022
            SECUNDARIO 07/03/2022</h2>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4" *ngFor="let video of listaVideosInscripciones">

<<<<<<< HEAD
        <div class="card border-0 rounded" >
=======
        <div class="card border-0 rounded">
>>>>>>> 4a9059e99c62cb6163d997a0768a7d582bf284cd
            <div class="card-header rounded text-white shadow" [ngClass]="getClass(video?.categoria)">
                <h4>{{video?.titulo}}</h4>
                <h2 class="badge rounded-pill bg-warning" style="font-size: 15px;">NUEVO</h2>

            </div>
            <div class="card-body p-0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                        [src]="video?.url | safeUrl" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="false" class="row mx-0 my-5 d-flex justify-content-center">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <h1 class="display-4"> Nuevos Tutoriales</h1>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4" *ngFor="let video of listaVideos">
        <div class="card border-0 rounded">
            <div class="card-header rounded text-white shadow" [ngClass]="getClass(video?.categoria)">
                <h4>{{video?.titulo}}</h4>
                <h2 class="badge rounded-pill bg-warning" style="font-size: 15px;">NUEVO</h2>

            </div>
            <div class="card-body p-0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                        [src]="video?.url | safeUrl" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="card-footer text-white rounded" [ngClass]="getClass(video?.categoria)"
                *ngIf="video.categoria.toUpperCase().includes('PRIMARIO')">
                <p>Fecha de habilitación de carga de Notas período de Examenes: 21/02/2022 al 25/02/2022</p>
            </div>
            <div class="card-footer text-white rounded" [ngClass]="getClass(video?.categoria)"
                *ngIf="video.categoria.toUpperCase().includes('SECUNDARIO')">
                <p>Fecha de habilitación de carga de Notas período de Examenes: 21/02/2022 al 25/02/2022</p>
                <p>Fecha de habilitación de carga de Notas período INTENSIVO: 09/03/2022 al 30/03/2022</p>
            </div>
        </div>
    </div>






</div>
<div class="menu my-3">
    <div class="d-flex justify-content-center flex-wrap my-1">
<<<<<<< HEAD
        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-6">
=======
        <!--<div class="col-sm-12 col-md-12 col-lg-8 col-xl-6">
>>>>>>> 4a9059e99c62cb6163d997a0768a7d582bf284cd
            <div class="alert alert-success text-center fondo text-white m-0 rounded shadow animated fadeIn">
                <H1> <i class="fab fa-youtube"></i> Nuestros últimos videos</H1>
            </div>-->
        <!--carrusel de imagenes-->
        <!-- <div id="carouselExampleCaptionsVideos" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleCaptionsVideos" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleCaptionsVideos" data-slide-to="1"></li>
                    <li data-target="#carouselExampleCaptionsVideos" data-slide-to="2"></li>

                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="embed-responsive embed-responsive-16by9">

                            <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                                [src]="getObtenerIdVideo(listaVideos[2]?.url) | safeUrl" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="embed-responsive embed-responsive-16by9">

                            <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                                [src]="getObtenerIdVideo(listaVideos[1]?.url) | safeUrl" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="embed-responsive embed-responsive-16by9">

                            <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12"
                                [src]="getObtenerIdVideo(listaVideos[0]?.url) | safeUrl" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaptionsVideos" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptionsVideos" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>-->
    </div>
    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-6 text-center">
        <a class="btn botones mb-3 rounded-pill" (click)="irAPagina('documentacion')">
            <H1>
                <i class="fas fa-file-alt"></i> Ver Formularios Importantes
            </H1>
        </a>
        <img src="assets/images/formulario.jpg" class="img-fluid my-1" height="400px" alt="">
    </div>
</div>
