import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SharedModule } from './shared/shared.module';
//import { FlexLayoutModule } from '@angular/flex-layout';

import { PlanDictadoService } from './services/ra/plan-dictado.service';
import { ComparteEdificioService } from './services/ra/comparte-edificio.service';
import { AlumnoTipoService } from './services/ra/alumno-tipo.service';
import { HomeModule } from './home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { ConsultoresEscuelasModule } from './consultores-escuelas/consultores-escuelas.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LocalizacionService } from './services/ra/localizacion.service';
import { LocalizacionDomicilioService } from './services/ra/localizacion-domicilio.service';
import { DepartamentoService } from './services/ra/departamento.service';
import { OfertaLocalService } from './services/ra/oferta-local.service';
import { AlumnosService } from './services/ra/alumnos.service';
import { VideosPrincipalesModule } from './videos-principales/videos-principales.module';
import { FormulariosModule } from './formularios/formularios.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    BrowserAnimationsModule,
    CollapseModule,
    SharedModule,
    HomeModule,
    HttpClientModule,
    ConsultoresEscuelasModule,
    VideosPrincipalesModule,
    FormulariosModule
  ],
  providers: [ {provide: LocationStrategy, useClass: HashLocationStrategy},
     LocalizacionService,
     OfertaLocalService, DepartamentoService, LocalizacionDomicilioService, AlumnoTipoService,
     PlanDictadoService, AlumnosService],
  bootstrap: [AppComponent]
})
export class AppModule { }
