import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-header-bienvenida',
  templateUrl: './header-bienvenida.component.html',
  styleUrls: ['./header-bienvenida.component.scss']
})
export class HeaderBienvenidaComponent implements OnInit {
// direccionAssets = '../../../';
direccionAssets = '';
isCollapsed = true;
constructor(private dataservice: DataService,private router: Router) { }

ngOnInit(): void {
}
cargarPagina(categoria: string): void{
  this.dataservice.setEleccion(categoria);
  this.tiempoYColapsar();
}
tiempoYColapsar():void{
  setTimeout(() => {
    this.isCollapsed = true;  
  }, 1500);
}

}
