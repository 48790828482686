import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultoresEscuelasComponent } from './consultores-escuelas/consultores-escuelas.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatTableExporterModule } from 'mat-table-exporter';
import { AppRoutingModule } from '../app-routing.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [ConsultoresEscuelasComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ClipboardModule,
    MatTableExporterModule,
    AppRoutingModule,
    TooltipModule.forRoot()
  ],
  exports: [ConsultoresEscuelasComponent]
})
export class ConsultoresEscuelasModule { }
