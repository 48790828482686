<div class="bg-white text-center mx-0 mb-0">
  <!--mensaje de ra-->
  <div *ngIf="categoria.toUpperCase()=='RA'" class="my-2">
    <app-lista-documentacion></app-lista-documentacion>
  </div>

  <!--<a name="{{elegido}}"></a>-->
  <h1 class="display-5 " id="titulos">
    <h1 class='display-5'> Categoría elegida {{categoria}}</h1>
    <h4 class='display-5' *ngIf="validarObjeto()"> Resultados de Búsqueda {{lista.length}}</h4>
    <!-- <h4 class='display-5' *ngIf="!validarObjeto()" > Video Seleccionado</h4> -->

  </h1>

  <div class="d-flex justify-content-center">
    <a *ngIf="!todasCategorias && categoria.toUpperCase()!=='RA'" (click)="habilitarCategorias()"
      class='btn botonesInicio '> Volver a Elegir </a>
  </div>


  <!--busqueda de los videos-->
  <div *ngIf="false">
    <div *ngIf="lista.length>3" class="text-center form-inline d-flex justify-content-center">
      <input class="form-control mx-2  col-sm-10 col-md-7 col-lg-6" [(ngModel)]="videoBus" id="videoBus" type="search"
        [placeholder]="textoInputBus" aria-label="Search">
      <button class="btn btn-danger border-1 border-white m-2" [disabled]="videoBus===''" (click)="buscarVideos()"
        type="button"> <i class="fas fa-search"></i> Buscar </button>
    </div>
    <div>
      <button *ngIf="habilitarTodos===true" [disabled]="listaOriginal.length===0 || videoBus===''"
        class="btn btn-success border-1 border-white m-2" (click)="verTodos()" type="button"> <i
          class="fas fa-search"></i> Todos </button>
    </div>
  </div>


  <div *ngIf="lista.length>0" class="d-flex justify-content-center flex-wrap my-3">
    <div *ngFor="let v of lista; let i=index" [ngClass]="getClass(v)"
      class='card my-5 cards-video card-grande rounded text-center' style='width: 18rem;'>
      <!--<img *ngIf="habilitarImg" class="p-2" src="../../../assets/images/logo_unjuvirtual.png" width="100%" height="133px" alt="logo"> -->
      <iframe class="rounded" [src]="v.url | safeUrl" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div class='card-body'>
        <h5 class='card-title'>{{i+1}} - {{v.titulo}} </h5>
        <div class="alert alert-warning" *ngIf="v.observacion!==''"> {{v.observacion}}</div>
        <p class='card-text'> {{v.subtitulo}} </p>
      </div>
      <div [ngClass]="getClass(v)" class='card-footer card-footer-button text-muted mx-auto'>
        <h4>Compartir</h4>
        <div class="row p-0 d-flex justify-content-center">
          <share-buttons [theme]="'material-dark'" [include]="['whatsapp']" [show]="1" [size]="9"
            url="https://youtu.be/{{v.url.slice(30,v.url.length)}}" [autoSetMeta]="false"></share-buttons>
          <share-buttons mat-mini-fab [theme]="'material-dark'" [include]="['email']" [show]="1" [size]="9"
            url="https://youtu.be/{{v.url.slice(30,v.url.length)}}" [autoSetMeta]="false"></share-buttons>

          <share-buttons [theme]="'material-dark'" [include]="['copy']" [show]="1" [size]="9"
            url="https://youtu.be/{{v.url.slice(30,v.url.length)}}" [autoSetMeta]="false"></share-buttons>
        </div>



        <a (click)="elegir(v,templateVideo)" class='btn btn-primary botonesInicio text-white my-1 w-100'>
          <i class="fab fa-youtube iconosInit"></i> Ver Video </a>
        <a href="https://youtu.be/{{v.url.slice(30,v.url.length)}}" target="_blanck"
          class="btn botones-youtube btn-danger text-white w-100">
          <i class="fab fa-youtube iconosInit"></i>Ver en Youtube </a>
      </div>
    </div>
  </div>
</div>



<ng-template #templateVideo>

  <div *ngIf="!validarObjeto()" class="modal-header azul-violeta text-white text-center">
    <h4 class="modal-title  w-100 text-center">{{videoElegido?.titulo}}</h4>
    <button *ngIf="getPosicion(videoElegido.id) !== 0" type="button"
      class="btn btn-primary btn-circle pull-right mx-1 border border-white" aria-label="Close" (click)="anterior()">
      <i class="fas fa-chevron-circle-left"></i>
    </button>
    <button *ngIf="!esUltimoVideo(videoElegido.id)" type="button"
      class="btn btn-primary btn-circle pull-right mx-1 border border-white" aria-label="Close" (click)="siguiente()">
      <i class="fas fa-chevron-circle-right"></i>
    </button>
    <button type="button" class="btn btn-danger btn-circle pull-right border border-white" aria-label="Close"
      (click)="modalRef.hide()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div *ngIf="!validarObjeto()" class="modal-body text-dark">
    <div class="text-center d-sm-block d-md-block d-lg-block" id="videoSeleccionado">
      <div class="alert alert-primary" role="alert">
        <h6 class="modal-title pull-left">{{videoElegido?.subtitulo}}</h6>
      </div>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" class="col-sm-12 col-md-12 col-lg-12" [src]="videoElegido?.url | safeUrl"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>

</ng-template>