<nav class="navbar navbar-expand-lg  navbar-dark bg-white  sticky-top">
  <div class="row m-0 w-100 d-flex justify-content-end">
    <div class="col-sm-12 col-md-4 col-lg-3 text-center">
      <div class="container-fluid ">
        <a class="navbar-brand" href="#">
          <img src="assets/images/nuevo/logo_me.png" alt="logo_me" class="img-fluid" width="200" height="20">
        </a>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9 bg-header" >
      <div class="row m-0 d-flex justify-content-end align-items-center">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <div class="row m-0 d-flex justify-content-start align-items-center">
            <div class="col-sm-6 col-md-8 col-lg-8 my-2 ">
              <div class="display-4 text-white text-center titulo-header font-weight-bold"> Mesa de Ayuda<br> SIniDE-SGE

              </div>
            </div>
            <div class="col-2">
              <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="collapse navbar-collapse col-sm-12 col-md-4 col-lg-4" id="navbarSupportedContent"
          [collapse]="isCollapsed" [isAnimated]="true">
          <div class="navbar-nav nav-pills items mr-auto ml-auto text-center my-1">
            <a class="nav-link active" (click)="tiempoYColapsar()" routerLink="/" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Inicio</a>
            <!--<a class="nav-link" (click)="cargarPagina('inicial')" [routerLink]="['seccion', 'inicial']"
                  routerLinkActive="active">Nivel Inicial</a>
                <a class="nav-link" (click)="cargarPagina('primario')" [routerLink]="['seccion', 'primario']"
                  routerLinkActive="active">Nivel Primario</a>
                <a class="nav-link" (click)="cargarPagina('secundario')" [routerLink]="['seccion', 'secundario']"
                  routerLinkActive="active">Nivel Secundario</a>
                  <a class="nav-link" (click)="cargarPagina('ra')" [routerLink]="['seccion', 'ra']"
                  routerLinkActive="active">RA</a>-->
                  <a class="nav-link" (click)="tiempoYColapsar()" [routerLink]="['videos-tutoriales']"
                  routerLinkActive="active">Videos</a>
            <a class="nav-link" (click)="tiempoYColapsar()" [routerLink]="['documentacion']"
              routerLinkActive="active">Formularios</a>
            <a class="nav-link" *ngIf="false" (click)="tiempoYColapsar()" [routerLink]="['galeria']"
              routerLinkActive="active">Galería</a>
            <a class="nav-link" *ngIf="false" (click)="tiempoYColapsar()" [routerLink]="['quienessomos']"
              routerLinkActive="active">Quienes Somos</a>
            <a *ngIf="false" class="nav-link" (click)="tiempoYColapsar()" [routerLink]="['consultores']"
              routerLinkActive="active">Consultores</a>
          </div>
          <!--creamos unos botones con imagenes para redes sociales-->
          <div class="d-flex justify-content-center mr-auto ml-auto flex-wrap">

          </div>
        </div>
      </div>

    </div>


  </div>


</nav>
