import { Component, OnInit } from '@angular/core';
import { sistemas } from 'src/app/data/data';

@Component({
  selector: 'app-portada-inicio',
  templateUrl: './portada-inicio.component.html',
  styleUrls: ['./portada-inicio.component.scss']
})
export class PortadaInicioComponent implements OnInit {
  listaSistemas: any[]=sistemas;

  constructor() { }

  ngOnInit(): void {
  }

}
