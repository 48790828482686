import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portada-pagina-principal',
  templateUrl: './portada-pagina-principal.component.html',
  styleUrls: ['./portada-pagina-principal.component.scss']
})
export class PortadaPaginaPrincipalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
