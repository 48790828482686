import { Component, Input, OnInit } from '@angular/core';
import { videos } from 'src/app/data/videos-sge';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-videos-principales',
  templateUrl: './videos-principales.component.html',
  styleUrls: ['./videos-principales.component.scss']
})
export class VideosPrincipalesComponent implements OnInit {
  @Input() videoElegido: any;
  listaVideosPrincipales = videos;
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  constructor() {

    console.log(this.listaVideosPrincipales)
   }

  ngOnInit(): void {
  }
  getClass(video: Video): string {
    return video.id === this.videoElegido.id ? 'bg-dark text-white shadow rounded' : '';
  }

  getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }
  getUrlVideo(urlVideo: string):string{
    return "https://youtu.be/"+urlVideo.slice(30,urlVideo.length);

  }
}

