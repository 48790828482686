import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map , catchError, tap } from 'rxjs/operators';
import { Contacto } from '../data/contacto';
import { dominioServer, endpointPHP } from '../data/datos';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private urlphp = '../data/correos.php';
  // url = dominioServer + '/files/';
  // url = dominioServer + '/MesaDeAyudaVirtual/files/';
  url = endpointPHP;
  private httpHeaders = new HttpHeaders();
  constructor(private http: HttpClient) { }
  postEmail(Payload: any): Observable<any>{
    return this.http.post( this.urlphp , Payload, httpOptions);
 }
 recuperarTodos() {
  return this.http.get(`${this.url}todos.php`);
}
enviarEmail(contacto: Contacto): Observable<any> {
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  headers.append('responseType', 'application/json');
  // console.log(contacto);
  let body:any = Object.assign({}, contacto); // copio los datos el objeto contacto a un objeto vacio {}
  // console.log(body);
  // console.log(JSON.stringify(body));
  return this.http.post(`${this.url}correos.php`, JSON.stringify(body) ,{headers});
}
}
/*
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
*/

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};
