import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlanDictado } from '../../models/ra/PlanDictado';

@Injectable({
  providedIn: 'root'
})
export class PlanDictadoService {
  private urlEndPoint: string = URL_BACKEND;
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {

 }
 getPlanesDictados(): Observable<PlanDictado[]> {
    return this.http.get<PlanDictado[]>(this.urlEndPoint + '/planesdictados');
 }
 getPlanDictadoXIdOferta(id: number): Observable<PlanDictado[]> {
  return this.http.get<PlanDictado[]>(this.urlEndPoint + '/planesdictados/ofertalocal/' + id);
}
getPlanDictadoXofertaLocalXIdlocalizacion(id: number): Observable<PlanDictado[]> {
  return this.http.get<PlanDictado[]>(this.urlEndPoint + '/planesdictados/ofertalocal/localizacion/' + id);
}
}
