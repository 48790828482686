<h1>Fechas de Apertura de RA 2022</h1>
<div class="row m-0 d-flex justify-content-center">
    <div class="col-sm-12 col-md-6 col-lg-12">
        <div class="alert alert-primary text-left">
            La carga de los cuadernillos específicos para cada oferta educativa se hará como todos los años vía web y
            estará disponible para:
            <h3>escuelas de <span class="badge bg-secondary text-white">periodo común (marzo/diciembre)</span> a partir
                del <span class="badge bg-secondary text-white">06 de junio hasta el 08 de julio</span></h3>
            <h3>escuelas de <span class="badge bg-danger text-white">periodo especial (septiembre/junio)</span>
                a partir del <span class="badge bg-danger text-white">26 de septiembre hasta el 04 de noviembre</span>
                del presente año.</h3>
            Cabe aclarar que es obligatorio y tiene carácter de declaración jurada.
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="alert alert-primary">
            Para iniciar la carga del Relevamiento Anual 2022 se recomienda contar con la siguiente información:
            <ul class="list-group list-group-flush">
                <li class="list-group-item">Relevamiento Anual 2021</li>
                <li class="list-group-item">Parte estadístico al 30/04/22 confirmado</li>
                <li class="list-group-item">Matricula por edad cumplida al 30/06/2022</li>
                <li class="list-group-item">Datos sobre Planta Orgánica Funcional (POF) – cargos y horas</li>
                <li class="list-group-item">Registros de Entrados/Salidos de alumnos</li>
                <li class="list-group-item">Promovidos/No promovidos y Egresados por año de estudio del ciclo lectivo
                    2021</li>
            </ul>
        </div>

    </div>

<div class="col-sm-12 col-md-6 col-lg-6" *ngIf="listaDocumentos.length>0">
    <div class="container-fluid  d-flex justify-content-center flex-wrap my-1">
        <div *ngFor="let d of listaDocumentos; let i=index" class="card p-0 cards text-center">
            <div class='card-header azul-violeta '>
                <h3 class='card-title text-white col-12'>{{d.titulo}} </h3>
            </div>
            <div class='card-body px-0'>
                <!--<div class="badge-warning rounded mx-5">Nuevo</div>-->
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="100%" [src]="getUrlPdfPreview(d?.urlpdf)  | safeUrl"></iframe>
                </div>
            </div>
            <div class='card-footer card-footer-button text-muted mx-auto'>
                <a class='btn btn-danger col-12' href="{{getUrlPdfView(d?.urlpdf)}}" target="_blanck">
                    Descargar <i class="fas fa-external-link-alt"></i> </a>

            </div>
        </div>


    </div>
</div>
<div class="col-sm-12 col-md-6 col-lg-12">
    <div class="alert alert-danger text-left">
        Recuerde que puede realizar consultas vía telefónica, correo electrónico o whatsapp como así también si necesita
        asesoramiento personal deberá tramitar el turno con su respectivo consultor (día y horario disponible).
        Ante cualquier duda o consulta podrá comunicarse al teléfono fijo 0388-4315020 (Sinide/RA) o con sus consultores
        turno mañana de 07:00 hs a 13:00 hs y de tarde de 13:00 hs a 19:00 hs o en la oficina de la Secretaría de
        Innovación y Calidad Educativa ubicada en Alvear N° 1258.

        En la fecha ante mencionada, las instituciones educativas podrán acceder al RAWEB2022 para empezar hacer la
        carga
        vía online desde el siguiente enlace:
        <div class="col-sm-12 col-md-6 col-lg-6">
            <a href="{{t.url}}" target="_blanck" *ngFor="let t of listaSistemas"
                class='btn botonesInicio my-1 mx-1 col-sm-12 col-md-5 col-lg-5'>
                <img src="assets/images/{{t.imagen}}" width="100" class='img-fluid' alt='...'>
            </a>

        </div>
    </div>

</div>
</div>
